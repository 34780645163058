.text_ {

  &_small { font-size:@font__small; }
  &_regular { font-size:@font__regular; }
  &_medium { font-size:@font__medium; }
  &_medium-large { font-size:@font__medium-large; }
  &_large { font-size:@font__large; }
  &_xlarge { font-size:@font__xlarge; }
  &_xxlarge { font-size:@font__xxlarge; }

  &_body-bold {
    font-size:16px;
    font-weight:@semi-bold;
  }

  &_right {
    text-align:right;
  }

  &_center {
    text-align:center;
  }

  &_interactive {
    cursor:pointer;
    //underline on hover
    &:hover {
      text-decoration:underline;
    }
  }
}

.color_ {
  // Grey colors
  &_grey {
    &-dark { color:@color__grey-dark;}
    &-medium-dark { color:@color__grey-medium-dark;}
    &-medium-light { color:@color__grey-medium-light;}
    &-medium { color:@color__grey-medium;}
    &-light { color:@color__grey-light;}
    &-lighter { color:@color__grey-lighter;}
  }

  &_secondary { color: @secondary; }

  &_ios-purple { color: @ios-purple; }

  &_primary-dark { color: @primary-dark; }
  &_primary-dark-impt { color: @primary-dark !important; }

  &_tertiary-dark { color: @tertiary-dark; }

  &_error { color: @color__error; }
  &_error-impt { color: @color__error !important; }
  &_error-dark { color: @color__error-dark; }
  &_error-dark-impt { color: @color__error-dark !important; }
}

.medium {
  font-weight:@medium;
}

.semi-bold {
  font-weight:@semi-bold;
}

.bold {
  font-weight:@bold;
}

.hidden {
  display:none;

  &-impt {
    display:none !important;
  }
}

.lg-tablet-visible {
  display:none !important;

  .breakpoint-lg-tablet({
    display:block !important;
  });
}

.lg-tablet-hidden {
  .breakpoint-lg-tablet({
    display:none !important;
  });
}

.mobile-hidden {
  .breakpoint-mobile({
    display:none !important;
  });
}

.desktop-hidden {
  .breakpoint-desktop({
    display:none !important;
  });
}

.inline-block {
  display:inline-block;
}

.hoverable {
  cursor:pointer;
  color:#4183c4;
  &:hover {
    text-decoration: underline;
    color:darken(#4183c4, 10%);
  }

  &.active {
    text-decoration: underline;
    font-weight:@bold;
  }
}

//Gaps
.row-gap-20 {
  row-gap: 20px;
}

//Border helpers
.b-t-dashed {
  border-top:1px dashed @border;
}

.b-b-solid {
  border-bottom:1px solid @border;
}

.b-t-solid {
  border-top:1px solid @border;
}


.m-t-a {
  margin-top:auto !important;
}

.m-0-a {
  margin:0 auto !important;
}

// Loop for creating margin and padding helpers
each(range(-50, 100, 5), {
  .m-l-@{value} {
    margin-left:~"@{value}px" !important;
  }
  .m-r-@{value} {
    margin-right:~"@{value}px" !important;
  }
  .m-t-@{value} {
    margin-top:~"@{value}px" !important;
  }
  .m-b-@{value} {
    margin-bottom:~"@{value}px" !important;
  }

  .p-l-@{value} {
    padding-left:~"@{value}px" !important;
  }
  .p-r-@{value} {
    padding-right:~"@{value}px" !important;
  }
  .p-t-@{value} {
    padding-top:~"@{value}px" !important;
  }
  .p-b-@{value} {
    padding-bottom:~"@{value}px" !important;
  }

  .p-@{value} {
    padding:~"@{value}px" !important;
  }

  .row-gap-@{value} {
    row-gap:~"@{value}px" !important;
  }
});

.help {
  color:@color__grey-light;
  font-size:14px;
}