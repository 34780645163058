.aro.widget {
  position:relative;
  border-radius:16px;
  background-color:#fff;
  box-shadow: 0px 4px 20px rgba(60, 61, 73, 0.08);
  padding:30px;
  color: @primary-dark;
  
  &.small {
    height:100%;
    max-height:260px;
  }

  .heading-wrapper {
    & > .title {
      font-size:1em;
      font-weight:@regular;
      color:fade(@color__grey-medium-dark, 80%);

      & > a {
        padding:6px;
        color: fade(@color__grey-medium-dark, 60%);

        &:hover {
          color:@secondary;
        }
      }
    }

    & > .subtitle {
      font-size:1em;
      font-weight:@semi-bold;
      color:@primary-dark;
    }
  }

  & > .aro.ui.loader {
    position:absolute;
    bottom:20px;
    left:30px;
    top:auto;
    transform:none;
  }
}

//Custom widgets
.survey.widget.aro {
  overflow:hidden;
  display:flex;

  .inner-left {
    padding-right:2em;
  }

  .inner-right {
    flex:1 1 auto;
  }

  .overall-score {
    font-size: 2em;
    font-weight:@semi-bold;
    margin-top:4px;
    line-height:1;
  }

  .recommend-wrapper {
    margin-top:23px;
    color:fade(@primary-dark, 80%);
    font-weight:@medium;

    .heading {
      font-size:1em;
    }

  }

  .total-surveys {
    color: fade(@color__grey-medium-dark, 80%);
    
    & > .num {
      font-weight:@semi-bold;
    }
  }
}

.survey-overall-rating.widget.aro {
  display:inline-flex;
  align-items:center;
  gap:30px;

  .title {
    color:fade(#232229, 80%);
    font-weight:@semi-bold;
    font-size:1.125em;
    margin-bottom:6px;
  }

  .value {
    font-size:2em;
    color:#232229;
    font-weight:@semi-bold;
    line-height:1;
  }
}

.survey-listing.widget.aro {
  max-width:640px;

  table {
    width:100%;
    border-collapse: collapse;

    thead {
      tr {
        
        border-bottom:1px solid #C3C3C5;
        
        th {
          padding:15px 0px 10px;
          text-align:left;
          color:fade(#232229, 70%);
          font-weight:@semi-bold;
          size:.875em;
        }
      }
    }

    tbody {
      tr {
        border-bottom:1px solid @border;
        color:#232229;

        &:last-child {
          border-bottom:none;
        }

        td {
          padding:15px 0px;
        }
      }
    }
  }
}

.costsaves.widget.aro {
  .main-total {
    font-size: 2em;
    margin-top:22px;
    font-weight:@semi-bold;
    line-height:1;
  }

  .subtotals-section {
    max-width:360px;
    margin-top: 15px;
    display:flex;
    justify-content:space-between;
    gap:20px;

    .subtitle {
      color: fade(@primary-dark, 80%);
      font-size:1em;
    }

    .title {
      margin-top:4px;
      font-size:1.5em;
      color:@color__grey-medium-dark;
      font-weight:@semi-bold;
    }
  }
}

.costsaves-grand-totals.widget.aro {
  display:inline-block;
  max-width:100%;

  table {
    width:100%;
    border-collapse: collapse;

    th.heading, td.heading {
      font-weight:@semi-bold;
      font-size:.875em;
      line-height:1;
      color:fade(#232229, 70%);
    }

    th, td:not(.heading) {
      padding:15px 5px;
      border-bottom:1px solid @border;
    }

    tbody {
      tr:last-child td {
        border-bottom:none;
      }
    }
  }

  .monthly-totals-wrapper {
    max-width:100%;
    overflow-x:scroll;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.activitylog.widget.aro {
  .chart-container {
    display:flex;
    margin-top:20px;
    column-gap: 20px;
    // flex-wrap:wrap-reverse;

    .legend-wrapper {
      display:flex;
      align-items:center;
    }

    .chart-wrapper {
      flex:0 1 400px;
      overflow:hidden;
    }

    .breakpoint-md-tablet({
      flex-wrap: wrap-reverse;
      justify-content: center;
      row-gap: 30px;
    });
  }
}

.defects-breakdown.widget.aro {
  max-height:none;

  .breakdown-wrapper {
    & > .title {
      font-size:.875em;
      font-weight:@semi-bold;
      color: @primary-dark;
    }

    table.breakdown-table {
      width:100%;
      border-collapse: collapse;

      td.value {
        font-weight:@semi-bold;
        font-size:.875em;
        line-height:1;
        color:@color__grey-medium-dark;
        text-align: right;
      }

      td {
        font-size:.875em;
        padding:15px 5px;
        border-bottom:1px solid @border;
      }

      tr:last-child td {
        border-bottom:none;
      }
    }
  }
}

.defects.widget.aro {
  &.bordered {
    border:2px solid @ios-purple;
  }
}
