// .slide-fade-enter-active {
//   transition: all .3s;
// }
// .slide-fade-leave-active {
//   transition: all .3s;
// }

// .slide-fade-enter /* .list-leave-active below version 2.1.8 */ {
//   opacity: 0;
//   transform: translateX(60px);
// }

// .slide-fade-leave-to {
//   opacity: 0;
//   transform: translateX(-60px);
// }

.slide-fade {
  &-enter, &-fast-enter { //style before element enters
    opacity: 0;
    transform: translateX(60px);
  }
  &-enter-active, &-leave-active { //syle when transition is active
    transition: all .3s;
  }
  &-leave-to, &-fast-leave-to {
    opacity: 0;
    transform: translateX(-60px);
  }

  &-fast-enter-active, &-fast-leave-active, 
  &-fast-reverse-enter-active, &-fast-reverse-leave-active { //syle when transition is active
    transition: all .15s;
  }

  &-fast-reverse-enter { //style before element enters
    opacity: 0;
    transform: translateX(-60px);
  }
  &-fast-reverse-leave-to {
    opacity: 0;
    transform: translateX(60px);
  }

}

.add-event-button {
  border:2px dashed @secondary;
  background-color:transparent;
  text-align: center;
  padding:50px 10px;
  width:100%;
  color:@secondary;
  cursor:pointer;
  -webkit-user-select: none; /* Chrome/Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;

  .icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg {
      // height:18px;
      // width:18px;
      fill:@secondary;
    }

    & > span {
      margin-left:8px;
    }
  }

  &:hover {
    border-color:darken(@secondary, 20%);
    color:darken(@secondary, 20%);
    svg {
      fill:darken(@secondary, 20%);
    }
  }

}

.event-type-container {
  display:flex;
  justify-content: space-between;
  align-items: center;

  & > button {
    border: none;
    background-color: transparent;
    // color:@primary-dark;
    cursor:pointer;

    &:focus {
      border:none;
      outline:none;
    }

    &:hover {
      color:darken(@primary-dark, 10%);
    }
  }
}

.travel-actions {
  display:flex;
  justify-content: space-between;
  align-items:center;
}

.true.card {
  width:100%;
  display:flex;
  background-color:#fff;
  border-radius:6px;
  // -webkit-box-shadow: 0px 0px 46px -38px rgba(0,0,0,0.71);
  // -moz-box-shadow: 0px 0px 46px -38px rgba(0,0,0,0.71);
  // box-shadow: 0px 0px 46px -38px rgba(0,0,0,0.71);
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

  & > .content {
    flex:1 1 auto;
    background-color:#fff;
    padding:50px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-radius:6px;
  }

  & > .sidebar {
    flex:0 1000 500px;
    min-width:300px;
    background-color:@ios-purple;
    padding:50px 30px;
    z-index:100;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    .true.stepper {

      & > .step {
        position:relative;
        padding:60px 10px 60px 25px;

        & > .step__heading,
        & > .step__description {
          color:fade(#ffffff, 50%);
        }

        & > .step__heading {
          font-size:18px;
          margin-bottom:8px;
        }

        & > .step__icon-container {
          position:absolute;
          left:-50px;
          width:40px;
          height:40px;
          // background-color:red;
          border-radius:20px;
          border:2px solid #fff;
          color:#fff;
          text-align:center;
          line-height:36px;
          background-color:#c8c8c8;

          
        }

        &.complete {
          & > .step__icon-container {
            background-image:url('../../img/checkmark.svg');
            background-position:center center;
            background-repeat: no-repeat;
            background-size:15px 15px;
            background-color:@ios-purple;

            & > span {
              display:none;
            }
          }
        }

        &.active {
          & > .step__icon-container {
            background-color:@secondary;
          }

          & > .step__heading {
            color:#fff;
          }

          & > .step__description {
            color:fade(#ffffff, 70%);
          }
        }

      }

    }
  }

  .review-header {
    display:flex;
    align-items:center;
    justify-content:space-between;
  }

  .breakpoint-extra-lg-tablet({
    flex-direction: column;

    & > .content {
      order:2;
      padding:40px 20px;
    }

    & > .sidebar {
      order:1;
      flex:0 0 auto;
      border-radius:0px;
      padding:30px 20px;

      .true.stepper {
        display:flex;
        position:static;
        justify-content: space-around;

        & > .step {
          display:flex;
          justify-content: center;
          flex-wrap: wrap;
          align-items: center;
          gap:20px;
          padding:0px;

          .step__heading {
            margin-bottom:0px;
          }

          .step__description {
            display:none;
          }

          .step__icon-container {
            position:static;
          }
        }
      }
    }
  });
}

.true.panel {
  width:100%;
  background-color:#fff;
  border-radius: 4px;
  // box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

  .panel__header {
    padding:12px 50px;
  }

  &.primary {
    border:1px solid #A6BCC9;

    .panel__header {
      background-color:fade(@primary-dark, 6%);
      color:@primary-dark;
      border-bottom:1px solid #A6BCC9;
    }
  }

  &.warning {
    border:1px solid @color__warning-light;

    .panel__header {
      background-color:fade(@color__warning-light, 6%);
      color:darken(@color__warning-light, 30%);
      border-bottom:1px solid @color__warning-light;
    }
  }

  .panel__content,.panel__footer {
    padding:25px 50px;
  }

  .panel__footer {
    background-color:fade(#000, 5%);
  }
  

  .breakpoint-tablet({
    .panel__header, .panel__content {
      padding:30px 20px;
    }
  });
}

.sliders-container {
  padding: 20px;
  .slider {
    width:400px;
    margin-right:20px;
    display:inline-block;
  }
}

.property-label {
  font-size:13px;
  color: fade(#000, 61%);
}

.property-value {
  font-size:14px;
  color:#000;
}

/* HELPERS -------------------------------------------- */
.dark-link {
  font-size:14px;
  color:@primary-dark;
  cursor:pointer;

  &:hover {
    color:darken(@primary-dark, 10%);
    text-decoration: underline;
  }
}