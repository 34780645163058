// Mix-ins
.borderRadius(@radius) {
	-moz-border-radius: @radius;
	-webkit-border-radius: @radius;
	border-radius: @radius;
}

.boxShadow(@boxShadow) {
	-moz-box-shadow: @boxShadow;
	-webkit-box-shadow: @boxShadow;
	box-shadow: @boxShadow;
}

.opacity(@opacity) {
	@opacityPercent: (@opacity * 100);
	opacity: @opacity;
	-ms-filter: ~"progid:DXImageTransform.Microsoft.Alpha(Opacity=@{opacityPercent})";
	filter: ~"alpha(opacity=@{opacityPercent})";
}

.wordWrap(@wordWrap: break-word) {
	-ms-word-wrap: @wordWrap;
	word-wrap: @wordWrap;
}

// Variables
@black: #000000;
@grey: #999999;
@light-grey: #CCCCCC;
@white: #FFFFFF;
@near-black: #030303;
@green: #51A351;
@red: #BD362F;
@blue: #2F96B4;
@orange: #F89406;
@default-container-opacity: .8;

// Styles
.toast-title {
	font-weight: bold;
}

.toast-message {
	.wordWrap();

	a,
	label {
		color: @white;
	}

		a:hover {
			color: @light-grey;
			text-decoration: none;
		}
}

// .toast-close-button {
// 	position: absolute;
// 	right: -0.3em;
// 	top: -0.3em;
// 	float: right;
// 	font-size: 20px;
// 	font-weight: bold;
// 	color: @white;
// 	-webkit-text-shadow: 0 1px 0 rgba(255,255,255,1);
// 	text-shadow: 0 1px 0 rgba(255,255,255,1);
// 	.opacity(0.8);
// 	line-height: 1;

// 	&:hover,
// 	&:focus {
// 		color: @black;
// 		text-decoration: none;
// 		cursor: pointer;
// 		.opacity(0.4);
// 	}
// }

// .rtl .toast-close-button {
// 	left: -0.3em;
// 	float: left;
// 	right: 0.3em;
// }

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
// button.toast-close-button {
// 	padding: 0;
// 	cursor: pointer;
// 	background: transparent;
// 	border: 0;
// 	-webkit-appearance: none;
// }

//#endregion

.toast-top-center {
  margin-top:40px;
	top: 0;
	right: 0;
	width: 100%;
}

.toast-bottom-center {
	bottom: 0;
	right: 0;
	width: 100%;
}

.toast-top-full-width {
	top: 0;
	right: 0;
	width: 100%;
}

.toast-bottom-full-width {
	bottom: 0;
	right: 0;
	width: 100%;
}

.toast-top-left {
	top: 12px;
	left: 12px;
}

.toast-top-right {
	top: 12px;
	right: 12px;
}

.toast-bottom-right {
	right: 12px;
	bottom: 12px;
}

.toast-bottom-left {
	bottom: 12px;
	left: 12px;
}

#toast-container {
	position: fixed;
	z-index: 999999;
  padding:0px 20px;
	// The container should not be clickable.
	pointer-events: none;
	* {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	> div {
    position:relative;
		// The toast itself should be clickable.
		pointer-events: auto;
		overflow: hidden;
		margin: 0 0 6px;
		padding: 30px 20px;
		max-width: 500px;
		// background-position: 15px center;
		// background-repeat: no-repeat;
		// color: @white;
		.opacity(@default-container-opacity);
	}

	> div.rtl {
		direction: rtl;
		padding: 15px 50px 15px 15px;
		background-position: right 15px center;
	}

	// > div:hover {
	// 	.boxShadow(0 0 12px @black);
	// 	.opacity(1);
	// 	cursor: pointer;
	// }

	// > .toast-info {
	// 	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
	// }

	// > .toast-error {
	// 	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAABPlBMVEUAAAD+/fz+/f39+/r9/Pz79/f8+Pf8+fj79vb69fX79fX58fH48PD37u347+727Ov16ej16Oby4d/x4N/u2NXt19Tt19Xs1dLt1dPt1tPs09Ds1NHr0c7oysboysfivrrivLjivbjhu7bivLjftrLes67dsa3dsq3csKvbrqncrqnZqaPZqaTaqqTaqqXYpqHWopzWop3Xo53SmZLSmZPTmZPSmJHSmJLSmZLRlo/QlI3RlY7RlY/PkYrOj4jKhn7Kh3/Hf3bHf3fFe3PEd2/EeG/EeHDDd27BcmnBcWjAcGfAbmW/bWS7Y1m5XlS5X1W0U0m0VEm0U0izUUayUEWzUEWxTEGxTUKwSj6wS0CtRDisQDSsQTWqPTGpOi2pOi6oOCynNyqoNyuoOCynNSinNimmMyamNCemNSimMyYR9bAiAAAAaXRSTlMAAgIEBAgICAoMDBASFBQYGhwkJjAyMjQ0NDY2OEJCUFJSVFRaXmBgYmRkampqam5ycnJ+fn6BgYGDhYWFiY2Xl6GhpampqauvsbO1t8PJydXV19nb29/f4eHp7e3x9fX3+fn5+/v9/f2McthOAAABd0lEQVR4AX2SA7pjQRQG+9m2bdu2FXPi1P43MNfspD6cX3GEm9qLi1pRhc4XeOmsWNcvxwBiy/XSum0tAPz9AcG1Nk/ZNLz1lgMyl83Nlxng39vWcJNR7r5+hPMAZG961aD3JgtAPvzxuqv4OzRKnztdwqBr+7OExp3iruHn+XSqw/2iHVOnzz9wrcgD6BdS+uFAOQswbWXjT0/jlpmDBeWMwbGVzcKsZY5gTDmNGd7lg3cyjer9IlknG9Ql+dLEGUzKBhNwpolRuJINrmBUV2Hijf5BQ5ywIfdg2T9Yhn1Ddmf4Mf4mPbOzPbqq+SbTLQxuYN6QQ0OGmIdbYdJXINSg96WSvmgIUegTFldw4h6cw5Xzt0uQGXS+xECGhOv3XYTfVtu2/MKScPECj7Z7gBfvHz4CR6Y5hIjvzz+Ugg1drkNqSPiYLlPaVMVGifK0kLBS1F7lCIqripUtynB/D2WllzOTBkjPiIqMRCE6IqrQ/vTU7k7+AzJFaDp8th3SAAAAAElFTkSuQmCC") !important;
	// }

	// > .toast-success {
	// 	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAA8FBMVEUAAAD8/f39/f39/v39/v77/Pz3+vnz9/bx9fTv9PPu9PLs8vDp8O7q8e/p8O7m7uzl7evk7evj7Oni6+ng6ufZ5uLF2NPD1tG+086908260cu70cu4z8m40Mq50Mq3zsi0zMa0zcexysStyMGqxr+oxb2nxLyjwbqiwLmdvbWZurKUt66VuK+Stq2QtKuMsqiKsKaKsKeIr6WJr6WJsKaErKJ+qJ11opZwn5JmmItnmYxklolglIdhlYddkoVdkoRakIJVjX5Ti31Ti3xQiXtRintPiHpIhHRGg3NEgXFEgXJFgnJDgHBDgHFDgXFCgHASgfC0AAAAT3RSTlMAAgICAgQKEBIUFhocHB4gIiQmKCoyTlBWWFxcXl5eYGRkaG5ydHZ6fIOJj4+TlZudnZ+fn6evu8HNzdHV1dnb3+Xn6evr7ff5+/v7/f39UQTx+QAAAUtJREFUeAGFk9myk0AQQDvqDeKexLiIiYviorgEDYsKRkDQoJz//xuLGYtJuKma88LDaWp66RaD423SuuvqdOM5cp7Fp4aBJlyM9DRoAYokipICoA2mh36eAbk/E8XMz4FsbvyygnI9kYHJuoRqOfxfQezKEW4M1fz/+xlsz2TE2RYynUcAsfEmIoZA1ddSunICt6Ttqw1hJSdZQSjiNOSTkbn4+WH/uZDTOOKBP/KXPtCpCB882cBMjnkP/LrRdww+Skox8m+B3/elpyCVmkQ0d66rzxugeyCKhFo6IlHc2+9uichr4O8j0UR0Q8DdPfy4La+AP4/FBAxP3NwBP98BPNVWP2GSvPYdzbPBqyRNmVe/AfDceFXmQaOufAVeGK8bddjqy194abRu9fGwnCfjYVnHbV8Yy8rZlta69vbDsZ+eYRGePl7r+f8DDFJgSOTZyIcAAAAASUVORK5CYII=") !important;
	// }

	// > .toast-warning {
	// 	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NjJGRDU3N0QwNDk5MTFFQUI1RjhFRTdCNjUzMzU1RDciIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjJGRDU3N0UwNDk5MTFFQUI1RjhFRTdCNjUzMzU1RDciPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2MkZENTc3QjA0OTkxMUVBQjVGOEVFN0I2NTMzNTVENyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2MkZENTc3QzA0OTkxMUVBQjVGOEVFN0I2NTMzNTVENyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhRGjdsAAAGkSURBVHjaYvz//z8DLQETA40B4458NlLUmwCxDBBvgAm4T/iJVwMLiQ7qB2IJIN4BxD+oHUQRQGwDxCpAXEDtOOAA4m4gPgHEa4C4GuoTqllQATUwFYhzgfgPELdTywIFIC4H4g4gvgLEL4C4FIgTgNiCGhaAXPoAiFuRxOYA8QFopFNkgQ00cjOxpBqQmMHOAvYYci0AJeHJSK4F8edDsQgQ34D6qhtoCQ85FiRAI7YUyv8DFQNhmIGgeHkDjSOSLBCAhn0hEH9AEv+CRv+BpqwSoC8USLGgHRoEK9DE36DRoKICljemE2sBKOmlQNM7OvgDxeigEpQggL6IIGQBKCJnA/ECIL6AxaAz0NyMAoC+eAKkOkHJFmiJAD4LSqClZS2OoAPFSSwOuR5oUm7HZQEokuqhSe8Fjoi/DcT3oQUeui9+QIMqA+gLC2wWgCIJ5NUJOFz4A5qivuAqqoGWrIDmmdlAS1iQLQBFjgc0CP7gsUAViCWhDmHAE4wa0OAG12ggr1+HFskTqVRTxkMzqSYLNFJgZXs9lavk6YxDvlUBEGAAtBhoxbW2qjIAAAAASUVORK5CYII=") !important;
	// }

	/*overrides*/
	&.toast-top-center > div,
	&.toast-bottom-center > div {
		// width: 300px;
		margin-left: auto;
		margin-right: auto;
	}

	&.toast-top-full-width > div,
	&.toast-bottom-full-width > div {
		width: 96%;
		margin-left: auto;
		margin-right: auto;
	}
}

.toast {
  display:flex;
  gap:20px;
  align-items:center;
  font-size:16px;
	background-color: #fff;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
  border-radius:9px;

  & > .icon {
    flex-shrink:0;
    width:46px;
    height:46px;
    border-radius:100px;
    position:relative;

    & > i {
      position:absolute;
      font-size:20px;
      color:#fff;
      top:12px;
      left:13px;
    }
  }

  .toast-close-button {
    position:absolute;
    top:10px;
    right:10px;
    font-size:13px;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
  }
  
  .content {
    flex-shrink:1;
    position:relative;

    .toast-message {
      line-height:1.2em;
      color:@color__grey-medium;
    }
  }

  &.actions {
    .content::after {
      content:'';
      width:1px;
      height:100%;
      background-color:@border;
      position:absolute;
      right:-20px;
      top:0px;
      bottom:0px;
    }
  }
  
  .actions {
    flex-shrink:0;
    position:relative;
    margin-left:20px;
  }

  &.toast-warning {
    & > .icon { background-color:#FFD15B; }
  }

  &.toast-success {
    & > .icon { background-color:@tertiary; }
  }

  &.toast-error {
    & > .icon { 
      background-color:@color__error;
      & > i {
        left:14px;
      }
    }
  }

  .breakpoint-mobile({
    flex-wrap:wrap;

    .actions {
      margin-left:0px;
    }

    &.actions .content::after {
      display:none;
    }
  });
}

.toast-progress {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 4px;
	background-color: @black;
	.opacity(0.4);
}

/*Responsive Design*/

// @media all and (max-width: 240px) {
// 	#toast-container {

// 		> div {
// 			padding: 8px 8px 8px 50px;
// 			width: 11em;
// 		}

// 		> div.rtl {
// 			padding: 8px 50px 8px 8px;
// 		}

// 		& .toast-close-button {
// 			right: -0.2em;
// 			top: -0.2em;
// 		}

// 		& .rtl .toast-close-button {
// 			left: -0.2em;
// 			right: 0.2em;
// 		}
// 	}
// }

// @media all and (min-width: 241px) and (max-width: 480px) {
// 	#toast-container {
// 		> div {
// 			padding: 8px 8px 8px 50px;
// 			width: 18em;
// 		}

// 		> div.rtl {
// 			padding: 8px 50px 8px 8px;
// 		}

// 		& .toast-close-button {
// 			right: -0.2em;
// 			top: -0.2em;
// 		}

// 		& .rtl .toast-close-button {
// 			left: -0.2em;
// 			right: 0.2em;
// 		}
// 	}
// }

// @media all and (min-width: 481px) and (max-width: 768px) {
// 	#toast-container {
// 		> div {
// 			padding: 15px 15px 15px 50px;
// 			width: 25em;
// 		}

// 		> div.rtl {
// 			padding: 15px 50px 15px 15px;
// 		}
// 	}
// }