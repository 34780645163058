.aro.button, .ui.aro.button {
  font-family:@font-family;
  position: relative;
  border-radius:6px;
  padding:10px 15px;
  background-color:@color__grey-light;
  border:none;
  font-size:16px;
  font-weight:@medium;
  line-height:1.15;
  cursor:pointer;
  user-select: none;
  display:inline-block;
  transition: box-shadow 0.2s ease, background-color 0.2s ease, transform 0.1s ease;

  &.loading {
    color:transparent !important;
    cursor:default !important;

    &:hover::before {
      //This hides the semantic styling that occurs on loading buttons on hover (little grey box in the corner)
      display:none;
      opacity:0;
    }
    
    &::after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      margin: -0.64285714em 0 0 -0.64285714em;
      width: 1.28571429em;
      height: 1.28571429em;
      border-radius: 500rem;
      animation: loader .6s infinite linear;
      border: 0.2em solid currentColor;
      color: #fff;
      box-shadow: 0 0 0 1px transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
    }
  }

  &:hover, &:focus, &:active {
    box-shadow:none;
    border-radius:6px;
  }

  &.fluid {
    width:100%;
    text-align:center;
  }

  &.circular {
    display:inline-flex;
    align-items:center;
    justify-content: center;
    appearance: none;
    border-radius:200px;
    height:44px;
    width:44px;
    overflow:hidden;
    text-align: center;
    padding:0px !important; //needed to overwrite semantic

    &.icon {
      & > i {
        font-size:20px;
        margin:0px;
      }
    }

    &.mini {
      height:30px;
      width:30px;
      padding:0px !important;
      line-height:1;

      //for some reason the line height property breaks on a tag (so we have to set a special line height if the button is an a tag)
      a& {
        line-height:1;
      }

      & > i {
        font-size:16px;
      }
    }

    &.extra-mini {
      height:24px;
      width:24px;
      padding:0px !important;
      line-height:18px;

      //for some reason the line height property breaks on a tag (so we have to set a special line height if the button is an a tag)
      a& {
        line-height:24px;
      }

      & > i {
        font-size:18px;
      }
    }

    &.medium {
      height:37px;
      width:37px;
      padding:0px !important;
      line-height:16px;

      & > i {
        font-size:16px;
      }
    }
    &.edit, &.delete, &.create, &.save-a-copy {
      overflow:visible;

      &:after {
        position: absolute;
        right: 50px;
        color: @color__grey-medium-dark;
        font-size:14px;
        text-align:right;
        min-width:150px;
      }
    }

    &.edit:after {
      content:'Edit';
    }

    &.create:after {
      content:'Create';
    }

    &.save-a-copy:after {
      content:'Save a copy';
    }

    &.delete:after {
      content:'Delete';
    }
  }

  &:hover::before {
    opacity:1;
  }

  &::before {
    /* Position the pseudo-element. */
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius:inherit;
  
    /* Create the box shadow at expanded size. */
    box-shadow: 0px 2px 10px rgba(60, 61, 73, 0.1);
  
    /* Hidden by default. */
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:active {
    transform:scale(.96);
    transition: background-color 0s;
  }
  
  &.icon {
    & > i {
      margin-right:10px;
    }

    &.big-icon > i {
      font-size:24px;
    }

    & > span {
      white-space: nowrap;
    }

    &.mobile {
      //Create the mixin
      .button-mixin {
        & > i {
          margin-right:0px;
        }
        & > span {
          display:none;
        }
      }

      //Apply mixin to force-mobile style
      &.force-mobile {
        .button-mixin();
      }

      // Apply mixin to mobile breakpoint
      .breakpoint-mobile({
        & {
          .button-mixin;
        }
      });
    }
  }
  
  &.medium {
    padding:13px 15px !important;
  }
  
  &.mini {
    padding:8px !important;
  }

  &.compact {
    height:39px;
    padding:10px 15px;
  }
  
  &.small {
    padding:8px 12px;
    font-size:14px;
    font-weight:@medium;
  }

  &.primary {
    color:#fff;
    background-color:@ios-purple;

    &:hover {
      background-color:lighten(@ios-purple, 10);
      // box-shadow: 0px 4px 15px fade(darken(@ios-purple, 20%), 33%);
    }

    &:active {
      background-color:@ios-purple;
    }
  }

  &.secondary {
    color:#fff;
    background-color:@secondary;

    &:hover {
      color:#fff;
      background-color:lighten(@secondary, 10);
    }

    &:active {
      background-color:@secondary;
    }
  }

  &.tertiary, &.success {
    background-color:@tertiary-light;
    color:@tertiary-dark;
    // outline:1px solid @tertiary-light;
    // transition: outline 0.2s ease;

    &:hover {
      // outline:1px solid @tertiary-dark;
      // background-color:lighten(@tertiary-light, 10);
      background-color:@tertiary-light;
      color:@tertiary-dark;
      // box-shadow: 0px 4px 15px fade(darken(@tertiary-light, 40%), 23%);
    }

    &:active {
      background-color:@tertiary-light;
    }
  }

  &.tertiary-outline {
    background-color:transparent;
    color:@tertiary-dark;
    outline:1px solid @tertiary-dark;
    // transition: outline 0.2s ease;

    &:hover {
      // outline:1px solid @tertiary-dark;
      // background-color:lighten(@tertiary-light, 10);
      background-color:@tertiary-light;
      color:@tertiary-dark;
      outline:1px solid @tertiary-light;
      // box-shadow: 0px 4px 15px fade(darken(@tertiary-light, 40%), 23%);
    }

    &:active, &.loading {
      background-color:@tertiary-light;
    }
  }

  &.transparent {
    padding-left:5px;
    padding-right:5px;
    background-color:transparent;
  }

  &.error {
    background-color:@color__error-light;
    color:@color__error-dark;
    // outline:1px solid @tertiary-light;
    // transition: outline 0.2s ease;

    &:hover {
      // outline:1px solid @tertiary-dark;
      // background-color:lighten(@tertiary-light, 10);
      background-color:@color__error-light;
      color:@color__error-dark;
      // box-shadow: 0px 4px 15px fade(darken(@tertiary-light, 40%), 23%);
    }

    &:active {
      background-color:@color__error-light;
    }
  }

  &.tertiary-light {
    background-color:@tertiary-light;
    color:@tertiary-dark;

    &:active {
      background-color:@tertiary-light;
    }
  }

  &.default {
    background-color:@color__grey-medium-light;
    color:#fff;

    &:hover {
      background-color:lighten(@color__grey-medium-light, 10);
      // box-shadow: 0px 4px 15px fade(darken(@color__grey-medium-light, 20%), 33%);
    }

    &:active {
      background-color:@color__grey-medium-light;
    }
  }

  &.default-outline {
    background-color:#fff;
    border:1px solid @border;
  }

  &.error-outline {
    background-color:transparent;
    border:1px solid @color__error;
    color:@color__error;
  }

  &.primary-outline {
    background-color:transparent;
    border:1px solid @primary-dark;
    color:@primary-dark;

    &:hover {
      background-color:@primary-dark;
      color:#fff;
    }
  }

  &.highlight-secondary {
    &:hover {
      color:@secondary;
    }
  }

  &.highlight-error {
    &:hover {
      color:@color__error;
    }
  }

  &.default-light {
    background-color:@border;
    color:@color__grey-medium;


    &:active {
      background-color:@border;
    }
  }

  &.secondary-light {
    background-color:#E6F6FC;
    color:@secondary;

    &:active {
      background-color:#E6F6FC;
    }
  }

  &.primary-light {
    background-color:@ios-purple-light;
    color:@primary;

    &:active {
      background-color:@ios-purple-light;
    }
  }

  &.primary-dark {
    border:1px solid @primary-dark;
    background-color:@primary-dark;
    color:#fff;

    &:active {
      background-color:@primary-dark;
    }

    &:hover {
      background-color:lighten(@primary-dark, 10);
    }
  }

  &.tertiary-white {
    color:#fff;
    background-color:@tertiary;

    &:hover {
      background-color:darken(@tertiary, 5);
    }

  }

  &.secondary-white {
    color:#fff;
    background-color:@secondary;

    &:hover {
      background-color:darken(@secondary, 5);
    }


  }

  &.primary-white {
    color:#fff;
    background-color:@ios-purple;

    &:hover {
      background-color:darken(@ios-purple, 5);
    }

  }

  &.discrete {
    background-color:transparent;
    color:@primary-dark;

    &.default-light {
      color:@color__grey-light;

      &:hover {
        color:@color__grey-dark;
      }
    }

    &.secondary {
      color:@secondary;

      &:hover {
        color:#fff;
      }
    }

    &:hover {
      color:@color__grey-medium;
    }

    &.tiny {
      font-size:13px;
    }

    &.slim {
      padding-left:0px;
      padding-right:0px;
    }

    //Dont show the drop shadow on hover
    &:hover::before {
      opacity:0;
    }
  }

  &.lowkey {
    background-color:transparent;
    color:@primary-dark;
    display:flex;
    align-items: center;

    &:hover::before {
      opacity:0;
    }

    &.slim {
      padding-left:0px;
      padding-right:0px;
    }

    &.secondary {
      color:@secondary;

      &:hover {
        background-color:transparent;
      }
    }
  }

  &.secondary-plain {
    color:@secondary;
    background-color:#fff;
  }

  &.error-plain {
    color:@color__error;
    background-color:#fff;
  }

  &.shadowed {
    box-shadow: 0px 11px 18.5023px rgba(0, 0, 0, 0.09);
  }
}

.aro.button-group {
  position:relative;
  overflow:hidden;

  .button-menu {
    position:absolute;
    right:0px;
    background-color:#fff;
    border-radius:16px;
    z-index:50;
    padding:6px 20px;
    display:flex;
    flex-direction: column;
    opacity:0;
    top:90px;
    transition: opacity .2s ease, top 0.2s ease-out;

    .button-select {
      cursor:pointer;
      white-space: nowrap;
      padding:10px 0px;
      border-bottom:1px solid @border;
      color:@color__grey-medium-dark;

      &:hover {
        color:@secondary;
      }

      & > i {
        margin-right:12px;
        color:@secondary;
      }

      &:last-child {
        border-bottom:none;
      }
    }
  }

  &.active {
    overflow:visible;

    .button-menu {
      box-shadow: 0px 4px 50px rgba(60, 61, 73, 0.08);
      opacity:1;
      top:60px;
    }
  }
}

.aro.divider {
  display:block;
  height:2px;
  background-color:@border;
}

.aro.heading {
  font-weight:@semi-bold;
  
  &.medium {
    font-size:1.5rem;
  }
}

.aro.label {
  padding:2px 7px;
  font-size:14px;
  font-weight:@medium;
  color:@color__grey-medium;
  background-color:@border;
  display:inline-block;
  border-radius:6px;
  white-space: nowrap;

  &.small {
    font-size:12px;
    padding:0px 5px;
    border-radius:5px;
  }

  &.success {
    background-color:@tertiary-light;
    color:@tertiary-dark;
  }

  &.warning {
    background-color:@color__warning-light;
    color:@color__warning-dark;
  }

  &.error {
    background-color:@color__error-light;
    color:@color__error-dark;
  }

  &.default {
    background-color:@border;
    color:@color__grey-medium-dark;
  }

  &.secondary {
    background-color:@secondary;
    color:#fff;
  }

  &.black {
    background-color:@primary-dark;
    color:#fff;
  }

  &.simple {
    display:flex;
    align-items:center;
    column-gap: 5px;
    background-color:transparent;
    padding:0px;
    font-weight:@semi-bold;
    line-height:1;

    &.success {
      color:@tertiary;
    }

    &.success-dark {
      color:@tertiary-dark;
    }

    &.primary {
      color:@ios-purple;
    }
  }

  &.pastel {
    &.green {
      background-color:#deece1;
    }
    &.red {
      background-color:#ff9899;
    }
    &.yellow {
      background-color:#fcf4dd;
    }
  }
}

.aro.label-group {
  &.group {
    display:flex;
    align-items:center;
    gap: 5px;
    flex-wrap: wrap;
  }

  .checkbox-label {
    display:flex;
    align-items:center;
    color:@color__grey-light;

    &:before {
      content:'';
      height: 10px;
      width: 10px;
      margin-left:3px;
      border-radius:2px;
      background-color:@border;
      margin-right:16px;
    }

    &.selected {
      font-weight: @medium;
      color:@primary-dark;

      &:before {
        content:'\e9c3';
        font-family: unicons-line;
        font-style: normal;
        font-weight: 400;
        color:@tertiary;
        padding:0px;
        height: auto;
        width: auto;
        border-radius:0px;
        background-color:transparent;
        margin-left:0px;
        margin-right:13px;
      }
    }
  }

  .radio-label {
    display:flex;
    align-items:center;
    color:@color__grey-light;

    &:before {
      content:'';
      height: 10px;
      width: 10px;
      margin-left:3px;
      border-radius:25px;
      background-color:@border;
      margin-right:16px;
    }

    &.selected {
      font-weight: @medium;
      color:@primary-dark;

      &:before {
        content:'';
        padding:0px;
        height: 10px;
        width: 10px;
        border:3px solid @border;
        background-color:@ios-purple;
      }
    }
  }
}

.aro.dot {
  &.small {
    width:14px;
    height:14px;
    display:flex;
    align-items:center;
    justify-content: center;
    font-size:10px;
    border-radius:100%;
  }

  &.success {
    background-color:@tertiary;
    color:#fff;
  }

  &.error {
    background-color:@color__error-light;
    color:@color__error-dark;
  }

  &.warning {
    background-color:@color__warning-dark;
  }

  &.primary {
    background-color:@ios-purple;
    color:#fff;
  }
}

.aro.dropdown, .aro.selection.dropdown {
  border:1px solid @border-medium;
  border-radius:6px;
  display:inline-block;
  line-height:1em;

  &:focus, &.active, &.active:hover {
    border:1px solid @ios-purple;
    background-color:lighten(@ios-purple, 55);
    box-shadow: 0px 1px 4px rgba(2, 9, 34, 0.12);

    .menu, .menu:hover {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border:1px solid @ios-purple;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }

  &:focus when (@platform = 'roboworx') {
    background-color:#fff;
  } 
  &.active when (@platform = 'roboworx') {
    background-color:#fff;
  } 
  &.active:hover when (@platform = 'roboworx') {
    background-color:#fff;
  } 

  &.select {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  }

  & > .dropdown.icon{
    font-family:unicons-line;
    color:@color__grey-medium-dark;
    font-size:20px;
    padding:6px;

    &::before {
      content:'\eb3a';
    }
  }

  &.dropdown > .default.text {
    color:@color__grey-light;
  }

  &.ui.selection.dropdown > .remove.icon {
    right: 3.3em;
    top: 10px;
  }

  &.compact {
    min-height:0px;
    height:39px;
    font-size:16px;
    padding:10px 45px 10px 15px;
  }

  & > i {
    position:absolute;
    right:15px;
  }
}

.aro.search.container {
  // border:1px solid @color__grey-medium-light;
  // border-radius:6px;

  display:inline-block;
  font-size:16px;
  position:relative;
  line-height:0px; //fixes weird whitespace issue

  & > input {
    font-family:@font-family;
    border:none;
  }

  & > input::placeholder {
    font-size:16px;
  }

  & > button {
    background-color:transparent;
    border:none;
    height:100%;
    cursor:pointer;
    position:absolute;
    right:5px;
    color:@color__grey-medium-light;
  }

  &.light {
    border:1px solid @color__grey-light;
  }

  &.compact {
    height:39px;

    & > input {
      padding:9px 15px;
    }

  }
}

.aro.form {

  .field-group {
    display:grid;
    //auto fill columns with equal width
    grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
    grid-template-rows: 1fr;
    gap:20px;
    padding:10px 0px;
  }

  .field, .grouped.fields {
    &.required {
      & > label:after {
        content:' *';
        color:@color__error;
      }
    }
  }

  & > .field, & > .grouped.fields {
    padding:10px 0px;

    &:first-child {
      padding-top:0px;
    }

    &:last-child {
      padding-bottom:0px;
    }

    &.error {
      input, & > .aro.input {
        border:1px solid @color__error-dark;
        background-color:@color__error-lighter;
      }

      .help-error {
        font-size:14px;
        color:@color__error-dark;
      }
    }

    &.disabled {
      input, & > .aro.input {
        background-color:@border-light;
        color:@color__grey-light;
        cursor:not-allowed;
      }
    }
  }

  & > .grouped.fields {
    .field {
      padding:4px 0px;

      &:first-child {
        padding-top:0px;
      }

      &:last-child {
        padding-bottom:0px;
      }
    }
  }

  .field > label, .grouped.fields > label {
    font-size:14px;
    font-weight:@medium;
    color:@color__grey-medium-dark;
    display:block;
    margin-bottom:6px;
  }

  .field > .aro.input, .field > .aro.search.input, .aro.input, .aro.search.input, .aro.textarea {
    font-family: @font-family;
    border:1px solid @border-medium;
    border-radius:6px !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
    margin: 0;
    outline: 0;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    padding: 10px 14px;
    background: #fff;
    font-size:14px;

    &.fluid {
      width:100%;
      max-width:100%;
    }

    &:focus {
      border:1px solid @ios-purple;
      background-color:lighten(@ios-purple, 55);
      box-shadow: 0px 1px 4px rgba(2, 9, 34, 0.12);
    }

    &:focus when (@platform = 'aroworks'){
      background-color:lighten(@ios-purple, 55);
    }

    &:focus when (@platform = 'roboworx'){
      background-color:#fff;
    }

    &:focus-visible {
      outline:none;
    }

    &::placeholder {
      color:@color__grey-lighter;
      opacity:1;
    }

    &.error {
      border:1px solid @color__error-dark;
      background-color:@color__error-lighter;
    }
  }

  .aro.textarea {
    resize:vertical;
  }

  .aro.checkbox {
    label {
      font-size:@font__small;
      font-weight:@medium;
      color:@color__grey-medium-dark;
    }
  }
}

.aro.modal {
  border-radius:16px;

  & > .header {
    position:relative;
    padding:30px 20px 20px;
    border-bottom:none;
    background-color:transparent;
    font-family:@font-family;

    & > h4 {
      margin-top:0px;
      color:@color__grey-medium;
    }
    
    & > i {
      position: absolute;
      right: 20px;
      font-size: 22px;
      cursor:pointer;
      transition:color 0.2s ease;

      &:hover {
        color:@color__grey-light;
      }
    }
  }

  & > .actions {
    background-color:transparent;
    border-top:1px solid @border;

    & > button.aro.button {
      margin-left:10px;
    }
  }

  & > .content {

    &.no-padding {
      padding:0px;
    }
  }

  &.overlay.fullscreen {
    bottom:0px;
  }
}

.aro.popup {
  &.invis {
    background-color:transparent;
    border:none;
    box-shadow:none;
    padding:0px;

    &:before {
      display:none;
    }
  }
}

.aro.details-group {
  // Add a 10px gap between elements
  & > * {
    margin:20px 0px;
    
    &:first-child { margin-top:0px;}
    &:last-child { margin-bottom:0px;}
  }
}

.aro.detail {
  & > .title {
    font-size:14px;
    font-weight:@medium;
    color:@color__grey-medium-light;

    &.strict {
      flex:0 0 auto;
    }
  }

  & > .description {
    font-weight:@medium;
    color:@primary-dark;

    &.word-break {
      word-break: break-word;
    }

    &.error {
      color:@color__error;
    }
  }

  & > h4 {
    margin:0px;
  }
  
  &.opaque {
    & > .title { color: fade(#ffffff, 90%); }
    & > .description { color: #fff; }
  }

  &.centered {
    text-align:center;
  }

  & > a.description:hover {
    text-decoration: underline;
  }

  &.inline {
    display:flex;
    align-items:center;
    justify-content:space-between;
    column-gap:20px;

    & > .description {
      font-weight:@semi-bold;
    }
  }

  &.highlighted {
    & > .description {
      color:@tertiary-dark;
      font-size:1.25em;
      font-weight:@regular;
    }
  }

  & > .lightbox-gallery {
    display:flex;
    flex-wrap:wrap;
    gap:10px;
    margin-top:6px;

    & > a {
      display:block;
      width:90px;
      height:90px;
      border-radius:6px;
      overflow:hidden;

      &:hover {
        outline:2px solid lighten(@ios-purple, 50);
        border: 1px solid @ios-purple;
      }

      img {
        width:100%;
        height:100%;
        object-fit:cover;
      }
    }
  
  }
}

.aro.link {
  &.h-underline{
    &:hover {
      text-decoration:underline;
    }
  }
}

.aro.card {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(60, 61, 73, 0.08);
  border-radius: 16px;
  position:relative;
  overflow:hidden;

  &.sm {
    max-width: 375px;
  }

  &.md {
    max-width: 600px;
  }

  &.fluid {
    width:100%;
  }

  &.create-card {
    max-width: 600px;
    margin:0px auto;
  }

  & > .close {
    cursor:pointer;
    padding:8px;
    position:absolute;
    top:8px;
    right:8px;
    transition: color 0.2s ease;
    
    &.light {
      color:#fff;

      &:hover {
        color:darken(#fff, 10%);
      }
    }
  }

  &.flex {
    display:flex;
    flex-direction:column;
    height:100%;
  }

  &.inner {
    border-radius:0px;
    box-shadow:none;
  }

  &.invis {
    background-color: transparent;
    box-shadow:none;
    border-radius: 0px;
    overflow:visible;
    position:static;
  }

  &.overflow {
    overflow:visible;
  }

  &.loading::after {
    content:'';
    position:absolute;
    // background-color:rgba(255,255,255,.55);
    width:100%;
    height:100%;
    top:0px;
    border-radius:inherit;
  }
  
  & > .header-image {
    height:345px;
    width:100%;
    background-size:cover;
    background-position: center center;
  }

  & > .header-images {
    max-height:375px;

    .swiper-slide {
      text-align:center;
    }

    .slide-image {
      height:375px;
      width:auto;
      overflow:hidden;
      display:flex;
      align-content: center;
      justify-content: center;
    }

    & > .swiper > .swiper-pagination {
      bottom:30px;

      .swiper-pagination-bullet-active {
        background-color:@ios-purple;
      }
    }
  }

  & > .header {
    padding:30px 20px;

    &.primary {
      background-color: @ios-purple;
      color:#fff;
    }

    &.top.rounded {
      border-radius:16px 16px 0px 0px;
      margin-top:-16px;
      z-index: 1;
      position: relative;
    }

    &.basic {
      font-weight:@semi-bold;
      color:@color__grey-medium;

      & > .title {
        color:@primary-dark;
      }

      & > .description {
        margin-top:10px;
        font-size:.875em;
        font-weight:@regular;
        color:@color__grey-medium-light;
      }
    }

    &.small {
      color:@primary-dark;
      font-size:@font__small;
      font-weight: @medium;
    }

    &.centered {
      text-align: center;
    }

    &.navigation {
      position:relative;

      .back {
        position:absolute;
        top:22px;
      }
    }
  }

  & > .content {
    padding:20px;
    position:relative;

    &.no-padding { padding:0px; }

    &.centered {
      text-align: center;
    }

    &.right-aligned {
      text-align:right;
    }

    .no-items {
      padding:20px;
      text-align:center;
      i {
        font-size:24px;
        color:@color__grey-light;
        margin-bottom:8px;
      }
  
      p {
        font-size:14px;
        font-weight:@medium;
        color:@color__grey-light;
      }
    }

    &.narrow-grids {
      .ui.grid > .wide.column {
        padding-top:10px;
        padding-bottom:10px;
      }
    }

    &.bordered-bottom {
      border-bottom:1px solid @border;
    }

    &.bordered-top {
      border-top:1px solid @border;
    }
  }

  & > .footer {
    padding:20px 20px 30px;

    &.right-aligned {
      text-align:right;
    }

    &.top-bordered {
      border-top:1px solid @border;
    }
  }

  & > .footer, & > .content {
    .subheading {
      font-weight:@semi-bold;
      color:@color__grey-medium;
      margin-bottom:20px;
    }
  }

  & > .delete-block {
    display:flex;
    justify-content: center;
    padding:30px 0px;
    margin-top:auto;

    .confirm-block {
      display:flex;
      align-items:center;
      flex-direction:column;
    }
  }

  & > .button-group-block {
    display:flex;
    align-items:center;
    justify-content:center;
    gap:20px;
  }

  &.error {

    & > .header.basic {
      color:@color__error-dark;
    }

    background-color:@color__error-light;
    color:@color__error-dark;
  }

  //Variations
  &.outlined {
    box-shadow:none;
    border:2px solid @border-medium;
  }

  &.warning {
    border:1px solid @color__warning-dark;
  }
}

.aro.sidebar {
  background-color:#fff;
  max-width:430px;
  width:100% !important;
  z-index:102 !important;
  
  & > .close {
    position:absolute;
    padding:20px;
    top:0px;
    right:0px;
    cursor:pointer;
    font-size:24px;
    color:@color__grey-medium;

    &:hover {
      color:@color__grey-light;
    }
  }

  & > .header {
    padding:80px 20px 20px;
    border-bottom:1px solid rgba(0,0,0,.12);
  }

  .content {
    padding:20px;
  }

  .basic-error {
    color:@color__error-dark;
  }
}

.aro.listing-group {
  // just putting this here and leaving it empty for now. Just so you know that this class is used in the field
  // it's only currently used for the list js plugin
}

.aro.listing {
  display:flex;
  align-items: center;
  position:relative;
  padding-left:20px;
  padding-right:20px;
  transition: background-color 0.2s ease;

  

  &.clickable {
    cursor:pointer;
  
    &:hover {
      background-color: @border;
    }
  }

  &.compact {
    padding-top:18px;
    padding-bottom:18px;
  }

  & > .content {
    flex:1 1 auto;
    padding:0px 16px;
    color:@primary-dark;

    .title {
      color:@primary-dark;
      font-weight:500;
    }

    .description {
      font-size:@font__small;
      color:@color__grey-medium-light;
    }

  }

  &.icon {
    & > .icon {
      flex-basis: 24px;
      font-size:20px;
      color:@secondary;

      &.aro-primary {
        color:@ios-purple;
      }
    }
  }

  &.image {
    padding-top:10px;
    padding-bottom:10px;

    & > .image {
      flex:0 0 30px;
      img { 
        max-width:100%;
        display:block;
      }
    }
  }

  &.arrowed {
    &:after {
      display:block !important;
    }
  }

  &.labeled {
    column-gap:20px;

    &:after {
      display:none;
    }

    .content {
      padding:0px;
    }

    .title {
      color:@primary-dark;
      font-weight:500;
    }

    .description {
      font-size:@font__small;
      color:@color__grey-medium-light;
    }
  }

  &.highlighted {
    & > .content {
      color:@secondary;
    }
  }

  &.super-basic {
    &:after {
      display:none;
    }

    .title {
      color:@primary-dark;
    }

    .content {
      padding:0px;
    }
  }

  &.tabled {
    .content {
      .title {
        font-size:@font__small;
        color:@color__grey-medium-light;
      }
    }
  }

  &.extra-plain {
    padding:15px 0px;
    cursor:pointer;

    &:after {
      display:none;
    }

    &:before {
      left:0px;
    }

    .title {
      color:@color__grey-medium;
    }

    &:hover {
      .title {
        color:@primary-dark;
      }
    }
  }

  &.basic {
    &:after {
      display:none;
    }

    .content {
      padding:0px;
    }

    .title {
      color:@primary-dark;
    }

    .description {
      font-size:@font__small;
      color:@color__grey-medium-light;
    }
  }

  &.plain {
    padding:15px 20px;

    &:after {
      display:none;
    }

    & > .content {
      padding:0px;

      .title {
        line-height:1.5;
      }

      .description {
        line-height:1.5;
      }
    }
  }

  &.nested {
    padding-left:0px;
    padding-right:0px;
  }

  &::before {
    content:'';
    height:1px;
    background-color: @border;
    position:absolute;
    bottom:0px;
    display:block;
    left:20px;
    right:20px;
  }

  &:last-child:before, &.last:before {
    display:none;
  }

  &::after {
    content:'\eb9f';
    font-family: unicons-line;
    font-style: normal;
    font-weight: 400;
    display: inline-block;
    text-decoration: inherit;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color:@primary-dark;
  }

  .options-block {
    position:absolute;
    width:100%;
    height:100%;
    background-color: rgba(0, 24, 113, 0.8);
    top:0px;
    right:0px;
    display:none;
    align-items: center;
    justify-content: center;
    gap:20px;
    padding:20px;
    opacity:0;
    overflow:hidden;
    transition:opacity 0.5s ease;

    .option {
      background-color:#fff;
      border-radius:100px;
      height:44px;
      width:44px;
      display:flex;
      align-items: center;
      justify-content: center;
      color:@color__grey-medium;
      font-size:19px;
      line-height:1;
      opacity:0;
      //animate background color fade
      transition-duration: 0.2s, 0.3s, 0.4s;
      transition-property: background-color, transform, opacity;
      transition-timing-function: ease, ease-out, ease;
      transition-delay: 0s, 1s, 0s;
      //translate down 200px
      transform:translateY(100px);

      &:hover {
        //lighten the @border by 10%
        background-color: @border;
      }

      &:nth-child(1) { transition-delay:0s, 0s, 0s;}
      &:nth-child(2) { transition-delay:0s, 0.1s, 0s;}
      &:nth-child(3) { transition-delay:0s, 0.15s, 0s;}
      &:nth-child(4) { transition-delay:0s, 0.2s, 0s;}
      &:nth-child(5) { transition-delay:0s, 0.25s, 0s;}
      &:nth-child(6) { transition-delay:0s, 0.3s, 0s;}
      &:nth-child(7) { transition-delay:0s, 0.35s, 0s;}
    }
  }

  &.options-visible {
    .options-block {
      display:flex;
    }
  }

  &.options-active {
    .options-block {
      opacity:1;

      .option {
        opacity:1;
        transform:translateY(0px);
      }
    }
  }
}

.aro.ui.calendar {
  .ui.calendar.active {
    table tbody > tr > td.link.focus {
      box-shadow:inset 0 0 0 1px @ios-purple;
    }
  }
}

.aro.message.wrapper.top {
  position:absolute;
  top:70px;
  left:0px;
  right:0px;
  display:flex;
  justify-content: center;
  padding:0px;
  z-index:20;
}

.aro.message {
  padding:10px 50px 10px 25px;
  border-radius:10px;
  display:inline-block;
  position:relative;

  &.fluid {
    display:block;
    width:100%;
  }

  &.error {
    background-color:@color__error-light;
    color:@color__error-dark;
  }

  &.error-light {
    background-color:lighten(@color__error-light, 10);
    color:@color__error-dark;
    border:1px solid @color__error-dark;
  }

  &.warning-light {
    background-color:lighten(@color__warning-light, 10);
    color:@color__warning-dark;
    border:1px solid @color__warning-dark;
  }

  &.success {
    background-color:@tertiary-light;
    color:@tertiary-dark;
  }

  &.top {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  &.centered {
    margin:0 auto;
  }

  & > i {
    position:absolute;
    padding:10px;
    cursor:pointer;
    right:5px;
    top:0px;
  }
}

.aro.fileuploader, .aro.form .aro.fileuploader {
  & > label {
    padding:0px;
    border: 2px dashed @border-medium;
    border-radius: 8px;
    display:flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    padding:20px 0px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
    color:@color__grey-medium;
    margin:0px 0px 10px 0px;

    &:hover {
      border-color:darken(@border-medium, 10);
    }

    & > i {
      font-size:20px;
      margin-right:8px;
    }

    &.is-dragover {
      border-color: @secondary;
    }
  }

  & > .file-list {
    margin:0px;
    padding:0px;
  }
}

.aro.item {
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding:12px 44px 12px 10px;
  border:1px solid @border;
  box-shadow: @field-shadow;
  border-radius:6px;
  margin:8px 0px;
  position:relative;
  overflow:hidden;

  &:hover {
    border:1px solid darken(@border, 10);
  }

  &.draggable {
    cursor: grab;
    background-color:#fff;

    &.dragging {
      cursor: grabbing;
    }

    & > .grabber {
      margin-right:10px;
    }

    & > .title {
      margin-right:auto;
    }
  }
  
  & > .title {
    font-size:16px;
    font-weight:@medium;
    color:@color__grey-medium;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & > .action {
    font-size:18px;
    position:absolute;
    cursor:pointer;
    padding:12px;
    right:0px;
    top:0px;
    bottom:0px;

    &.error {
      color: @color__error;
      transition: color 0.2s ease;

      &:hover {
        color: darken(@color__error, 10);
      }
    }
  }
}

.aro.table {
  border-radius:0px;
  margin-top:0px;
  transform-style: preserve-3d;
  min-width:100%;

  &.small {
    font-size:15px;
  }

  & > thead {
    will-change: transform;

    .sortable {
      cursor:pointer;
      transition: background-color 0.2s ease;
      
      &:hover {
        background-color:darken(@thead, 2);
      }
    }
  }
  // TH styles
  & > thead > tr > th {
    position:relative;
    color:@color__grey-medium;
    font-weight:@semi-bold;
    background-color: @thead;
    //Might want to get rid of this padding to have more room for data
    padding-left:20px !important;
    padding-right:20px !important;
    user-select: none;

    & > i {
      color:@color__grey-lighter;
      text-align: right;
      position: absolute;
      right: 8px;
      top: 15px;
    }

    &.sort-active > i {
      color:@color__grey-medium;
    }
    
  }

  // TD styles
  & > tbody > tr > td {
    position:relative;
    color:@primary-dark;
    overflow-y:visible;
    //Might want to get rid of this padding to have more room for data
    padding-left:20px !important;
    padding-right:20px !important;
    cursor:auto;


    a {
      color:#005a7a;
      font-weight:@semi-bold;
      display:block;

      &:hover {
        text-decoration:underline;
      }
    }
  }

  // TR styles
  & > tbody > tr {
    transform-style: preserve-3d;

    &:hover td:not(.hidden):first-child .row-options, //this styles the td that doesn't have class .hidden AND is first child
    &:hover td:not(.hidden) .row-options{ //this styles the td that is the first sibling of td with class hidden (this will style first sibiling on each side)
      opacity:100;
      transform:scale(1.0);
    }

    &:hover td:not(.hidden) ~ td .row-options { //hides every option class after the first visible column (used to correct styling of sibling on each side)
      opacity:0;
      transform:scale(0.9);
    }

    &.selectable {
      & > td {
        cursor:pointer;
      }

      &:hover {
        & > td {
          background-color:@border-light !important;
        }
      }
    }
  }

  &.striped {
    & > tbody > tr:nth-child(even) > td {
      background-color:#fafafb;
    }
    & > tbody > tr:nth-child(odd) > td {
      background-color:#fff;
    }
  }

  .row-options {
    opacity:0;
    transform:scale(0.9);
    display:flex;
    position: absolute;
    background-color: #fff;
    padding: 0px 4px 0px 20px;
    right: 0px;
    height: 100%;
    top: 0px;
    font-size:14px;
    align-items:center;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10%);
    transition: opacity 0.2s ease, transform 0.2s ease;

    
    & > .option {
      cursor:pointer;
      display:flex;
      align-items: center;
      color:@color__grey-medium;
      font-weight:@semi-bold;
      margin:0px 0px;
      padding:4px 8px;
      border-radius:6px;
      transition: color 0.2s ease, background-color 0.2s ease;

      & > i {
        font-size:16px;
        margin-right:5px;
      }
      
      &:hover {
        background-color:@border;
        color:@ios-purple;
        text-decoration:underline;
      }
    }
  }

  .trueGrip {
    position:absolute;
    border-left:6px solid rgba(66,134,244, 0);
    height:100%;
    top:0px;
    right:0px;
    cursor: w-resize;
    z-index:21;
    transform: translateZ(15px);
  }
  
  .trueGrip:hover {
    border-left:6px solid rgba(66,134,244, 1.0);
  }
}

.aro.dropdown.checkbox-group {

  &.active, &.active:hover {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom:none;
  }

  .menu, .menu:hover {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border:1px solid @ios-purple;
    border-top:none !important;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    left: -1px;
    right: -1px;
  }

  .select-button-group > span {
    font-size:14px;
    cursor:pointer;
    transition: color 0.2s ease;

    &:hover {
      color:@ios-purple;
      text-decoration: underline;
    }
  }

  input[type="checkbox"] {
    margin-right:5px;
  }

  .menu .item {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
}

.aro.collapsable-group {
  opacity:1;
  transition:max-height 0.3s ease, opacity 0.3s ease;

  &.collapsed {
    max-height:0px !important;
    overflow:hidden;
    opacity:0;
  }
}

.aro.pill-group {
  display:flex;
  align-items:center;
  gap:10px;

  &.scrollable {
    overflow-x:auto;
    overflow-y:hidden;
    //hide the scrollbar
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    //hide scrollbar in chrome and safari
    &::-webkit-scrollbar {
      display: none;
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }

    & > .pill {
      flex:0 0 auto;
    }
  }

  & > .pill {
    
    border:1px solid @ios-purple;
    border-radius:100px;
    color:@ios-purple;
    padding:10px 20px;
    user-select: none;
    transition:background-color 0.2s ease;

    &.active {
      background-color:@ios-purple;
      color:#fff;
    }

    &:hover {
      background-color:@ios-purple;
      color:#fff;
    }
  }
}

.aro.popup-menu {
  border-radius:8px;
  position:absolute;
  z-index:50;
  background-color:#fff;
  overflow:hidden;
  padding:10px 0px;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15);

  .item {
    display:block;
    color:@primary-dark;
    padding:10px 20px;
    font-size:1em;
    cursor:pointer;
    //don't wrap text
    white-space:nowrap;
    transition:background-color 0.2s ease;

    &:hover {
      background-color:@border-light;
    }
  }

  //loop though numbers by 5s to create classes
  each(range(0, 50, 5), {
    &.top-@{value} {
      top:~"@{value}px";
    }
    &.bottom-@{value}  {
      bottom:~"@{value}px";
    }
    &.left-@{value}  {
      left:~"@{value}px";
    }
    &.right-@{value}  {
      right:~"@{value}px";
    }
  });

  &.origin-top-right {
    transform-origin: top right;
  }
  &.origin-top-left {
    transform-origin: top left;
  }

  //Animation classes
  &.enter {
    transition: opacity 0.1s ease-out, transform 0.1s ease-out;
  }
  &.enter-start {
    opacity:0;
    transform:scale(0.95);
  }
  &.enter-end {
    opacity:1;
    transform:scale(1);
  }

  &.leave {
    transition: opacity 0.1s ease-in, transform 0.1s ease-in;
  }
  &.leave-start {
    opacity:1;
    transform:scale(1);
  }
  &.leave-end {
    opacity:0;
    transform:scale(0.95);
  }
}

.aro.meta-group {
  font-size:.875em;
  display:flex;
  align-items:center;
  gap:10px;

  & > .title {
    color:@color__grey-medium-light;
  }

  & > .description {
    color:@primary-dark;
    font-weight:@medium;
  }
}

.aro.meta-item {
  display:flex;
  gap:10px;

  i {
    font-size:1.2em;
    color:@tertiary;
  }

  a:hover {
    text-decoration:underline;
  }

  .content {
    font-size:.875em;
    color:@color__grey-medium-dark;
    font-weight:@medium;
  }
}

.aro.tabs-overflow {
  position:relative;
  //add a gradient to the right side of the tabs
  &:after {
    content:'';
    position:absolute;
    top:0px;
    right:0px;
    bottom:0px;
    width:40px;
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  }
}

.aro.tabs {
  width: 100%;
  border-bottom:1px solid @border;
  display:flex;
  flex-wrap:wrap;
  gap:30px;

  & > .tab {
    padding:20px 0px;
    cursor:pointer;
    transition:color 0.2s ease;
    font-weight:@regular;
    color:@color__grey-medium-light;
    flex-shrink:0;

    &.active {
      border-bottom:1px solid @ios-purple;
      color:@ios-purple;
      p {
        font-weight:@semi-bold;
      }
    }

    &:hover {
      color:@ios-purple;
    }
  }

  &.no-wrap {
    flex-wrap:nowrap;
    overflow:auto;
    padding-right:40px;

    //hide the scrollbar
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    //hide scrollbar in chrome and safari
    &::-webkit-scrollbar {
      display: none;
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }
  }
}

.aro.banner {
  background-color:lighten(@border, 5);
  border-radius:8px;
  padding:20px;
  text-align:center;
  font-size:1rem;
  font-weight:@medium;
  width:100%;
  border:1px solid @border;

  &.error {
    background-color:lighten(@color__error-light, 5);
    color:@color__error-dark;
  }
}