/* 
  HOW I PLAN ON SETTING ALL THIS UP.
  If a class start with aro-* this means that it is a "component." (Think of it as a class component) Meaning every class wrapped inside of it are only used inside of that component
  Thus, I can technically change all the classes inside of the aro-* class and only that component will be affected.
*/
html {
  font-size: 16px;
}
body {
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  margin: 0px;
  background-color: #F4F6F8;
  color: #232229;
}
[x-cloak] {
  display: none !important;
}
[x-hide] {
  display: none !important;
}
body.pushable > .pusher {
  background-color: #f4f6f8;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Inter', sans-serif;
}
p {
  font-size: 1em;
  font-weight: 400;
  margin: 0px;
}
h3 {
  font-size: 24px;
  font-weight: 600;
}
h4 {
  font-size: 18px;
  font-weight: 600;
}
/*******************************
        Semantic Overrides
*******************************/
.ui.calendar .ui.table tr .completed {
  color: #B01B0C !important;
  background-color: #FFC6C1 !important;
}
.ui.form,
.ui.form .field .dropdown,
.ui.form .field .dropdown .menu > .item {
  font-size: 0.875rem;
}
a {
  color: #232229;
}
a:hover {
  color: #232229;
}
.ui.accordion .title:not(.ui) {
  padding: unset;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}
.aro.form .ui.labeled.input > input {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.aro.form .ui.labeled.input > input:focus {
  border-left: 1px solid #39449C !important;
}
.text__small {
  font-size: 0.875rem;
}
.text__regular {
  font-size: 1rem;
}
.text__medium {
  font-size: 1.125rem;
}
.text__medium-large {
  font-size: 1.25rem;
}
.text__large {
  font-size: 1.5rem;
}
.text__xlarge {
  font-size: 2rem;
}
.text__xxlarge {
  font-size: 2.5rem;
}
.text__body-bold {
  font-size: 16px;
  font-weight: 600;
}
.text__right {
  text-align: right;
}
.text__center {
  text-align: center;
}
.text__interactive {
  cursor: pointer;
}
.text__interactive:hover {
  text-decoration: underline;
}
.color__grey-dark {
  color: #232229;
}
.color__grey-medium-dark {
  color: #39393F;
}
.color__grey-medium-light {
  color: #65666B;
}
.color__grey-medium {
  color: rgba(0, 0, 0, 0.7);
}
.color__grey-light {
  color: #727380;
}
.color__grey-lighter {
  color: #A7A9B2;
}
.color__secondary {
  color: #00A3E0;
}
.color__ios-purple {
  color: #39449C;
}
.color__primary-dark {
  color: #232229;
}
.color__primary-dark-impt {
  color: #232229 !important;
}
.color__tertiary-dark {
  color: #006B57;
}
.color__error {
  color: #FF331F;
}
.color__error-impt {
  color: #FF331F !important;
}
.color__error-dark {
  color: #B01B0C;
}
.color__error-dark-impt {
  color: #B01B0C !important;
}
.medium {
  font-weight: 500;
}
.semi-bold {
  font-weight: 600;
}
.bold {
  font-weight: 700;
}
.hidden {
  display: none;
}
.hidden-impt {
  display: none !important;
}
.lg-tablet-visible {
  display: none !important;
}
@media screen and (max-width: 1200px) {
  .lg-tablet-visible {
    display: block !important;
  }
}
@media screen and (max-width: 1200px) {
  .lg-tablet-hidden {
    display: none !important;
  }
}
@media screen and (max-width: 480px) {
  .mobile-hidden {
    display: none !important;
  }
}
@media screen and (min-width: 1200px) {
  .desktop-hidden {
    display: none !important;
  }
}
.inline-block {
  display: inline-block;
}
.hoverable {
  cursor: pointer;
  color: #4183c4;
}
.hoverable:hover {
  text-decoration: underline;
  color: #3269a0;
}
.hoverable.active {
  text-decoration: underline;
  font-weight: 700;
}
.row-gap-20 {
  row-gap: 20px;
}
.b-t-dashed {
  border-top: 1px dashed #EAEBEF;
}
.b-b-solid {
  border-bottom: 1px solid #EAEBEF;
}
.b-t-solid {
  border-top: 1px solid #EAEBEF;
}
.m-t-a {
  margin-top: auto !important;
}
.m-0-a {
  margin: 0 auto !important;
}
.m-l--50 {
  margin-left: -50px !important;
}
.m-r--50 {
  margin-right: -50px !important;
}
.m-t--50 {
  margin-top: -50px !important;
}
.m-b--50 {
  margin-bottom: -50px !important;
}
.p-l--50 {
  padding-left: -50px !important;
}
.p-r--50 {
  padding-right: -50px !important;
}
.p-t--50 {
  padding-top: -50px !important;
}
.p-b--50 {
  padding-bottom: -50px !important;
}
.p--50 {
  padding: -50px !important;
}
.row-gap--50 {
  row-gap: -50px !important;
}
.m-l--45 {
  margin-left: -45px !important;
}
.m-r--45 {
  margin-right: -45px !important;
}
.m-t--45 {
  margin-top: -45px !important;
}
.m-b--45 {
  margin-bottom: -45px !important;
}
.p-l--45 {
  padding-left: -45px !important;
}
.p-r--45 {
  padding-right: -45px !important;
}
.p-t--45 {
  padding-top: -45px !important;
}
.p-b--45 {
  padding-bottom: -45px !important;
}
.p--45 {
  padding: -45px !important;
}
.row-gap--45 {
  row-gap: -45px !important;
}
.m-l--40 {
  margin-left: -40px !important;
}
.m-r--40 {
  margin-right: -40px !important;
}
.m-t--40 {
  margin-top: -40px !important;
}
.m-b--40 {
  margin-bottom: -40px !important;
}
.p-l--40 {
  padding-left: -40px !important;
}
.p-r--40 {
  padding-right: -40px !important;
}
.p-t--40 {
  padding-top: -40px !important;
}
.p-b--40 {
  padding-bottom: -40px !important;
}
.p--40 {
  padding: -40px !important;
}
.row-gap--40 {
  row-gap: -40px !important;
}
.m-l--35 {
  margin-left: -35px !important;
}
.m-r--35 {
  margin-right: -35px !important;
}
.m-t--35 {
  margin-top: -35px !important;
}
.m-b--35 {
  margin-bottom: -35px !important;
}
.p-l--35 {
  padding-left: -35px !important;
}
.p-r--35 {
  padding-right: -35px !important;
}
.p-t--35 {
  padding-top: -35px !important;
}
.p-b--35 {
  padding-bottom: -35px !important;
}
.p--35 {
  padding: -35px !important;
}
.row-gap--35 {
  row-gap: -35px !important;
}
.m-l--30 {
  margin-left: -30px !important;
}
.m-r--30 {
  margin-right: -30px !important;
}
.m-t--30 {
  margin-top: -30px !important;
}
.m-b--30 {
  margin-bottom: -30px !important;
}
.p-l--30 {
  padding-left: -30px !important;
}
.p-r--30 {
  padding-right: -30px !important;
}
.p-t--30 {
  padding-top: -30px !important;
}
.p-b--30 {
  padding-bottom: -30px !important;
}
.p--30 {
  padding: -30px !important;
}
.row-gap--30 {
  row-gap: -30px !important;
}
.m-l--25 {
  margin-left: -25px !important;
}
.m-r--25 {
  margin-right: -25px !important;
}
.m-t--25 {
  margin-top: -25px !important;
}
.m-b--25 {
  margin-bottom: -25px !important;
}
.p-l--25 {
  padding-left: -25px !important;
}
.p-r--25 {
  padding-right: -25px !important;
}
.p-t--25 {
  padding-top: -25px !important;
}
.p-b--25 {
  padding-bottom: -25px !important;
}
.p--25 {
  padding: -25px !important;
}
.row-gap--25 {
  row-gap: -25px !important;
}
.m-l--20 {
  margin-left: -20px !important;
}
.m-r--20 {
  margin-right: -20px !important;
}
.m-t--20 {
  margin-top: -20px !important;
}
.m-b--20 {
  margin-bottom: -20px !important;
}
.p-l--20 {
  padding-left: -20px !important;
}
.p-r--20 {
  padding-right: -20px !important;
}
.p-t--20 {
  padding-top: -20px !important;
}
.p-b--20 {
  padding-bottom: -20px !important;
}
.p--20 {
  padding: -20px !important;
}
.row-gap--20 {
  row-gap: -20px !important;
}
.m-l--15 {
  margin-left: -15px !important;
}
.m-r--15 {
  margin-right: -15px !important;
}
.m-t--15 {
  margin-top: -15px !important;
}
.m-b--15 {
  margin-bottom: -15px !important;
}
.p-l--15 {
  padding-left: -15px !important;
}
.p-r--15 {
  padding-right: -15px !important;
}
.p-t--15 {
  padding-top: -15px !important;
}
.p-b--15 {
  padding-bottom: -15px !important;
}
.p--15 {
  padding: -15px !important;
}
.row-gap--15 {
  row-gap: -15px !important;
}
.m-l--10 {
  margin-left: -10px !important;
}
.m-r--10 {
  margin-right: -10px !important;
}
.m-t--10 {
  margin-top: -10px !important;
}
.m-b--10 {
  margin-bottom: -10px !important;
}
.p-l--10 {
  padding-left: -10px !important;
}
.p-r--10 {
  padding-right: -10px !important;
}
.p-t--10 {
  padding-top: -10px !important;
}
.p-b--10 {
  padding-bottom: -10px !important;
}
.p--10 {
  padding: -10px !important;
}
.row-gap--10 {
  row-gap: -10px !important;
}
.m-l--5 {
  margin-left: -5px !important;
}
.m-r--5 {
  margin-right: -5px !important;
}
.m-t--5 {
  margin-top: -5px !important;
}
.m-b--5 {
  margin-bottom: -5px !important;
}
.p-l--5 {
  padding-left: -5px !important;
}
.p-r--5 {
  padding-right: -5px !important;
}
.p-t--5 {
  padding-top: -5px !important;
}
.p-b--5 {
  padding-bottom: -5px !important;
}
.p--5 {
  padding: -5px !important;
}
.row-gap--5 {
  row-gap: -5px !important;
}
.m-l-0 {
  margin-left: 0px !important;
}
.m-r-0 {
  margin-right: 0px !important;
}
.m-t-0 {
  margin-top: 0px !important;
}
.m-b-0 {
  margin-bottom: 0px !important;
}
.p-l-0 {
  padding-left: 0px !important;
}
.p-r-0 {
  padding-right: 0px !important;
}
.p-t-0 {
  padding-top: 0px !important;
}
.p-b-0 {
  padding-bottom: 0px !important;
}
.p-0 {
  padding: 0px !important;
}
.row-gap-0 {
  row-gap: 0px !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.p-l-5 {
  padding-left: 5px !important;
}
.p-r-5 {
  padding-right: 5px !important;
}
.p-t-5 {
  padding-top: 5px !important;
}
.p-b-5 {
  padding-bottom: 5px !important;
}
.p-5 {
  padding: 5px !important;
}
.row-gap-5 {
  row-gap: 5px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.p-l-10 {
  padding-left: 10px !important;
}
.p-r-10 {
  padding-right: 10px !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-b-10 {
  padding-bottom: 10px !important;
}
.p-10 {
  padding: 10px !important;
}
.row-gap-10 {
  row-gap: 10px !important;
}
.m-l-15 {
  margin-left: 15px !important;
}
.m-r-15 {
  margin-right: 15px !important;
}
.m-t-15 {
  margin-top: 15px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.p-l-15 {
  padding-left: 15px !important;
}
.p-r-15 {
  padding-right: 15px !important;
}
.p-t-15 {
  padding-top: 15px !important;
}
.p-b-15 {
  padding-bottom: 15px !important;
}
.p-15 {
  padding: 15px !important;
}
.row-gap-15 {
  row-gap: 15px !important;
}
.m-l-20 {
  margin-left: 20px !important;
}
.m-r-20 {
  margin-right: 20px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.p-l-20 {
  padding-left: 20px !important;
}
.p-r-20 {
  padding-right: 20px !important;
}
.p-t-20 {
  padding-top: 20px !important;
}
.p-b-20 {
  padding-bottom: 20px !important;
}
.p-20 {
  padding: 20px !important;
}
.row-gap-20 {
  row-gap: 20px !important;
}
.m-l-25 {
  margin-left: 25px !important;
}
.m-r-25 {
  margin-right: 25px !important;
}
.m-t-25 {
  margin-top: 25px !important;
}
.m-b-25 {
  margin-bottom: 25px !important;
}
.p-l-25 {
  padding-left: 25px !important;
}
.p-r-25 {
  padding-right: 25px !important;
}
.p-t-25 {
  padding-top: 25px !important;
}
.p-b-25 {
  padding-bottom: 25px !important;
}
.p-25 {
  padding: 25px !important;
}
.row-gap-25 {
  row-gap: 25px !important;
}
.m-l-30 {
  margin-left: 30px !important;
}
.m-r-30 {
  margin-right: 30px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}
.p-l-30 {
  padding-left: 30px !important;
}
.p-r-30 {
  padding-right: 30px !important;
}
.p-t-30 {
  padding-top: 30px !important;
}
.p-b-30 {
  padding-bottom: 30px !important;
}
.p-30 {
  padding: 30px !important;
}
.row-gap-30 {
  row-gap: 30px !important;
}
.m-l-35 {
  margin-left: 35px !important;
}
.m-r-35 {
  margin-right: 35px !important;
}
.m-t-35 {
  margin-top: 35px !important;
}
.m-b-35 {
  margin-bottom: 35px !important;
}
.p-l-35 {
  padding-left: 35px !important;
}
.p-r-35 {
  padding-right: 35px !important;
}
.p-t-35 {
  padding-top: 35px !important;
}
.p-b-35 {
  padding-bottom: 35px !important;
}
.p-35 {
  padding: 35px !important;
}
.row-gap-35 {
  row-gap: 35px !important;
}
.m-l-40 {
  margin-left: 40px !important;
}
.m-r-40 {
  margin-right: 40px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.p-l-40 {
  padding-left: 40px !important;
}
.p-r-40 {
  padding-right: 40px !important;
}
.p-t-40 {
  padding-top: 40px !important;
}
.p-b-40 {
  padding-bottom: 40px !important;
}
.p-40 {
  padding: 40px !important;
}
.row-gap-40 {
  row-gap: 40px !important;
}
.m-l-45 {
  margin-left: 45px !important;
}
.m-r-45 {
  margin-right: 45px !important;
}
.m-t-45 {
  margin-top: 45px !important;
}
.m-b-45 {
  margin-bottom: 45px !important;
}
.p-l-45 {
  padding-left: 45px !important;
}
.p-r-45 {
  padding-right: 45px !important;
}
.p-t-45 {
  padding-top: 45px !important;
}
.p-b-45 {
  padding-bottom: 45px !important;
}
.p-45 {
  padding: 45px !important;
}
.row-gap-45 {
  row-gap: 45px !important;
}
.m-l-50 {
  margin-left: 50px !important;
}
.m-r-50 {
  margin-right: 50px !important;
}
.m-t-50 {
  margin-top: 50px !important;
}
.m-b-50 {
  margin-bottom: 50px !important;
}
.p-l-50 {
  padding-left: 50px !important;
}
.p-r-50 {
  padding-right: 50px !important;
}
.p-t-50 {
  padding-top: 50px !important;
}
.p-b-50 {
  padding-bottom: 50px !important;
}
.p-50 {
  padding: 50px !important;
}
.row-gap-50 {
  row-gap: 50px !important;
}
.m-l-55 {
  margin-left: 55px !important;
}
.m-r-55 {
  margin-right: 55px !important;
}
.m-t-55 {
  margin-top: 55px !important;
}
.m-b-55 {
  margin-bottom: 55px !important;
}
.p-l-55 {
  padding-left: 55px !important;
}
.p-r-55 {
  padding-right: 55px !important;
}
.p-t-55 {
  padding-top: 55px !important;
}
.p-b-55 {
  padding-bottom: 55px !important;
}
.p-55 {
  padding: 55px !important;
}
.row-gap-55 {
  row-gap: 55px !important;
}
.m-l-60 {
  margin-left: 60px !important;
}
.m-r-60 {
  margin-right: 60px !important;
}
.m-t-60 {
  margin-top: 60px !important;
}
.m-b-60 {
  margin-bottom: 60px !important;
}
.p-l-60 {
  padding-left: 60px !important;
}
.p-r-60 {
  padding-right: 60px !important;
}
.p-t-60 {
  padding-top: 60px !important;
}
.p-b-60 {
  padding-bottom: 60px !important;
}
.p-60 {
  padding: 60px !important;
}
.row-gap-60 {
  row-gap: 60px !important;
}
.m-l-65 {
  margin-left: 65px !important;
}
.m-r-65 {
  margin-right: 65px !important;
}
.m-t-65 {
  margin-top: 65px !important;
}
.m-b-65 {
  margin-bottom: 65px !important;
}
.p-l-65 {
  padding-left: 65px !important;
}
.p-r-65 {
  padding-right: 65px !important;
}
.p-t-65 {
  padding-top: 65px !important;
}
.p-b-65 {
  padding-bottom: 65px !important;
}
.p-65 {
  padding: 65px !important;
}
.row-gap-65 {
  row-gap: 65px !important;
}
.m-l-70 {
  margin-left: 70px !important;
}
.m-r-70 {
  margin-right: 70px !important;
}
.m-t-70 {
  margin-top: 70px !important;
}
.m-b-70 {
  margin-bottom: 70px !important;
}
.p-l-70 {
  padding-left: 70px !important;
}
.p-r-70 {
  padding-right: 70px !important;
}
.p-t-70 {
  padding-top: 70px !important;
}
.p-b-70 {
  padding-bottom: 70px !important;
}
.p-70 {
  padding: 70px !important;
}
.row-gap-70 {
  row-gap: 70px !important;
}
.m-l-75 {
  margin-left: 75px !important;
}
.m-r-75 {
  margin-right: 75px !important;
}
.m-t-75 {
  margin-top: 75px !important;
}
.m-b-75 {
  margin-bottom: 75px !important;
}
.p-l-75 {
  padding-left: 75px !important;
}
.p-r-75 {
  padding-right: 75px !important;
}
.p-t-75 {
  padding-top: 75px !important;
}
.p-b-75 {
  padding-bottom: 75px !important;
}
.p-75 {
  padding: 75px !important;
}
.row-gap-75 {
  row-gap: 75px !important;
}
.m-l-80 {
  margin-left: 80px !important;
}
.m-r-80 {
  margin-right: 80px !important;
}
.m-t-80 {
  margin-top: 80px !important;
}
.m-b-80 {
  margin-bottom: 80px !important;
}
.p-l-80 {
  padding-left: 80px !important;
}
.p-r-80 {
  padding-right: 80px !important;
}
.p-t-80 {
  padding-top: 80px !important;
}
.p-b-80 {
  padding-bottom: 80px !important;
}
.p-80 {
  padding: 80px !important;
}
.row-gap-80 {
  row-gap: 80px !important;
}
.m-l-85 {
  margin-left: 85px !important;
}
.m-r-85 {
  margin-right: 85px !important;
}
.m-t-85 {
  margin-top: 85px !important;
}
.m-b-85 {
  margin-bottom: 85px !important;
}
.p-l-85 {
  padding-left: 85px !important;
}
.p-r-85 {
  padding-right: 85px !important;
}
.p-t-85 {
  padding-top: 85px !important;
}
.p-b-85 {
  padding-bottom: 85px !important;
}
.p-85 {
  padding: 85px !important;
}
.row-gap-85 {
  row-gap: 85px !important;
}
.m-l-90 {
  margin-left: 90px !important;
}
.m-r-90 {
  margin-right: 90px !important;
}
.m-t-90 {
  margin-top: 90px !important;
}
.m-b-90 {
  margin-bottom: 90px !important;
}
.p-l-90 {
  padding-left: 90px !important;
}
.p-r-90 {
  padding-right: 90px !important;
}
.p-t-90 {
  padding-top: 90px !important;
}
.p-b-90 {
  padding-bottom: 90px !important;
}
.p-90 {
  padding: 90px !important;
}
.row-gap-90 {
  row-gap: 90px !important;
}
.m-l-95 {
  margin-left: 95px !important;
}
.m-r-95 {
  margin-right: 95px !important;
}
.m-t-95 {
  margin-top: 95px !important;
}
.m-b-95 {
  margin-bottom: 95px !important;
}
.p-l-95 {
  padding-left: 95px !important;
}
.p-r-95 {
  padding-right: 95px !important;
}
.p-t-95 {
  padding-top: 95px !important;
}
.p-b-95 {
  padding-bottom: 95px !important;
}
.p-95 {
  padding: 95px !important;
}
.row-gap-95 {
  row-gap: 95px !important;
}
.m-l-100 {
  margin-left: 100px !important;
}
.m-r-100 {
  margin-right: 100px !important;
}
.m-t-100 {
  margin-top: 100px !important;
}
.m-b-100 {
  margin-bottom: 100px !important;
}
.p-l-100 {
  padding-left: 100px !important;
}
.p-r-100 {
  padding-right: 100px !important;
}
.p-t-100 {
  padding-top: 100px !important;
}
.p-b-100 {
  padding-bottom: 100px !important;
}
.p-100 {
  padding: 100px !important;
}
.row-gap-100 {
  row-gap: 100px !important;
}
.help {
  color: #727380;
  font-size: 14px;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease-out;
}
.aro.button,
.ui.aro.button {
  font-family: 'Inter', sans-serif;
  position: relative;
  border-radius: 6px;
  padding: 10px 15px;
  background-color: #727380;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.15;
  cursor: pointer;
  user-select: none;
  display: inline-block;
  transition: box-shadow 0.2s ease, background-color 0.2s ease, transform 0.1s ease;
}
.aro.button.loading,
.ui.aro.button.loading {
  color: transparent !important;
  cursor: default !important;
}
.aro.button.loading:hover::before,
.ui.aro.button.loading:hover::before {
  display: none;
  opacity: 0;
}
.aro.button.loading::after,
.ui.aro.button.loading::after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0 0 -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  border-radius: 500rem;
  animation: loader 0.6s infinite linear;
  border: 0.2em solid currentColor;
  color: #fff;
  box-shadow: 0 0 0 1px transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.aro.button:hover,
.ui.aro.button:hover,
.aro.button:focus,
.ui.aro.button:focus,
.aro.button:active,
.ui.aro.button:active {
  box-shadow: none;
  border-radius: 6px;
}
.aro.button.fluid,
.ui.aro.button.fluid {
  width: 100%;
  text-align: center;
}
.aro.button.circular,
.ui.aro.button.circular {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  border-radius: 200px;
  height: 44px;
  width: 44px;
  overflow: hidden;
  text-align: center;
  padding: 0px !important;
}
.aro.button.circular.icon > i,
.ui.aro.button.circular.icon > i {
  font-size: 20px;
  margin: 0px;
}
.aro.button.circular.mini,
.ui.aro.button.circular.mini {
  height: 30px;
  width: 30px;
  padding: 0px !important;
  line-height: 1;
}
a.aro.button.circular.mini,
a.ui.aro.button.circular.mini {
  line-height: 1;
}
.aro.button.circular.mini > i,
.ui.aro.button.circular.mini > i {
  font-size: 16px;
}
.aro.button.circular.extra-mini,
.ui.aro.button.circular.extra-mini {
  height: 24px;
  width: 24px;
  padding: 0px !important;
  line-height: 18px;
}
a.aro.button.circular.extra-mini,
a.ui.aro.button.circular.extra-mini {
  line-height: 24px;
}
.aro.button.circular.extra-mini > i,
.ui.aro.button.circular.extra-mini > i {
  font-size: 18px;
}
.aro.button.circular.medium,
.ui.aro.button.circular.medium {
  height: 37px;
  width: 37px;
  padding: 0px !important;
  line-height: 16px;
}
.aro.button.circular.medium > i,
.ui.aro.button.circular.medium > i {
  font-size: 16px;
}
.aro.button.circular.edit,
.ui.aro.button.circular.edit,
.aro.button.circular.delete,
.ui.aro.button.circular.delete,
.aro.button.circular.create,
.ui.aro.button.circular.create,
.aro.button.circular.save-a-copy,
.ui.aro.button.circular.save-a-copy {
  overflow: visible;
}
.aro.button.circular.edit:after,
.ui.aro.button.circular.edit:after,
.aro.button.circular.delete:after,
.ui.aro.button.circular.delete:after,
.aro.button.circular.create:after,
.ui.aro.button.circular.create:after,
.aro.button.circular.save-a-copy:after,
.ui.aro.button.circular.save-a-copy:after {
  position: absolute;
  right: 50px;
  color: #39393F;
  font-size: 14px;
  text-align: right;
  min-width: 150px;
}
.aro.button.circular.edit:after,
.ui.aro.button.circular.edit:after {
  content: 'Edit';
}
.aro.button.circular.create:after,
.ui.aro.button.circular.create:after {
  content: 'Create';
}
.aro.button.circular.save-a-copy:after,
.ui.aro.button.circular.save-a-copy:after {
  content: 'Save a copy';
}
.aro.button.circular.delete:after,
.ui.aro.button.circular.delete:after {
  content: 'Delete';
}
.aro.button:hover::before,
.ui.aro.button:hover::before {
  opacity: 1;
}
.aro.button::before,
.ui.aro.button::before {
  /* Position the pseudo-element. */
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
  /* Create the box shadow at expanded size. */
  box-shadow: 0px 2px 10px rgba(60, 61, 73, 0.1);
  /* Hidden by default. */
  opacity: 0;
  transition: opacity 0.2s ease;
}
.aro.button:active,
.ui.aro.button:active {
  transform: scale(0.96);
  transition: background-color 0s;
}
.aro.button.icon > i,
.ui.aro.button.icon > i {
  margin-right: 10px;
}
.aro.button.icon.big-icon > i,
.ui.aro.button.icon.big-icon > i {
  font-size: 24px;
}
.aro.button.icon > span,
.ui.aro.button.icon > span {
  white-space: nowrap;
}
.aro.button.icon.mobile .button-mixin > i,
.ui.aro.button.icon.mobile .button-mixin > i {
  margin-right: 0px;
}
.aro.button.icon.mobile .button-mixin > span,
.ui.aro.button.icon.mobile .button-mixin > span {
  display: none;
}
.aro.button.icon.mobile.force-mobile > i,
.ui.aro.button.icon.mobile.force-mobile > i {
  margin-right: 0px;
}
.aro.button.icon.mobile.force-mobile > span,
.ui.aro.button.icon.mobile.force-mobile > span {
  display: none;
}
@media screen and (max-width: 480px) {
  .aro.button.icon.mobile > i,
  .ui.aro.button.icon.mobile > i {
    margin-right: 0px;
  }
  .aro.button.icon.mobile > span,
  .ui.aro.button.icon.mobile > span {
    display: none;
  }
}
.aro.button.medium,
.ui.aro.button.medium {
  padding: 13px 15px !important;
}
.aro.button.mini,
.ui.aro.button.mini {
  padding: 8px !important;
}
.aro.button.compact,
.ui.aro.button.compact {
  height: 39px;
  padding: 10px 15px;
}
.aro.button.small,
.ui.aro.button.small {
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
}
.aro.button.primary,
.ui.aro.button.primary {
  color: #fff;
  background-color: #39449C;
}
.aro.button.primary:hover,
.ui.aro.button.primary:hover {
  background-color: #4b58bd;
}
.aro.button.primary:active,
.ui.aro.button.primary:active {
  background-color: #39449C;
}
.aro.button.secondary,
.ui.aro.button.secondary {
  color: #fff;
  background-color: #00A3E0;
}
.aro.button.secondary:hover,
.ui.aro.button.secondary:hover {
  color: #fff;
  background-color: #14bfff;
}
.aro.button.secondary:active,
.ui.aro.button.secondary:active {
  background-color: #00A3E0;
}
.aro.button.tertiary,
.ui.aro.button.tertiary,
.aro.button.success,
.ui.aro.button.success {
  background-color: #A8EADE;
  color: #006B57;
}
.aro.button.tertiary:hover,
.ui.aro.button.tertiary:hover,
.aro.button.success:hover,
.ui.aro.button.success:hover {
  background-color: #A8EADE;
  color: #006B57;
}
.aro.button.tertiary:active,
.ui.aro.button.tertiary:active,
.aro.button.success:active,
.ui.aro.button.success:active {
  background-color: #A8EADE;
}
.aro.button.tertiary-outline,
.ui.aro.button.tertiary-outline {
  background-color: transparent;
  color: #006B57;
  outline: 1px solid #006B57;
}
.aro.button.tertiary-outline:hover,
.ui.aro.button.tertiary-outline:hover {
  background-color: #A8EADE;
  color: #006B57;
  outline: 1px solid #A8EADE;
}
.aro.button.tertiary-outline:active,
.ui.aro.button.tertiary-outline:active,
.aro.button.tertiary-outline.loading,
.ui.aro.button.tertiary-outline.loading {
  background-color: #A8EADE;
}
.aro.button.transparent,
.ui.aro.button.transparent {
  padding-left: 5px;
  padding-right: 5px;
  background-color: transparent;
}
.aro.button.error,
.ui.aro.button.error {
  background-color: #FFC6C1;
  color: #B01B0C;
}
.aro.button.error:hover,
.ui.aro.button.error:hover {
  background-color: #FFC6C1;
  color: #B01B0C;
}
.aro.button.error:active,
.ui.aro.button.error:active {
  background-color: #FFC6C1;
}
.aro.button.tertiary-light,
.ui.aro.button.tertiary-light {
  background-color: #A8EADE;
  color: #006B57;
}
.aro.button.tertiary-light:active,
.ui.aro.button.tertiary-light:active {
  background-color: #A8EADE;
}
.aro.button.default,
.ui.aro.button.default {
  background-color: #65666B;
  color: #fff;
}
.aro.button.default:hover,
.ui.aro.button.default:hover {
  background-color: #7e7f85;
}
.aro.button.default:active,
.ui.aro.button.default:active {
  background-color: #65666B;
}
.aro.button.default-outline,
.ui.aro.button.default-outline {
  background-color: #fff;
  border: 1px solid #EAEBEF;
}
.aro.button.error-outline,
.ui.aro.button.error-outline {
  background-color: transparent;
  border: 1px solid #FF331F;
  color: #FF331F;
}
.aro.button.primary-outline,
.ui.aro.button.primary-outline {
  background-color: transparent;
  border: 1px solid #232229;
  color: #232229;
}
.aro.button.primary-outline:hover,
.ui.aro.button.primary-outline:hover {
  background-color: #232229;
  color: #fff;
}
.aro.button.highlight-secondary:hover,
.ui.aro.button.highlight-secondary:hover {
  color: #00A3E0;
}
.aro.button.highlight-error:hover,
.ui.aro.button.highlight-error:hover {
  color: #FF331F;
}
.aro.button.default-light,
.ui.aro.button.default-light {
  background-color: #EAEBEF;
  color: rgba(0, 0, 0, 0.7);
}
.aro.button.default-light:active,
.ui.aro.button.default-light:active {
  background-color: #EAEBEF;
}
.aro.button.secondary-light,
.ui.aro.button.secondary-light {
  background-color: #E6F6FC;
  color: #00A3E0;
}
.aro.button.secondary-light:active,
.ui.aro.button.secondary-light:active {
  background-color: #E6F6FC;
}
.aro.button.primary-light,
.ui.aro.button.primary-light {
  background-color: #E2E3F0;
  color: #001871;
}
.aro.button.primary-light:active,
.ui.aro.button.primary-light:active {
  background-color: #E2E3F0;
}
.aro.button.primary-dark,
.ui.aro.button.primary-dark {
  border: 1px solid #232229;
  background-color: #232229;
  color: #fff;
}
.aro.button.primary-dark:active,
.ui.aro.button.primary-dark:active {
  background-color: #232229;
}
.aro.button.primary-dark:hover,
.ui.aro.button.primary-dark:hover {
  background-color: #3b3945;
}
.aro.button.tertiary-white,
.ui.aro.button.tertiary-white {
  color: #fff;
  background-color: #00C19F;
}
.aro.button.tertiary-white:hover,
.ui.aro.button.tertiary-white:hover {
  background-color: #00a88a;
}
.aro.button.secondary-white,
.ui.aro.button.secondary-white {
  color: #fff;
  background-color: #00A3E0;
}
.aro.button.secondary-white:hover,
.ui.aro.button.secondary-white:hover {
  background-color: #0090c7;
}
.aro.button.primary-white,
.ui.aro.button.primary-white {
  color: #fff;
  background-color: #39449C;
}
.aro.button.primary-white:hover,
.ui.aro.button.primary-white:hover {
  background-color: #323c89;
}
.aro.button.discrete,
.ui.aro.button.discrete {
  background-color: transparent;
  color: #232229;
}
.aro.button.discrete.default-light,
.ui.aro.button.discrete.default-light {
  color: #727380;
}
.aro.button.discrete.default-light:hover,
.ui.aro.button.discrete.default-light:hover {
  color: #232229;
}
.aro.button.discrete.secondary,
.ui.aro.button.discrete.secondary {
  color: #00A3E0;
}
.aro.button.discrete.secondary:hover,
.ui.aro.button.discrete.secondary:hover {
  color: #fff;
}
.aro.button.discrete:hover,
.ui.aro.button.discrete:hover {
  color: rgba(0, 0, 0, 0.7);
}
.aro.button.discrete.tiny,
.ui.aro.button.discrete.tiny {
  font-size: 13px;
}
.aro.button.discrete.slim,
.ui.aro.button.discrete.slim {
  padding-left: 0px;
  padding-right: 0px;
}
.aro.button.discrete:hover::before,
.ui.aro.button.discrete:hover::before {
  opacity: 0;
}
.aro.button.lowkey,
.ui.aro.button.lowkey {
  background-color: transparent;
  color: #232229;
  display: flex;
  align-items: center;
}
.aro.button.lowkey:hover::before,
.ui.aro.button.lowkey:hover::before {
  opacity: 0;
}
.aro.button.lowkey.slim,
.ui.aro.button.lowkey.slim {
  padding-left: 0px;
  padding-right: 0px;
}
.aro.button.lowkey.secondary,
.ui.aro.button.lowkey.secondary {
  color: #00A3E0;
}
.aro.button.lowkey.secondary:hover,
.ui.aro.button.lowkey.secondary:hover {
  background-color: transparent;
}
.aro.button.secondary-plain,
.ui.aro.button.secondary-plain {
  color: #00A3E0;
  background-color: #fff;
}
.aro.button.error-plain,
.ui.aro.button.error-plain {
  color: #FF331F;
  background-color: #fff;
}
.aro.button.shadowed,
.ui.aro.button.shadowed {
  box-shadow: 0px 11px 18.5023px rgba(0, 0, 0, 0.09);
}
.aro.button-group {
  position: relative;
  overflow: hidden;
}
.aro.button-group .button-menu {
  position: absolute;
  right: 0px;
  background-color: #fff;
  border-radius: 16px;
  z-index: 50;
  padding: 6px 20px;
  display: flex;
  flex-direction: column;
  opacity: 0;
  top: 90px;
  transition: opacity 0.2s ease, top 0.2s ease-out;
}
.aro.button-group .button-menu .button-select {
  cursor: pointer;
  white-space: nowrap;
  padding: 10px 0px;
  border-bottom: 1px solid #EAEBEF;
  color: #39393F;
}
.aro.button-group .button-menu .button-select:hover {
  color: #00A3E0;
}
.aro.button-group .button-menu .button-select > i {
  margin-right: 12px;
  color: #00A3E0;
}
.aro.button-group .button-menu .button-select:last-child {
  border-bottom: none;
}
.aro.button-group.active {
  overflow: visible;
}
.aro.button-group.active .button-menu {
  box-shadow: 0px 4px 50px rgba(60, 61, 73, 0.08);
  opacity: 1;
  top: 60px;
}
.aro.divider {
  display: block;
  height: 2px;
  background-color: #EAEBEF;
}
.aro.heading {
  font-weight: 600;
}
.aro.heading.medium {
  font-size: 1.5rem;
}
.aro.label {
  padding: 2px 7px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
  background-color: #EAEBEF;
  display: inline-block;
  border-radius: 6px;
  white-space: nowrap;
}
.aro.label.small {
  font-size: 12px;
  padding: 0px 5px;
  border-radius: 5px;
}
.aro.label.success {
  background-color: #A8EADE;
  color: #006B57;
}
.aro.label.warning {
  background-color: #FEEBD7;
  color: #B35C05;
}
.aro.label.error {
  background-color: #FFC6C1;
  color: #B01B0C;
}
.aro.label.default {
  background-color: #EAEBEF;
  color: #39393F;
}
.aro.label.secondary {
  background-color: #00A3E0;
  color: #fff;
}
.aro.label.black {
  background-color: #232229;
  color: #fff;
}
.aro.label.simple {
  display: flex;
  align-items: center;
  column-gap: 5px;
  background-color: transparent;
  padding: 0px;
  font-weight: 600;
  line-height: 1;
}
.aro.label.simple.success {
  color: #00C19F;
}
.aro.label.simple.success-dark {
  color: #006B57;
}
.aro.label.simple.primary {
  color: #39449C;
}
.aro.label.pastel.green {
  background-color: #deece1;
}
.aro.label.pastel.red {
  background-color: #ff9899;
}
.aro.label.pastel.yellow {
  background-color: #fcf4dd;
}
.aro.label-group.group {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}
.aro.label-group .checkbox-label {
  display: flex;
  align-items: center;
  color: #727380;
}
.aro.label-group .checkbox-label:before {
  content: '';
  height: 10px;
  width: 10px;
  margin-left: 3px;
  border-radius: 2px;
  background-color: #EAEBEF;
  margin-right: 16px;
}
.aro.label-group .checkbox-label.selected {
  font-weight: 500;
  color: #232229;
}
.aro.label-group .checkbox-label.selected:before {
  content: '\e9c3';
  font-family: unicons-line;
  font-style: normal;
  font-weight: 400;
  color: #00C19F;
  padding: 0px;
  height: auto;
  width: auto;
  border-radius: 0px;
  background-color: transparent;
  margin-left: 0px;
  margin-right: 13px;
}
.aro.label-group .radio-label {
  display: flex;
  align-items: center;
  color: #727380;
}
.aro.label-group .radio-label:before {
  content: '';
  height: 10px;
  width: 10px;
  margin-left: 3px;
  border-radius: 25px;
  background-color: #EAEBEF;
  margin-right: 16px;
}
.aro.label-group .radio-label.selected {
  font-weight: 500;
  color: #232229;
}
.aro.label-group .radio-label.selected:before {
  content: '';
  padding: 0px;
  height: 10px;
  width: 10px;
  border: 3px solid #EAEBEF;
  background-color: #39449C;
}
.aro.dot.small {
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border-radius: 100%;
}
.aro.dot.success {
  background-color: #00C19F;
  color: #fff;
}
.aro.dot.error {
  background-color: #FFC6C1;
  color: #B01B0C;
}
.aro.dot.warning {
  background-color: #B35C05;
}
.aro.dot.primary {
  background-color: #39449C;
  color: #fff;
}
.aro.dropdown,
.aro.selection.dropdown {
  border: 1px solid #DEDFE7;
  border-radius: 6px;
  display: inline-block;
  line-height: 1em;
}
.aro.dropdown:focus,
.aro.selection.dropdown:focus,
.aro.dropdown.active,
.aro.selection.dropdown.active,
.aro.dropdown.active:hover,
.aro.selection.dropdown.active:hover {
  border: 1px solid #39449C;
  background-color: #f3f4fb;
  box-shadow: 0px 1px 4px rgba(2, 9, 34, 0.12);
}
.aro.dropdown:focus .menu,
.aro.selection.dropdown:focus .menu,
.aro.dropdown.active .menu,
.aro.selection.dropdown.active .menu,
.aro.dropdown.active:hover .menu,
.aro.selection.dropdown.active:hover .menu,
.aro.dropdown:focus .menu:hover,
.aro.selection.dropdown:focus .menu:hover,
.aro.dropdown.active .menu:hover,
.aro.selection.dropdown.active .menu:hover,
.aro.dropdown.active:hover .menu:hover,
.aro.selection.dropdown.active:hover .menu:hover {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid #39449C;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.aro.dropdown.select,
.aro.selection.dropdown.select {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
}
.aro.dropdown > .dropdown.icon,
.aro.selection.dropdown > .dropdown.icon {
  font-family: unicons-line;
  color: #39393F;
  font-size: 20px;
  padding: 6px;
}
.aro.dropdown > .dropdown.icon::before,
.aro.selection.dropdown > .dropdown.icon::before {
  content: '\eb3a';
}
.aro.dropdown.dropdown > .default.text,
.aro.selection.dropdown.dropdown > .default.text {
  color: #727380;
}
.aro.dropdown.ui.selection.dropdown > .remove.icon,
.aro.selection.dropdown.ui.selection.dropdown > .remove.icon {
  right: 3.3em;
  top: 10px;
}
.aro.dropdown.compact,
.aro.selection.dropdown.compact {
  min-height: 0px;
  height: 39px;
  font-size: 16px;
  padding: 10px 45px 10px 15px;
}
.aro.dropdown > i,
.aro.selection.dropdown > i {
  position: absolute;
  right: 15px;
}
.aro.search.container {
  display: inline-block;
  font-size: 16px;
  position: relative;
  line-height: 0px;
}
.aro.search.container > input {
  font-family: 'Inter', sans-serif;
  border: none;
}
.aro.search.container > input::placeholder {
  font-size: 16px;
}
.aro.search.container > button {
  background-color: transparent;
  border: none;
  height: 100%;
  cursor: pointer;
  position: absolute;
  right: 5px;
  color: #65666B;
}
.aro.search.container.light {
  border: 1px solid #727380;
}
.aro.search.container.compact {
  height: 39px;
}
.aro.search.container.compact > input {
  padding: 9px 15px;
}
.aro.form .field-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  grid-template-rows: 1fr;
  gap: 20px;
  padding: 10px 0px;
}
.aro.form .field.required > label:after,
.aro.form .grouped.fields.required > label:after {
  content: ' *';
  color: #FF331F;
}
.aro.form > .field,
.aro.form > .grouped.fields {
  padding: 10px 0px;
}
.aro.form > .field:first-child,
.aro.form > .grouped.fields:first-child {
  padding-top: 0px;
}
.aro.form > .field:last-child,
.aro.form > .grouped.fields:last-child {
  padding-bottom: 0px;
}
.aro.form > .field.error input,
.aro.form > .grouped.fields.error input,
.aro.form > .field.error > .aro.input,
.aro.form > .grouped.fields.error > .aro.input {
  border: 1px solid #B01B0C;
  background-color: #FFF7F7;
}
.aro.form > .field.error .help-error,
.aro.form > .grouped.fields.error .help-error {
  font-size: 14px;
  color: #B01B0C;
}
.aro.form > .field.disabled input,
.aro.form > .grouped.fields.disabled input,
.aro.form > .field.disabled > .aro.input,
.aro.form > .grouped.fields.disabled > .aro.input {
  background-color: #f3f3f6;
  color: #727380;
  cursor: not-allowed;
}
.aro.form > .grouped.fields .field {
  padding: 4px 0px;
}
.aro.form > .grouped.fields .field:first-child {
  padding-top: 0px;
}
.aro.form > .grouped.fields .field:last-child {
  padding-bottom: 0px;
}
.aro.form .field > label,
.aro.form .grouped.fields > label {
  font-size: 14px;
  font-weight: 500;
  color: #39393F;
  display: block;
  margin-bottom: 6px;
}
.aro.form .field > .aro.input,
.aro.form .field > .aro.search.input,
.aro.form .aro.input,
.aro.form .aro.search.input,
.aro.form .aro.textarea {
  font-family: 'Inter', sans-serif;
  border: 1px solid #DEDFE7;
  border-radius: 6px !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  padding: 10px 14px;
  background: #fff;
  font-size: 14px;
}
.aro.form .field > .aro.input.fluid,
.aro.form .field > .aro.search.input.fluid,
.aro.form .aro.input.fluid,
.aro.form .aro.search.input.fluid,
.aro.form .aro.textarea.fluid {
  width: 100%;
  max-width: 100%;
}
.aro.form .field > .aro.input:focus,
.aro.form .field > .aro.search.input:focus,
.aro.form .aro.input:focus,
.aro.form .aro.search.input:focus,
.aro.form .aro.textarea:focus {
  border: 1px solid #39449C;
  background-color: #f3f4fb;
  box-shadow: 0px 1px 4px rgba(2, 9, 34, 0.12);
}
.aro.form .field > .aro.input:focus,
.aro.form .field > .aro.search.input:focus,
.aro.form .aro.input:focus,
.aro.form .aro.search.input:focus,
.aro.form .aro.textarea:focus {
  background-color: #f3f4fb;
}
.aro.form .field > .aro.input:focus-visible,
.aro.form .field > .aro.search.input:focus-visible,
.aro.form .aro.input:focus-visible,
.aro.form .aro.search.input:focus-visible,
.aro.form .aro.textarea:focus-visible {
  outline: none;
}
.aro.form .field > .aro.input::placeholder,
.aro.form .field > .aro.search.input::placeholder,
.aro.form .aro.input::placeholder,
.aro.form .aro.search.input::placeholder,
.aro.form .aro.textarea::placeholder {
  color: #A7A9B2;
  opacity: 1;
}
.aro.form .field > .aro.input.error,
.aro.form .field > .aro.search.input.error,
.aro.form .aro.input.error,
.aro.form .aro.search.input.error,
.aro.form .aro.textarea.error {
  border: 1px solid #B01B0C;
  background-color: #FFF7F7;
}
.aro.form .aro.textarea {
  resize: vertical;
}
.aro.form .aro.checkbox label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #39393F;
}
.aro.modal {
  border-radius: 16px;
}
.aro.modal > .header {
  position: relative;
  padding: 30px 20px 20px;
  border-bottom: none;
  background-color: transparent;
  font-family: 'Inter', sans-serif;
}
.aro.modal > .header > h4 {
  margin-top: 0px;
  color: rgba(0, 0, 0, 0.7);
}
.aro.modal > .header > i {
  position: absolute;
  right: 20px;
  font-size: 22px;
  cursor: pointer;
  transition: color 0.2s ease;
}
.aro.modal > .header > i:hover {
  color: #727380;
}
.aro.modal > .actions {
  background-color: transparent;
  border-top: 1px solid #EAEBEF;
}
.aro.modal > .actions > button.aro.button {
  margin-left: 10px;
}
.aro.modal > .content.no-padding {
  padding: 0px;
}
.aro.modal.overlay.fullscreen {
  bottom: 0px;
}
.aro.popup.invis {
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0px;
}
.aro.popup.invis:before {
  display: none;
}
.aro.details-group > * {
  margin: 20px 0px;
}
.aro.details-group > *:first-child {
  margin-top: 0px;
}
.aro.details-group > *:last-child {
  margin-bottom: 0px;
}
.aro.detail > .title {
  font-size: 14px;
  font-weight: 500;
  color: #65666B;
}
.aro.detail > .title.strict {
  flex: 0 0 auto;
}
.aro.detail > .description {
  font-weight: 500;
  color: #232229;
}
.aro.detail > .description.word-break {
  word-break: break-word;
}
.aro.detail > .description.error {
  color: #FF331F;
}
.aro.detail > h4 {
  margin: 0px;
}
.aro.detail.opaque > .title {
  color: rgba(255, 255, 255, 0.9);
}
.aro.detail.opaque > .description {
  color: #fff;
}
.aro.detail.centered {
  text-align: center;
}
.aro.detail > a.description:hover {
  text-decoration: underline;
}
.aro.detail.inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
}
.aro.detail.inline > .description {
  font-weight: 600;
}
.aro.detail.highlighted > .description {
  color: #006B57;
  font-size: 1.25em;
  font-weight: 400;
}
.aro.detail > .lightbox-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 6px;
}
.aro.detail > .lightbox-gallery > a {
  display: block;
  width: 90px;
  height: 90px;
  border-radius: 6px;
  overflow: hidden;
}
.aro.detail > .lightbox-gallery > a:hover {
  outline: 2px solid #e0e2f4;
  border: 1px solid #39449C;
}
.aro.detail > .lightbox-gallery > a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.aro.link.h-underline:hover {
  text-decoration: underline;
}
.aro.card {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(60, 61, 73, 0.08);
  border-radius: 16px;
  position: relative;
  overflow: hidden;
}
.aro.card.sm {
  max-width: 375px;
}
.aro.card.md {
  max-width: 600px;
}
.aro.card.fluid {
  width: 100%;
}
.aro.card.create-card {
  max-width: 600px;
  margin: 0px auto;
}
.aro.card > .close {
  cursor: pointer;
  padding: 8px;
  position: absolute;
  top: 8px;
  right: 8px;
  transition: color 0.2s ease;
}
.aro.card > .close.light {
  color: #fff;
}
.aro.card > .close.light:hover {
  color: #e6e6e6;
}
.aro.card.flex {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.aro.card.inner {
  border-radius: 0px;
  box-shadow: none;
}
.aro.card.invis {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  overflow: visible;
  position: static;
}
.aro.card.overflow {
  overflow: visible;
}
.aro.card.loading::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  border-radius: inherit;
}
.aro.card > .header-image {
  height: 345px;
  width: 100%;
  background-size: cover;
  background-position: center center;
}
.aro.card > .header-images {
  max-height: 375px;
}
.aro.card > .header-images .swiper-slide {
  text-align: center;
}
.aro.card > .header-images .slide-image {
  height: 375px;
  width: auto;
  overflow: hidden;
  display: flex;
  align-content: center;
  justify-content: center;
}
.aro.card > .header-images > .swiper > .swiper-pagination {
  bottom: 30px;
}
.aro.card > .header-images > .swiper > .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #39449C;
}
.aro.card > .header {
  padding: 30px 20px;
}
.aro.card > .header.primary {
  background-color: #39449C;
  color: #fff;
}
.aro.card > .header.top.rounded {
  border-radius: 16px 16px 0px 0px;
  margin-top: -16px;
  z-index: 1;
  position: relative;
}
.aro.card > .header.basic {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
}
.aro.card > .header.basic > .title {
  color: #232229;
}
.aro.card > .header.basic > .description {
  margin-top: 10px;
  font-size: 0.875em;
  font-weight: 400;
  color: #65666B;
}
.aro.card > .header.small {
  color: #232229;
  font-size: 0.875rem;
  font-weight: 500;
}
.aro.card > .header.centered {
  text-align: center;
}
.aro.card > .header.navigation {
  position: relative;
}
.aro.card > .header.navigation .back {
  position: absolute;
  top: 22px;
}
.aro.card > .content {
  padding: 20px;
  position: relative;
}
.aro.card > .content.no-padding {
  padding: 0px;
}
.aro.card > .content.centered {
  text-align: center;
}
.aro.card > .content.right-aligned {
  text-align: right;
}
.aro.card > .content .no-items {
  padding: 20px;
  text-align: center;
}
.aro.card > .content .no-items i {
  font-size: 24px;
  color: #727380;
  margin-bottom: 8px;
}
.aro.card > .content .no-items p {
  font-size: 14px;
  font-weight: 500;
  color: #727380;
}
.aro.card > .content.narrow-grids .ui.grid > .wide.column {
  padding-top: 10px;
  padding-bottom: 10px;
}
.aro.card > .content.bordered-bottom {
  border-bottom: 1px solid #EAEBEF;
}
.aro.card > .content.bordered-top {
  border-top: 1px solid #EAEBEF;
}
.aro.card > .footer {
  padding: 20px 20px 30px;
}
.aro.card > .footer.right-aligned {
  text-align: right;
}
.aro.card > .footer.top-bordered {
  border-top: 1px solid #EAEBEF;
}
.aro.card > .footer .subheading,
.aro.card > .content .subheading {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 20px;
}
.aro.card > .delete-block {
  display: flex;
  justify-content: center;
  padding: 30px 0px;
  margin-top: auto;
}
.aro.card > .delete-block .confirm-block {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.aro.card > .button-group-block {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.aro.card.error {
  background-color: #FFC6C1;
  color: #B01B0C;
}
.aro.card.error > .header.basic {
  color: #B01B0C;
}
.aro.card.outlined {
  box-shadow: none;
  border: 2px solid #DEDFE7;
}
.aro.card.warning {
  border: 1px solid #B35C05;
}
.aro.sidebar {
  background-color: #fff;
  max-width: 430px;
  width: 100% !important;
  z-index: 102 !important;
}
.aro.sidebar > .close {
  position: absolute;
  padding: 20px;
  top: 0px;
  right: 0px;
  cursor: pointer;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.7);
}
.aro.sidebar > .close:hover {
  color: #727380;
}
.aro.sidebar > .header {
  padding: 80px 20px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.aro.sidebar .content {
  padding: 20px;
}
.aro.sidebar .basic-error {
  color: #B01B0C;
}
.aro.listing {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  transition: background-color 0.2s ease;
}
.aro.listing.clickable {
  cursor: pointer;
}
.aro.listing.clickable:hover {
  background-color: #EAEBEF;
}
.aro.listing.compact {
  padding-top: 18px;
  padding-bottom: 18px;
}
.aro.listing > .content {
  flex: 1 1 auto;
  padding: 0px 16px;
  color: #232229;
}
.aro.listing > .content .title {
  color: #232229;
  font-weight: 500;
}
.aro.listing > .content .description {
  font-size: 0.875rem;
  color: #65666B;
}
.aro.listing.icon > .icon {
  flex-basis: 24px;
  font-size: 20px;
  color: #00A3E0;
}
.aro.listing.icon > .icon.aro-primary {
  color: #39449C;
}
.aro.listing.image {
  padding-top: 10px;
  padding-bottom: 10px;
}
.aro.listing.image > .image {
  flex: 0 0 30px;
}
.aro.listing.image > .image img {
  max-width: 100%;
  display: block;
}
.aro.listing.arrowed:after {
  display: block !important;
}
.aro.listing.labeled {
  column-gap: 20px;
}
.aro.listing.labeled:after {
  display: none;
}
.aro.listing.labeled .content {
  padding: 0px;
}
.aro.listing.labeled .title {
  color: #232229;
  font-weight: 500;
}
.aro.listing.labeled .description {
  font-size: 0.875rem;
  color: #65666B;
}
.aro.listing.highlighted > .content {
  color: #00A3E0;
}
.aro.listing.super-basic:after {
  display: none;
}
.aro.listing.super-basic .title {
  color: #232229;
}
.aro.listing.super-basic .content {
  padding: 0px;
}
.aro.listing.tabled .content .title {
  font-size: 0.875rem;
  color: #65666B;
}
.aro.listing.extra-plain {
  padding: 15px 0px;
  cursor: pointer;
}
.aro.listing.extra-plain:after {
  display: none;
}
.aro.listing.extra-plain:before {
  left: 0px;
}
.aro.listing.extra-plain .title {
  color: rgba(0, 0, 0, 0.7);
}
.aro.listing.extra-plain:hover .title {
  color: #232229;
}
.aro.listing.basic:after {
  display: none;
}
.aro.listing.basic .content {
  padding: 0px;
}
.aro.listing.basic .title {
  color: #232229;
}
.aro.listing.basic .description {
  font-size: 0.875rem;
  color: #65666B;
}
.aro.listing.plain {
  padding: 15px 20px;
}
.aro.listing.plain:after {
  display: none;
}
.aro.listing.plain > .content {
  padding: 0px;
}
.aro.listing.plain > .content .title {
  line-height: 1.5;
}
.aro.listing.plain > .content .description {
  line-height: 1.5;
}
.aro.listing.nested {
  padding-left: 0px;
  padding-right: 0px;
}
.aro.listing::before {
  content: '';
  height: 1px;
  background-color: #EAEBEF;
  position: absolute;
  bottom: 0px;
  display: block;
  left: 20px;
  right: 20px;
}
.aro.listing:last-child:before,
.aro.listing.last:before {
  display: none;
}
.aro.listing::after {
  content: '\eb9f';
  font-family: unicons-line;
  font-style: normal;
  font-weight: 400;
  display: inline-block;
  text-decoration: inherit;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #232229;
}
.aro.listing .options-block {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 24, 113, 0.8);
  top: 0px;
  right: 0px;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.5s ease;
}
.aro.listing .options-block .option {
  background-color: #fff;
  border-radius: 100px;
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.7);
  font-size: 19px;
  line-height: 1;
  opacity: 0;
  transition-duration: 0.2s, 0.3s, 0.4s;
  transition-property: background-color, transform, opacity;
  transition-timing-function: ease, ease-out, ease;
  transition-delay: 0s, 1s, 0s;
  transform: translateY(100px);
}
.aro.listing .options-block .option:hover {
  background-color: #EAEBEF;
}
.aro.listing .options-block .option:nth-child(1) {
  transition-delay: 0s, 0s, 0s;
}
.aro.listing .options-block .option:nth-child(2) {
  transition-delay: 0s, 0.1s, 0s;
}
.aro.listing .options-block .option:nth-child(3) {
  transition-delay: 0s, 0.15s, 0s;
}
.aro.listing .options-block .option:nth-child(4) {
  transition-delay: 0s, 0.2s, 0s;
}
.aro.listing .options-block .option:nth-child(5) {
  transition-delay: 0s, 0.25s, 0s;
}
.aro.listing .options-block .option:nth-child(6) {
  transition-delay: 0s, 0.3s, 0s;
}
.aro.listing .options-block .option:nth-child(7) {
  transition-delay: 0s, 0.35s, 0s;
}
.aro.listing.options-visible .options-block {
  display: flex;
}
.aro.listing.options-active .options-block {
  opacity: 1;
}
.aro.listing.options-active .options-block .option {
  opacity: 1;
  transform: translateY(0px);
}
.aro.ui.calendar .ui.calendar.active table tbody > tr > td.link.focus {
  box-shadow: inset 0 0 0 1px #39449C;
}
.aro.message.wrapper.top {
  position: absolute;
  top: 70px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  padding: 0px;
  z-index: 20;
}
.aro.message {
  padding: 10px 50px 10px 25px;
  border-radius: 10px;
  display: inline-block;
  position: relative;
}
.aro.message.fluid {
  display: block;
  width: 100%;
}
.aro.message.error {
  background-color: #FFC6C1;
  color: #B01B0C;
}
.aro.message.error-light {
  background-color: #fff5f4;
  color: #B01B0C;
  border: 1px solid #B01B0C;
}
.aro.message.warning-light {
  background-color: #ffffff;
  color: #B35C05;
  border: 1px solid #B35C05;
}
.aro.message.success {
  background-color: #A8EADE;
  color: #006B57;
}
.aro.message.top {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.aro.message.centered {
  margin: 0 auto;
}
.aro.message > i {
  position: absolute;
  padding: 10px;
  cursor: pointer;
  right: 5px;
  top: 0px;
}
.aro.fileuploader > label,
.aro.form .aro.fileuploader > label {
  padding: 0px;
  border: 2px dashed #DEDFE7;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 20px 0px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.7);
  margin: 0px 0px 10px 0px;
}
.aro.fileuploader > label:hover,
.aro.form .aro.fileuploader > label:hover {
  border-color: #c0c2d2;
}
.aro.fileuploader > label > i,
.aro.form .aro.fileuploader > label > i {
  font-size: 20px;
  margin-right: 8px;
}
.aro.fileuploader > label.is-dragover,
.aro.form .aro.fileuploader > label.is-dragover {
  border-color: #00A3E0;
}
.aro.fileuploader > .file-list,
.aro.form .aro.fileuploader > .file-list {
  margin: 0px;
  padding: 0px;
}
.aro.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 44px 12px 10px;
  border: 1px solid #EAEBEF;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  margin: 8px 0px;
  position: relative;
  overflow: hidden;
}
.aro.item:hover {
  border: 1px solid #cdcfd9;
}
.aro.item.draggable {
  cursor: grab;
  background-color: #fff;
}
.aro.item.draggable.dragging {
  cursor: grabbing;
}
.aro.item.draggable > .grabber {
  margin-right: 10px;
}
.aro.item.draggable > .title {
  margin-right: auto;
}
.aro.item > .title {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.aro.item > .action {
  font-size: 18px;
  position: absolute;
  cursor: pointer;
  padding: 12px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}
.aro.item > .action.error {
  color: #FF331F;
  transition: color 0.2s ease;
}
.aro.item > .action.error:hover {
  color: #eb1500;
}
.aro.table {
  border-radius: 0px;
  margin-top: 0px;
  transform-style: preserve-3d;
  min-width: 100%;
}
.aro.table.small {
  font-size: 15px;
}
.aro.table > thead {
  will-change: transform;
}
.aro.table > thead .sortable {
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.aro.table > thead .sortable:hover {
  background-color: #eceef6;
}
.aro.table > thead > tr > th {
  position: relative;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 600;
  background-color: #F3F4F9;
  padding-left: 20px !important;
  padding-right: 20px !important;
  user-select: none;
}
.aro.table > thead > tr > th > i {
  color: #A7A9B2;
  text-align: right;
  position: absolute;
  right: 8px;
  top: 15px;
}
.aro.table > thead > tr > th.sort-active > i {
  color: rgba(0, 0, 0, 0.7);
}
.aro.table > tbody > tr > td {
  position: relative;
  color: #232229;
  overflow-y: visible;
  padding-left: 20px !important;
  padding-right: 20px !important;
  cursor: auto;
}
.aro.table > tbody > tr > td a {
  color: #005a7a;
  font-weight: 600;
  display: block;
}
.aro.table > tbody > tr > td a:hover {
  text-decoration: underline;
}
.aro.table > tbody > tr {
  transform-style: preserve-3d;
}
.aro.table > tbody > tr:hover td:not(.hidden):first-child .row-options,
.aro.table > tbody > tr:hover td:not(.hidden) .row-options {
  opacity: 100;
  transform: scale(1);
}
.aro.table > tbody > tr:hover td:not(.hidden) ~ td .row-options {
  opacity: 0;
  transform: scale(0.9);
}
.aro.table > tbody > tr.selectable > td {
  cursor: pointer;
}
.aro.table > tbody > tr.selectable:hover > td {
  background-color: #f3f3f6 !important;
}
.aro.table.striped > tbody > tr:nth-child(even) > td {
  background-color: #fafafb;
}
.aro.table.striped > tbody > tr:nth-child(odd) > td {
  background-color: #fff;
}
.aro.table .row-options {
  opacity: 0;
  transform: scale(0.9);
  display: flex;
  position: absolute;
  background-color: #fff;
  padding: 0px 4px 0px 20px;
  right: 0px;
  height: 100%;
  top: 0px;
  font-size: 14px;
  align-items: center;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 10%);
  transition: opacity 0.2s ease, transform 0.2s ease;
}
.aro.table .row-options > .option {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 600;
  margin: 0px 0px;
  padding: 4px 8px;
  border-radius: 6px;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.aro.table .row-options > .option > i {
  font-size: 16px;
  margin-right: 5px;
}
.aro.table .row-options > .option:hover {
  background-color: #EAEBEF;
  color: #39449C;
  text-decoration: underline;
}
.aro.table .trueGrip {
  position: absolute;
  border-left: 6px solid rgba(66, 134, 244, 0);
  height: 100%;
  top: 0px;
  right: 0px;
  cursor: w-resize;
  z-index: 21;
  transform: translateZ(15px);
}
.aro.table .trueGrip:hover {
  border-left: 6px solid #4286f4;
}
.aro.dropdown.checkbox-group.active,
.aro.dropdown.checkbox-group.active:hover {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom: none;
}
.aro.dropdown.checkbox-group .menu,
.aro.dropdown.checkbox-group .menu:hover {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid #39449C;
  border-top: none !important;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  left: -1px;
  right: -1px;
}
.aro.dropdown.checkbox-group .select-button-group > span {
  font-size: 14px;
  cursor: pointer;
  transition: color 0.2s ease;
}
.aro.dropdown.checkbox-group .select-button-group > span:hover {
  color: #39449C;
  text-decoration: underline;
}
.aro.dropdown.checkbox-group input[type="checkbox"] {
  margin-right: 5px;
}
.aro.dropdown.checkbox-group .menu .item {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.aro.collapsable-group {
  opacity: 1;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}
.aro.collapsable-group.collapsed {
  max-height: 0px !important;
  overflow: hidden;
  opacity: 0;
}
.aro.pill-group {
  display: flex;
  align-items: center;
  gap: 10px;
}
.aro.pill-group.scrollable {
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.aro.pill-group.scrollable::-webkit-scrollbar {
  display: none;
  width: 0px;
  background: transparent;
  /* make scrollbar transparent */
}
.aro.pill-group.scrollable > .pill {
  flex: 0 0 auto;
}
.aro.pill-group > .pill {
  border: 1px solid #39449C;
  border-radius: 100px;
  color: #39449C;
  padding: 10px 20px;
  user-select: none;
  transition: background-color 0.2s ease;
}
.aro.pill-group > .pill.active {
  background-color: #39449C;
  color: #fff;
}
.aro.pill-group > .pill:hover {
  background-color: #39449C;
  color: #fff;
}
.aro.popup-menu {
  border-radius: 8px;
  position: absolute;
  z-index: 50;
  background-color: #fff;
  overflow: hidden;
  padding: 10px 0px;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15);
}
.aro.popup-menu .item {
  display: block;
  color: #232229;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  white-space: nowrap;
  transition: background-color 0.2s ease;
}
.aro.popup-menu .item:hover {
  background-color: #f3f3f6;
}
.aro.popup-menu.top-0 {
  top: 0px;
}
.aro.popup-menu.bottom-0 {
  bottom: 0px;
}
.aro.popup-menu.left-0 {
  left: 0px;
}
.aro.popup-menu.right-0 {
  right: 0px;
}
.aro.popup-menu.top-5 {
  top: 5px;
}
.aro.popup-menu.bottom-5 {
  bottom: 5px;
}
.aro.popup-menu.left-5 {
  left: 5px;
}
.aro.popup-menu.right-5 {
  right: 5px;
}
.aro.popup-menu.top-10 {
  top: 10px;
}
.aro.popup-menu.bottom-10 {
  bottom: 10px;
}
.aro.popup-menu.left-10 {
  left: 10px;
}
.aro.popup-menu.right-10 {
  right: 10px;
}
.aro.popup-menu.top-15 {
  top: 15px;
}
.aro.popup-menu.bottom-15 {
  bottom: 15px;
}
.aro.popup-menu.left-15 {
  left: 15px;
}
.aro.popup-menu.right-15 {
  right: 15px;
}
.aro.popup-menu.top-20 {
  top: 20px;
}
.aro.popup-menu.bottom-20 {
  bottom: 20px;
}
.aro.popup-menu.left-20 {
  left: 20px;
}
.aro.popup-menu.right-20 {
  right: 20px;
}
.aro.popup-menu.top-25 {
  top: 25px;
}
.aro.popup-menu.bottom-25 {
  bottom: 25px;
}
.aro.popup-menu.left-25 {
  left: 25px;
}
.aro.popup-menu.right-25 {
  right: 25px;
}
.aro.popup-menu.top-30 {
  top: 30px;
}
.aro.popup-menu.bottom-30 {
  bottom: 30px;
}
.aro.popup-menu.left-30 {
  left: 30px;
}
.aro.popup-menu.right-30 {
  right: 30px;
}
.aro.popup-menu.top-35 {
  top: 35px;
}
.aro.popup-menu.bottom-35 {
  bottom: 35px;
}
.aro.popup-menu.left-35 {
  left: 35px;
}
.aro.popup-menu.right-35 {
  right: 35px;
}
.aro.popup-menu.top-40 {
  top: 40px;
}
.aro.popup-menu.bottom-40 {
  bottom: 40px;
}
.aro.popup-menu.left-40 {
  left: 40px;
}
.aro.popup-menu.right-40 {
  right: 40px;
}
.aro.popup-menu.top-45 {
  top: 45px;
}
.aro.popup-menu.bottom-45 {
  bottom: 45px;
}
.aro.popup-menu.left-45 {
  left: 45px;
}
.aro.popup-menu.right-45 {
  right: 45px;
}
.aro.popup-menu.top-50 {
  top: 50px;
}
.aro.popup-menu.bottom-50 {
  bottom: 50px;
}
.aro.popup-menu.left-50 {
  left: 50px;
}
.aro.popup-menu.right-50 {
  right: 50px;
}
.aro.popup-menu.origin-top-right {
  transform-origin: top right;
}
.aro.popup-menu.origin-top-left {
  transform-origin: top left;
}
.aro.popup-menu.enter {
  transition: opacity 0.1s ease-out, transform 0.1s ease-out;
}
.aro.popup-menu.enter-start {
  opacity: 0;
  transform: scale(0.95);
}
.aro.popup-menu.enter-end {
  opacity: 1;
  transform: scale(1);
}
.aro.popup-menu.leave {
  transition: opacity 0.1s ease-in, transform 0.1s ease-in;
}
.aro.popup-menu.leave-start {
  opacity: 1;
  transform: scale(1);
}
.aro.popup-menu.leave-end {
  opacity: 0;
  transform: scale(0.95);
}
.aro.meta-group {
  font-size: 0.875em;
  display: flex;
  align-items: center;
  gap: 10px;
}
.aro.meta-group > .title {
  color: #65666B;
}
.aro.meta-group > .description {
  color: #232229;
  font-weight: 500;
}
.aro.meta-item {
  display: flex;
  gap: 10px;
}
.aro.meta-item i {
  font-size: 1.2em;
  color: #00C19F;
}
.aro.meta-item a:hover {
  text-decoration: underline;
}
.aro.meta-item .content {
  font-size: 0.875em;
  color: #39393F;
  font-weight: 500;
}
.aro.tabs-overflow {
  position: relative;
}
.aro.tabs-overflow:after {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 40px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}
.aro.tabs {
  width: 100%;
  border-bottom: 1px solid #EAEBEF;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.aro.tabs > .tab {
  padding: 20px 0px;
  cursor: pointer;
  transition: color 0.2s ease;
  font-weight: 400;
  color: #65666B;
  flex-shrink: 0;
}
.aro.tabs > .tab.active {
  border-bottom: 1px solid #39449C;
  color: #39449C;
}
.aro.tabs > .tab.active p {
  font-weight: 600;
}
.aro.tabs > .tab:hover {
  color: #39449C;
}
.aro.tabs.no-wrap {
  flex-wrap: nowrap;
  overflow: auto;
  padding-right: 40px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.aro.tabs.no-wrap::-webkit-scrollbar {
  display: none;
  width: 0px;
  background: transparent;
  /* make scrollbar transparent */
}
.aro.banner {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  border: 1px solid #EAEBEF;
}
.aro.banner.error {
  background-color: #ffdddb;
  color: #B01B0C;
}
.aro.widget {
  position: relative;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(60, 61, 73, 0.08);
  padding: 30px;
  color: #232229;
}
.aro.widget.small {
  height: 100%;
  max-height: 260px;
}
.aro.widget .heading-wrapper > .title {
  font-size: 1em;
  font-weight: 400;
  color: rgba(57, 57, 63, 0.8);
}
.aro.widget .heading-wrapper > .title > a {
  padding: 6px;
  color: rgba(57, 57, 63, 0.6);
}
.aro.widget .heading-wrapper > .title > a:hover {
  color: #00A3E0;
}
.aro.widget .heading-wrapper > .subtitle {
  font-size: 1em;
  font-weight: 600;
  color: #232229;
}
.aro.widget > .aro.ui.loader {
  position: absolute;
  bottom: 20px;
  left: 30px;
  top: auto;
  transform: none;
}
.survey.widget.aro {
  overflow: hidden;
  display: flex;
}
.survey.widget.aro .inner-left {
  padding-right: 2em;
}
.survey.widget.aro .inner-right {
  flex: 1 1 auto;
}
.survey.widget.aro .overall-score {
  font-size: 2em;
  font-weight: 600;
  margin-top: 4px;
  line-height: 1;
}
.survey.widget.aro .recommend-wrapper {
  margin-top: 23px;
  color: rgba(35, 34, 41, 0.8);
  font-weight: 500;
}
.survey.widget.aro .recommend-wrapper .heading {
  font-size: 1em;
}
.survey.widget.aro .total-surveys {
  color: rgba(57, 57, 63, 0.8);
}
.survey.widget.aro .total-surveys > .num {
  font-weight: 600;
}
.survey-overall-rating.widget.aro {
  display: inline-flex;
  align-items: center;
  gap: 30px;
}
.survey-overall-rating.widget.aro .title {
  color: rgba(35, 34, 41, 0.8);
  font-weight: 600;
  font-size: 1.125em;
  margin-bottom: 6px;
}
.survey-overall-rating.widget.aro .value {
  font-size: 2em;
  color: #232229;
  font-weight: 600;
  line-height: 1;
}
.survey-listing.widget.aro {
  max-width: 640px;
}
.survey-listing.widget.aro table {
  width: 100%;
  border-collapse: collapse;
}
.survey-listing.widget.aro table thead tr {
  border-bottom: 1px solid #C3C3C5;
}
.survey-listing.widget.aro table thead tr th {
  padding: 15px 0px 10px;
  text-align: left;
  color: rgba(35, 34, 41, 0.7);
  font-weight: 600;
  size: 0.875em;
}
.survey-listing.widget.aro table tbody tr {
  border-bottom: 1px solid #EAEBEF;
  color: #232229;
}
.survey-listing.widget.aro table tbody tr:last-child {
  border-bottom: none;
}
.survey-listing.widget.aro table tbody tr td {
  padding: 15px 0px;
}
.costsaves.widget.aro .main-total {
  font-size: 2em;
  margin-top: 22px;
  font-weight: 600;
  line-height: 1;
}
.costsaves.widget.aro .subtotals-section {
  max-width: 360px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.costsaves.widget.aro .subtotals-section .subtitle {
  color: rgba(35, 34, 41, 0.8);
  font-size: 1em;
}
.costsaves.widget.aro .subtotals-section .title {
  margin-top: 4px;
  font-size: 1.5em;
  color: #39393F;
  font-weight: 600;
}
.costsaves-grand-totals.widget.aro {
  display: inline-block;
  max-width: 100%;
}
.costsaves-grand-totals.widget.aro table {
  width: 100%;
  border-collapse: collapse;
}
.costsaves-grand-totals.widget.aro table th.heading,
.costsaves-grand-totals.widget.aro table td.heading {
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1;
  color: rgba(35, 34, 41, 0.7);
}
.costsaves-grand-totals.widget.aro table th,
.costsaves-grand-totals.widget.aro table td:not(.heading) {
  padding: 15px 5px;
  border-bottom: 1px solid #EAEBEF;
}
.costsaves-grand-totals.widget.aro table tbody tr:last-child td {
  border-bottom: none;
}
.costsaves-grand-totals.widget.aro .monthly-totals-wrapper {
  max-width: 100%;
  overflow-x: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
}
.costsaves-grand-totals.widget.aro .monthly-totals-wrapper::-webkit-scrollbar {
  display: none;
}
.activitylog.widget.aro .chart-container {
  display: flex;
  margin-top: 20px;
  column-gap: 20px;
}
.activitylog.widget.aro .chart-container .legend-wrapper {
  display: flex;
  align-items: center;
}
.activitylog.widget.aro .chart-container .chart-wrapper {
  flex: 0 1 400px;
  overflow: hidden;
}
@media screen and (max-width: 1030px) {
  .activitylog.widget.aro .chart-container {
    flex-wrap: wrap-reverse;
    justify-content: center;
    row-gap: 30px;
  }
}
.defects-breakdown.widget.aro {
  max-height: none;
}
.defects-breakdown.widget.aro .breakdown-wrapper > .title {
  font-size: 0.875em;
  font-weight: 600;
  color: #232229;
}
.defects-breakdown.widget.aro .breakdown-wrapper table.breakdown-table {
  width: 100%;
  border-collapse: collapse;
}
.defects-breakdown.widget.aro .breakdown-wrapper table.breakdown-table td.value {
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1;
  color: #39393F;
  text-align: right;
}
.defects-breakdown.widget.aro .breakdown-wrapper table.breakdown-table td {
  font-size: 0.875em;
  padding: 15px 5px;
  border-bottom: 1px solid #EAEBEF;
}
.defects-breakdown.widget.aro .breakdown-wrapper table.breakdown-table tr:last-child td {
  border-bottom: none;
}
.defects.widget.aro.bordered {
  border: 2px solid #39449C;
}
.aro.grid.columns-1-auto {
  display: grid;
  grid-template-columns: minmax(300px, 375px) 1fr;
  grid-gap: 20px;
  align-items: start;
}
@media screen and (max-width: 480px) {
  .aro.grid.columns-1-auto {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 768px) {
  .aro.grid.columns-1-auto.tablet {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 1200px) {
  .aro.grid.columns-1-auto.tablet-lg {
    grid-template-columns: 1fr;
  }
}
.aro.grid.columns-1-1-auto {
  display: grid;
  grid-template-columns: minmax(300px, 375px) minmax(300px, 375px) 1fr;
  column-gap: 20px;
  row-gap: 20px;
  align-items: start;
}
@media screen and (max-width: 768px) {
  .aro.grid.columns-1-1-auto {
    grid-template-columns: 1fr;
  }
}
.aro.grid.columns-1-1-1 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 375px));
  column-gap: 20px;
  row-gap: 20px;
  align-items: start;
}
@media screen and (max-width: 768px) {
  .aro.grid.columns-1-1-1 {
    grid-template-columns: 1fr;
  }
}
.aro.grid.columns-2-auto {
  display: grid;
  grid-template-columns: minmax(600px, 770px) 1fr;
  column-gap: 20px;
  row-gap: 20px;
  align-items: start;
}
@media screen and (max-width: 480px) {
  .aro.grid.columns-2-auto {
    grid-template-columns: 1fr;
  }
}
.aro.grid.columns-3 {
  display: grid;
  grid-template-columns: minmax(300px, 1161px);
  align-items: start;
}
@media screen and (max-width: 480px) {
  .aro.grid.columns-3 {
    grid-template-columns: 1fr;
  }
}
.aro.grid.columns-1-3 {
  display: grid;
  grid-template-columns: minmax(300px, 375px) minmax(300px, 1161px);
  align-items: start;
  column-gap: 20px;
  row-gap: 20px;
}
.aro.grid.columns-1-3.with-sticky > .column:first-child {
  position: sticky;
  top: 30px;
  z-index: 1;
}
@media screen and (max-width: 1200px) {
  .aro.grid.columns-1-3 {
    grid-template-columns: 1fr !important;
  }
  .aro.grid.columns-1-3.reverse > .column:first-child {
    order: 2;
  }
  .aro.grid.columns-1-3.with-sticky > .column:last-child {
    position: static;
  }
}
.aro.grid.columns-1-3.fluid {
  grid-template-columns: 1fr minmax(300px, 375px);
}
.aro.grid.columns-3-1 {
  display: grid;
  grid-template-columns: minmax(300px, 1161px) minmax(300px, 375px);
  align-items: start;
  column-gap: 20px;
  row-gap: 20px;
}
.aro.grid.columns-3-1.with-sticky > .column:last-child {
  position: sticky;
  top: 30px;
  z-index: 1;
}
@media screen and (max-width: 1200px) {
  .aro.grid.columns-3-1 {
    grid-template-columns: 1fr !important;
  }
  .aro.grid.columns-3-1.reverse > .column:first-child {
    order: 2;
  }
  .aro.grid.columns-3-1.with-sticky > .column:last-child {
    position: static;
  }
}
.aro.grid.columns-3-1.fluid {
  grid-template-columns: 1fr minmax(300px, 375px);
}
.aro.grid.columns-1-2-1 {
  display: grid;
  grid-template-columns: minmax(300px, 375px) minmax(600px, 770px) minmax(300px, 375px);
  column-gap: 20px;
  row-gap: 20px;
  align-items: start;
}
@media screen and (max-width: 480px) {
  .aro.grid.columns-1-2-1 {
    grid-template-columns: 1fr;
  }
}
.aro.grid.columns-1-2 {
  display: grid;
  grid-template-columns: minmax(300px, 375px) minmax(300px, 770px);
  column-gap: 20px;
  row-gap: 20px;
  align-items: start;
}
@media screen and (max-width: 768px) {
  .aro.grid.columns-1-2 {
    grid-template-columns: 1fr;
  }
}
.aro.flex {
  display: flex;
}
.aro.flex.row {
  flex-direction: row;
}
.aro.flex.column {
  flex-direction: column;
}
.aro.flex.space-between {
  justify-content: space-between;
}
.aro.flex.justify-center {
  justify-content: center;
}
.aro.flex.align-center {
  align-items: center;
}
.aro.flex.gap-20 {
  gap: 20px;
}
.aro.dynamic-grid.layout-1-1-1-1 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 375px));
  column-gap: 20px;
  row-gap: 20px;
  align-items: start;
}
.aro.dynamic-grid.layout-1-1-1-1 .column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media screen and (max-width: 768px) {
  .aro.dynamic-grid.layout-1-1-1-1 {
    grid-template-columns: 1fr;
  }
}
.aro.layout-container {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 48px;
}
.aro.layout-container.width-600 {
  max-width: 600px;
}
@media screen and (max-width: 1200px) {
  .aro.layout-container {
    padding: 0px 0px;
  }
}
.aro-ui-wrapper {
  display: grid;
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: #F4F6F8;
  grid-template-columns: auto 1fr;
  grid-template-rows: 70px 1fr 30px;
  grid-template-areas: "sidenav header" "sidenav main" "sidenav footer";
}
@media screen and (max-width: 480px) {
  .aro-ui-wrapper {
    display: flex;
    flex-direction: column;
    position: static;
    min-height: 100%;
    padding-top: 70px;
  }
}
.aro-ui-wrapper.with-titlebar {
  grid-template-rows: 70px auto 1fr 30px;
  grid-template-areas: "sidenav header" "sidenav title" "sidenav main" "sidenav footer";
}
.aro-ui-wrapper .app-ui-sidebar {
  grid-area: sidenav;
  height: 100%;
  background-color: #232229;
  display: flex;
  flex-direction: column;
  width: 78px;
  overflow-x: auto;
  transition: width 0.3s ease;
  position: relative;
}
.aro-ui-wrapper .app-ui-sidebar::-webkit-scrollbar {
  width: 4px;
  background-color: #EAEBEF;
}
.aro-ui-wrapper .app-ui-sidebar::-webkit-scrollbar-thumb {
  background-color: #39393F;
  border-radius: 4px;
}
.aro-ui-wrapper .app-ui-sidebar.discrete {
  width: 0px;
}
.aro-ui-wrapper .app-ui-sidebar .app-ui-sidebar-header {
  display: flex;
  align-items: center;
  font-family: 'Montserrat', 'Inter', sans-serif;
  background-color: #fff;
  padding: 16px 20px;
  justify-content: space-between;
  border-right: 1px solid #e6e6e6;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 100;
  width: 100%;
}
.aro-ui-wrapper .app-ui-sidebar .app-ui-sidebar-header > img {
  height: 37px;
  width: 37px;
}
.aro-ui-wrapper .app-ui-sidebar .app-ui-sidebar-header > .heading,
.aro-ui-wrapper .app-ui-sidebar .app-ui-sidebar-header > .heading-img {
  color: #001871;
  font-size: 18px;
  font-weight: 700;
  opacity: 0;
  position: absolute;
  left: -500px;
  transition: opacity 0.2s ease;
}
@media screen and (max-width: 480px) {
  .aro-ui-wrapper .app-ui-sidebar .app-ui-sidebar-header {
    display: none;
  }
}
.aro-ui-wrapper .app-ui-sidebar .items-wrapper {
  height: auto;
  max-height: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 auto;
  font-size: 14px;
}
@media screen and (max-width: 480px) {
  .aro-ui-wrapper .app-ui-sidebar .items-wrapper {
    overflow: auto;
  }
}
.aro-ui-wrapper .app-ui-sidebar .items-wrapper > .main {
  padding-bottom: 40px;
}
.aro-ui-wrapper .app-ui-sidebar .items-wrapper .item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 16px 20px;
  padding-left: 27px;
  cursor: pointer;
  text-decoration: none;
}
.aro-ui-wrapper .app-ui-sidebar .items-wrapper .item .item-content {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  user-select: none;
  transition: transform 0.2s ease;
}
.aro-ui-wrapper .app-ui-sidebar .items-wrapper .item .item-content > p {
  margin: 0px 0px 0px 16px;
  opacity: 0;
  transition: opacity 0.2s ease;
}
.aro-ui-wrapper .app-ui-sidebar .items-wrapper .item .item-content > svg {
  width: 22px;
  height: 22px;
}
.aro-ui-wrapper .app-ui-sidebar .items-wrapper .item:not(.child):hover .item-content {
  transform: translateX(3px);
}
.aro-ui-wrapper .app-ui-sidebar .items-wrapper .item.active::after {
  content: '';
  position: absolute;
  width: 4px;
  height: 23px;
  border-radius: 0px 3px 3px 0px;
  background-color: #39449C;
  margin: auto 0px;
  left: 0px;
}
.aro-ui-wrapper .app-ui-sidebar .items-wrapper .item.child {
  padding: 4px 20px;
  opacity: 0;
  position: absolute;
  left: -600px;
  transition: opacity 0.2s ease;
}
.aro-ui-wrapper .app-ui-sidebar .items-wrapper .item.child .item-content {
  display: block;
  padding: 9px 40px;
  width: 100%;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  transition: background-color 0.2s ease;
}
.aro-ui-wrapper .app-ui-sidebar .items-wrapper .item.child .item-content > p {
  margin: 0px;
}
.aro-ui-wrapper .app-ui-sidebar .items-wrapper .item.child .item-content:hover {
  background-color: #39449C;
}
.aro-ui-wrapper .app-ui-sidebar .items-wrapper .sidebar-dropdown-item > .item.dropdown > svg {
  width: 22px;
  height: 22px;
  transform: rotate(90deg);
  opacity: 0;
  transition: transform 0.2s ease, opacity 0.2s ease;
}
.aro-ui-wrapper .app-ui-sidebar .items-wrapper .sidebar-dropdown-item .dropdown-container {
  height: auto;
  opacity: 1;
  transition: opacity 0.2s ease, max-height 0.2s ease;
}
.aro-ui-wrapper .app-ui-sidebar .items-wrapper .sidebar-dropdown-item.collapsed > .item.dropdown > svg {
  transform: rotate(0deg);
}
.aro-ui-wrapper .app-ui-sidebar .items-wrapper .sidebar-dropdown-item.collapsed .dropdown-container {
  max-height: 0px !important;
  overflow: hidden;
  opacity: 0;
}
.aro-ui-wrapper .app-ui-sidebar .items-wrapper .divider {
  height: 1px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 10px 0px;
}
@media screen and (max-width: 480px) {
  .aro-ui-wrapper .app-ui-sidebar {
    width: 0px;
    overflow: hidden;
    position: fixed;
    z-index: 100;
    top: 0px;
    bottom: 0px;
    height: auto;
    padding-top: 70px;
  }
}
.aro-ui-wrapper .app-ui-header {
  grid-area: header;
  background-color: #fff;
  position: relative;
  display: flex;
  width: auto;
  align-items: center;
  padding: 0px 0px 0px 20px;
  height: 70px;
  border-bottom: 1px solid #EAEBEF;
  justify-content: space-between;
}
.aro-ui-wrapper .app-ui-header .user-details {
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  position: relative;
  z-index: 20;
  user-select: none;
}
.aro-ui-wrapper .app-ui-header .user-details > p {
  font-weight: 600;
  margin-right: 14px;
}
.aro-ui-wrapper .app-ui-header .user-details > .user-menu {
  display: none;
  position: absolute;
  top: 75px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #EAEBEF;
  min-width: 200px;
  right: 20px;
  overflow: hidden;
  padding: 4px 0px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
}
.aro-ui-wrapper .app-ui-header .user-details > .user-menu.active {
  display: block;
}
.aro-ui-wrapper .app-ui-header .user-details > .user-menu a {
  padding: 4px 20px;
  color: #232229;
  display: block;
}
.aro-ui-wrapper .app-ui-header .user-details > .user-menu a:hover {
  background-color: #EAEBEF;
}
.aro-ui-wrapper .app-ui-header .links-block {
  display: flex;
  height: 100%;
}
@media screen and (max-width: 1200px) {
  .aro-ui-wrapper .app-ui-header .links-block {
    display: none;
  }
}
.aro-ui-wrapper .app-ui-header .links-block > .item {
  position: relative;
  height: 100%;
  font-size: 0.875em;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
  padding: 0px 20px;
}
.aro-ui-wrapper .app-ui-header .links-block > .item.active {
  border-bottom: 2px solid #39449C;
}
.aro-ui-wrapper .app-ui-header .links-block > .item.active > a,
.aro-ui-wrapper .app-ui-header .links-block > .item.active > .link {
  color: #39449C;
}
.aro-ui-wrapper .app-ui-header .links-block > .item a,
.aro-ui-wrapper .app-ui-header .links-block > .item .link {
  height: 100%;
  display: flex;
  align-items: center;
  column-gap: 5px;
  color: rgba(0, 0, 0, 0.7);
  transition: color 0.2 ease;
  cursor: pointer;
}
.aro-ui-wrapper .app-ui-header .links-block > .item a:hover,
.aro-ui-wrapper .app-ui-header .links-block > .item .link:hover {
  color: #39449C;
}
.aro-ui-wrapper .app-ui-header > .sidebar-toggle {
  font-size: 24px;
  cursor: pointer;
}
.aro-ui-wrapper .app-ui-header > .sidebar-toggle:hover {
  color: #39449C;
}
.aro-ui-wrapper .app-ui-header .user-nav-wrapper {
  display: flex;
  align-items: center;
  padding: 0px 20px;
  cursor: pointer;
  user-select: none;
  height: 100%;
}
.aro-ui-wrapper .app-ui-header .user-nav-wrapper .site-logo {
  margin-right: 14px;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background-color: #EEF3FE;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aro-ui-wrapper .app-ui-header .user-nav-wrapper .site-logo img {
  max-width: 70%;
}
.aro-ui-wrapper .app-ui-header .user-nav-wrapper .details {
  margin-right: 20px;
}
.aro-ui-wrapper .app-ui-header .user-nav-wrapper .details .name {
  font-weight: 700;
}
.aro-ui-wrapper .app-ui-header .user-nav-wrapper .details .site {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.7);
}
.aro-ui-wrapper .app-ui-header .user-nav-wrapper svg {
  color: rgba(0, 0, 0, 0.7);
  fill: rgba(0, 0, 0, 0.7);
}
.aro-ui-wrapper .app-ui-header.minimalistic {
  justify-content: space-between;
}
.aro-ui-wrapper .app-ui-header.minimalistic .sidebar-toggle {
  display: none;
}
@media screen and (max-width: 1200px) {
  .aro-ui-wrapper .app-ui-header.minimalistic .sidebar-toggle {
    display: block;
  }
}
.aro-ui-wrapper .app-ui-header.minimalistic > .icon-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
.aro-ui-wrapper .app-ui-header.minimalistic > .icon-wrapper img {
  width: 37px;
  height: 37px;
}
.aro-ui-wrapper .app-ui-header.minimalistic > .icon-wrapper .heading {
  font-weight: 700;
  font-size: 1.125em;
  margin: 0px;
  color: #001871;
  font-family: 'Montserrat', 'Inter', sans-serif;
}
@media screen and (max-width: 1200px) {
  .aro-ui-wrapper .app-ui-header.minimalistic > .icon-wrapper {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .aro-ui-wrapper .app-ui-header {
    z-index: 110;
    position: fixed;
    top: 0px;
    width: 100%;
  }
}
.aro-ui-wrapper .app-ui-title-block {
  grid-area: title;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 20px;
  justify-content: space-between;
  row-gap: 20px;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 10;
}
@media screen and (max-width: 480px) {
  .aro-ui-wrapper .app-ui-title-block {
    padding: 15px 20px 10px;
  }
}
.aro-ui-wrapper .app-ui-title-block .title {
  color: #232229;
  font-size: 20px;
  margin: 0px 66px 0px 0px;
}
.aro-ui-wrapper .app-ui-title-block .heading {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1400px) {
  .aro-ui-wrapper .app-ui-title-block .heading {
    display: block;
  }
}
.aro-ui-wrapper .app-ui-title-block .actions-wrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.aro-ui-wrapper .app-ui-title-block .aro-title-block {
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: space-between;
}
.aro-ui-wrapper .app-ui-title-block.mobile-ready .mobile-actions {
  display: none;
}
@media screen and (max-width: 1400px) {
  .aro-ui-wrapper .app-ui-title-block.mobile-ready .mobile-actions {
    display: block;
  }
}
@media screen and (max-width: 1400px) {
  .aro-ui-wrapper .app-ui-title-block.mobile-ready .actions-wrapper {
    display: none;
  }
}
.aro-ui-wrapper .app-ui-content {
  grid-area: main;
  padding: 20px;
  display: block;
  overflow-y: scroll;
}
.aro-ui-wrapper .app-ui-content.white {
  background-color: #fff;
}
@media screen and (max-width: 480px) {
  .aro-ui-wrapper .app-ui-content {
    padding: 10px 10px 50px 10px;
    overflow-y: visible;
  }
}
.aro-ui-wrapper .app-ui-footer {
  grid-area: footer;
  background-color: #fff;
  display: flex;
  border-top: 1px solid #EAEBEF;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  font-size: 12px;
}
@media screen and (max-width: 480px) {
  .aro-ui-wrapper .app-ui-footer {
    margin-top: auto;
    font-size: 10px;
  }
}
.aro-ui-wrapper .app-ui-footer > img {
  height: 17px;
}
.aro-ui-wrapper .app-ui-sidebar.expanded,
.aro-ui-wrapper .app-ui-sidebar.soft-expanded {
  width: 267px;
}
.aro-ui-wrapper .app-ui-sidebar.expanded .items-wrapper .item .item-content > p,
.aro-ui-wrapper .app-ui-sidebar.soft-expanded .items-wrapper .item .item-content > p {
  opacity: 100;
}
.aro-ui-wrapper .app-ui-sidebar.expanded .sidebar-dropdown-item > .item.dropdown > svg,
.aro-ui-wrapper .app-ui-sidebar.soft-expanded .sidebar-dropdown-item > .item.dropdown > svg {
  opacity: 100;
}
.aro-ui-wrapper .app-ui-sidebar.expanded .app-ui-sidebar-header > .heading,
.aro-ui-wrapper .app-ui-sidebar.soft-expanded .app-ui-sidebar-header > .heading,
.aro-ui-wrapper .app-ui-sidebar.expanded .app-ui-sidebar-header > .heading-img,
.aro-ui-wrapper .app-ui-sidebar.soft-expanded .app-ui-sidebar-header > .heading-img {
  opacity: 100;
  position: static;
}
.aro-ui-wrapper .app-ui-sidebar.expanded .app-ui-sidebar-header.roboworx-varient > img,
.aro-ui-wrapper .app-ui-sidebar.soft-expanded .app-ui-sidebar-header.roboworx-varient > img {
  display: none;
}
.aro-ui-wrapper .app-ui-sidebar.expanded .item.child,
.aro-ui-wrapper .app-ui-sidebar.soft-expanded .item.child {
  position: relative;
  left: 0px;
  opacity: 100;
}
@media screen and (max-width: 480px) {
  .aro-ui-wrapper .app-ui-sidebar.soft-expanded:not(.expanded) {
    width: 0px;
  }
}
.aro-user-nav-wrapper {
  display: flex;
  align-items: center;
  padding: 0px 20px;
  user-select: none;
  height: 100%;
}
.aro-user-nav-wrapper.selectable {
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.aro-user-nav-wrapper.selectable:hover {
  background-color: #f0f1f3;
}
.aro-user-nav-wrapper.no-padding {
  padding: 0px;
}
.aro-user-nav-wrapper .site-logo {
  margin-right: 14px;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background-color: #EEF3FE;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #EAEBEF;
  flex: 0 0 auto;
}
.aro-user-nav-wrapper .site-logo img {
  max-width: 70%;
}
.aro-user-nav-wrapper .details {
  margin-right: 20px;
  flex: 0 1 200px;
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.aro-user-nav-wrapper .details .name {
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
}
.aro-user-nav-wrapper .details .site {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.7);
  text-overflow: ellipsis;
  overflow: hidden;
}
.aro-user-nav-wrapper svg {
  color: rgba(0, 0, 0, 0.7);
  fill: rgba(0, 0, 0, 0.7);
}
.aro-user-nav-wrapper > a.aro.button {
  margin-left: auto;
}
.aro-title-bar-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px 0px 20px;
  justify-content: space-between;
  row-gap: 20px;
}
@media screen and (max-width: 480px) {
  .aro-title-bar-wrapper {
    padding: 15px 0px 10px;
  }
}
.aro-title-bar-wrapper .title {
  color: #232229;
  margin: 0px 66px 0px 0px;
}
.aro-title-bar-wrapper .heading {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1400px) {
  .aro-title-bar-wrapper .heading {
    display: block;
  }
}
.aro-title-bar-wrapper .actions-wrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.aro-title-bar-wrapper.mobile-ready .mobile-actions {
  display: none;
}
@media screen and (max-width: 1400px) {
  .aro-title-bar-wrapper.mobile-ready .mobile-actions {
    display: block;
  }
}
@media screen and (max-width: 1400px) {
  .aro-title-bar-wrapper.mobile-ready .actions-wrapper {
    display: none;
  }
}
.aro-breadcrumbs-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.875rem;
}
@media screen and (max-width: 768px) {
  .aro-breadcrumbs-container {
    display: none;
  }
}
.aro-breadcrumbs-container a {
  color: rgba(0, 0, 0, 0.7);
  padding: 5px;
}
.aro-breadcrumbs-container a:hover {
  color: #39449C;
}
.aro-breadcrumbs-container span {
  padding: 5px;
}
.aro-breadcrumbs-container svg {
  width: 16px;
  height: 16px;
  fill: rgba(0, 0, 0, 0.7);
  margin: 0px 0px;
}
.aro-breadcrumbs-container .home-icon {
  margin: 0px;
}
.aro-client-breadcrumbs-block {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.aro-client-breadcrumbs-block .breadcrumb-item {
  font-size: 1.25em;
  margin: 0px;
  color: #232229;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .aro-client-breadcrumbs-block .breadcrumb-item {
    font-size: 1em;
  }
}
.aro-client-breadcrumbs-block .breadcrumb-item.active {
  font-weight: 600;
}
.aro-client-breadcrumbs-block a {
  transition: color 0.2s ease;
}
.aro-client-breadcrumbs-block a:hover {
  color: #00A3E0;
}
@media screen and (max-width: 480px) {
  .aro-client-breadcrumbs-block .breadcrumb-item,
  .aro-client-breadcrumbs-block .breadcrumb-icon {
    display: none;
  }
  .aro-client-breadcrumbs-block .breadcrumb-item.active {
    display: block;
  }
}
.aro-table-wrapper {
  /*
    Mixin for force mobile styling
    This will create breakpoint styles and force-mobile styles at the same time
    This way our mobile styles work the same for both .force-mobile and our css media query breakpoint
  */
}
.aro-table-wrapper .aro-data-table-toolbar {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 1px solid #EAEBEF;
}
.aro-table-wrapper .aro-data-table-toolbar > .categories {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #727380;
  font-weight: 600;
}
.aro-table-wrapper .aro-data-table-toolbar > .categories .category {
  position: relative;
  cursor: pointer;
  line-height: 39px;
  transition: color 0.2s ease;
}
.aro-table-wrapper .aro-data-table-toolbar > .categories .category:hover {
  color: #39449C;
}
.aro-table-wrapper .aro-data-table-toolbar > .categories .category.active {
  color: #39449C;
}
.aro-table-wrapper .aro-data-table-toolbar > .categories .category.active::after {
  content: '';
  width: 100%;
  background-color: #39449C;
  height: 2px;
  position: absolute;
  bottom: -16px;
  left: 0px;
}
.aro-table-wrapper .aro-data-table-toolbar > .categories > * {
  margin: 0px 8px;
}
.aro-table-wrapper .aro-data-table-toolbar > .categories > *:first-child {
  margin-left: 0px;
}
.aro-table-wrapper .aro-data-table-toolbar > .categories > *:last-child {
  margin-right: 0px;
}
.aro-table-wrapper .aro-data-table-toolbar > .results-group {
  display: flex;
  align-items: center;
}
.aro-table-wrapper .aro-data-table-toolbar > .results-group .total-info {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
}
.aro-table-wrapper .aro-data-table-toolbar > .results-group > * {
  margin: 0px 8px;
}
.aro-table-wrapper .aro-data-table-toolbar > .results-group > *:first-child {
  margin-left: 0px;
}
.aro-table-wrapper .aro-data-table-toolbar > .results-group > *:last-child {
  margin-right: 0px;
}
.aro-table-wrapper .aro-data-table-peripherals {
  padding: 10px 20px;
  border-bottom: 1px solid #EAEBEF;
}
.aro-table-wrapper .aro-data-table-peripherals > .top-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.aro-table-wrapper .aro-data-table-peripherals > .top-row > .button-group {
  display: flex;
}
.aro-table-wrapper .aro-data-table-peripherals > .top-row > .button-group > * {
  margin: 0px 5px;
}
.aro-table-wrapper .aro-data-table-peripherals > .top-row > .button-group > *:first-child {
  margin-left: 0px;
}
.aro-table-wrapper .aro-data-table-peripherals > .top-row > .button-group > *:last-child {
  margin-right: 0px;
}
.aro-table-wrapper .aro-data-table-peripherals > .top-row > .search-group {
  line-height: 0px;
}
.aro-table-wrapper .aro-data-table-peripherals > .bottom-row {
  display: flex;
  flex-wrap: wrap;
}
.aro-table-wrapper .aro-data-table-peripherals > .bottom-row > * {
  margin-top: 10px;
  margin-right: 10px;
}
.aro-table-wrapper .aro-data-table-peripherals > .bottom-row > *:last-child {
  margin-right: 0px;
}
.aro-table-wrapper .aro-data-table-peripherals > .bottom-row .search-info,
.aro-table-wrapper .aro-data-table-peripherals > .bottom-row .filter-info {
  font-weight: 400;
  color: #232229;
  font-size: 0.875rem;
  padding-right: 12px;
  background-color: #232229;
  color: #fff;
  border-radius: 6px;
  user-select: none;
  opacity: 0;
  position: absolute;
  display: none;
  visibility: hidden;
  transform: scale(0.9);
  transition: visibility 0.2s ease, opacity 0.2s ease, transform 0.2s ease;
}
.aro-table-wrapper .aro-data-table-peripherals > .bottom-row .search-info.active,
.aro-table-wrapper .aro-data-table-peripherals > .bottom-row .filter-info.active {
  opacity: 100;
  transform: scale(1);
  visibility: visible;
  position: static;
  display: block;
}
.aro-table-wrapper .aro-data-table-peripherals > .bottom-row .search-info .clear-search,
.aro-table-wrapper .aro-data-table-peripherals > .bottom-row .filter-info .clear-search,
.aro-table-wrapper .aro-data-table-peripherals > .bottom-row .search-info .clear-filters,
.aro-table-wrapper .aro-data-table-peripherals > .bottom-row .filter-info .clear-filters {
  padding: 6px 12px;
  display: inline-block;
  cursor: pointer;
  transition: color 0.2s ease;
}
.aro-table-wrapper .aro-data-table-peripherals > .bottom-row .search-info .clear-search:hover,
.aro-table-wrapper .aro-data-table-peripherals > .bottom-row .filter-info .clear-search:hover,
.aro-table-wrapper .aro-data-table-peripherals > .bottom-row .search-info .clear-filters:hover,
.aro-table-wrapper .aro-data-table-peripherals > .bottom-row .filter-info .clear-filters:hover {
  color: rgba(255, 255, 255, 0.7);
}
.aro-table-wrapper .aro-data-table-peripherals > .bottom-row .filter-info {
  background-color: #00A3E0;
  color: #fff;
}
.aro-table-wrapper .aro-table-container {
  width: 100%;
  max-height: 500px;
  overflow-x: scroll;
  position: relative;
}
.aro-table-wrapper .aro-table-container.loading {
  min-height: 400px;
  overflow-y: hidden;
  overflow-x: hidden;
}
.aro-table-wrapper .aro-table-container.loading > .ui.table.true::before {
  content: '';
  position: absolute;
  top: 0px;
  background-color: rgba(255, 255, 255, 0.6);
  height: 100%;
  min-height: 400px;
  width: 100%;
  z-index: 99;
}
.aro-table-wrapper .aro-table-container .table-loader {
  position: absolute;
  top: 0px;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}
.aro-table-wrapper .aro-table-container .table-loader > .loader {
  margin: 0 auto;
  width: 200px;
  position: relative;
}
.aro-table-wrapper .aro-table-container .table-loader > .loader::after {
  content: 'Fetching data...';
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: 20px;
}
.aro-table-wrapper .aro-table-footer {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.aro-table-wrapper .aro-table-footer > .pagination-info {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
}
.aro-table-wrapper .aro-table-footer > .mobile-pagination-info {
  display: none;
}
.aro-table-wrapper .aro-table-footer .ui.loader {
  margin-left: auto;
}
.aro-table-wrapper .aro-table-footer .aro-pagination > ul {
  list-style-type: none;
  margin: 0px;
  color: #727380;
  padding-left: 0px;
}
.aro-table-wrapper .aro-table-footer .aro-pagination > ul li {
  float: left;
  display: block;
  text-align: center;
  padding: 4px 12px;
  margin: 0px 2px;
  border-radius: 4px;
  text-decoration: none;
  color: #727380;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}
.aro-table-wrapper .aro-table-footer .aro-pagination > ul li:hover {
  background-color: #EAEBEF;
  color: #39449C;
}
.aro-table-wrapper .aro-table-footer .aro-pagination > ul li.active {
  background-color: #39449C;
  color: #fff;
}
.aro-table-wrapper .aro-table-footer .aro-pagination > ul li.arrow {
  padding: 4px 8px;
}
.aro-table-wrapper .aro-table-footer .aro-pagination > ul li.disabled:hover {
  background-color: initial;
  cursor: default;
}
.aro-table-wrapper.force-mobile.mobile-ready table,
.aro-table-wrapper.force-mobile.mobile-ready .aro-table-container {
  display: none;
}
.aro-table-wrapper.force-mobile .aro-data-table-peripherals > .top-row > .button-group .ui.compact.menu,
.aro-table-wrapper.force-mobile .aro-data-table-peripherals > .top-row > .button-group .ui.compact.dropdown {
  display: none;
}
.aro-table-wrapper.force-mobile .aro-table-footer > .pagination-info {
  display: none;
}
.aro-table-wrapper.force-mobile .aro-table-footer > .mobile-pagination-info {
  display: block;
}
.aro-table-wrapper.force-mobile .aro-table-footer .aro-pagination > ul li:not(.arrow) {
  display: none;
}
@media screen and (max-width: 480px) {
  .aro-table-wrapper.mobile-ready table,
  .aro-table-wrapper.mobile-ready .aro-table-container {
    display: none;
  }
  .aro-table-wrapper .aro-data-table-peripherals > .top-row > .button-group .ui.compact.menu,
  .aro-table-wrapper .aro-data-table-peripherals > .top-row > .button-group .ui.compact.dropdown {
    display: none;
  }
  .aro-table-wrapper .aro-table-footer > .pagination-info {
    display: none;
  }
  .aro-table-wrapper .aro-table-footer > .mobile-pagination-info {
    display: block;
  }
  .aro-table-wrapper .aro-table-footer .aro-pagination > ul li:not(.arrow) {
    display: none;
  }
}
.aro-filter-row .delete-button {
  text-align: center;
  padding: 5px;
  cursor: pointer;
  font-size: 18px;
  transition: color 0.2s ease;
}
.aro-filter-row .delete-button:hover {
  color: #727380;
}
.aro-contacts-wrapper {
  border-radius: 8px;
  border: 1px solid #EAEBEF;
  background-color: #fff;
  padding: 10px 18px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.aro-contacts-wrapper.variation2 {
  border-top: none;
  border-bottom: 1px solid #EAEBEF;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  padding: 16px 0px;
  margin-bottom: 0px;
}
.aro-contacts-wrapper.variation2:last-child {
  border-bottom: none;
}
.aro-contacts-wrapper .contact {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  margin-right: 12px;
  width: 0;
  min-width: 0;
}
.aro-contacts-wrapper .contact .name {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
.aro-contacts-wrapper .contact .detail {
  display: inline;
  font-size: 0.875rem;
  color: #65666B;
}
.aro-contacts-wrapper .contact .detail:after {
  content: "\A";
  white-space: pre;
}
.aro-contacts-wrapper .contact .detail i {
  margin-right: 10px;
}
.aro-contacts-wrapper .actions {
  display: flex;
  flex: 0 0 30px;
  flex-direction: column;
}
.aro-popup-card {
  opacity: 0;
  visibility: hidden;
  transform: scale(0.8);
  transition: transform 0.2s ease, opacity 0.2s ease, visibility 0.2s ease;
  position: sticky !important;
  top: 0px;
}
.aro-popup-card .close {
  position: absolute;
  padding: 12px;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.aro-popup-card .close:hover {
  color: #65666B;
}
.aro-popup-card.active {
  opacity: 1;
  transform: scale(1);
  visibility: visible;
}
.aro-pdf-viewer {
  display: flex;
  flex-direction: column;
}
.aro-pdf-viewer.ui.modal > .close {
  top: 18px !important;
  font-size: 26px !important;
}
@media screen and (max-width: 768px) {
  .aro-pdf-viewer.ui.modal > .close {
    top: 18px !important;
    font-size: 26px !important;
  }
}
.aro-pdf-viewer .aro-pdf-content {
  flex: 1 1 auto;
  align-self: stretch;
  position: relative;
}
.aro-photos-group {
  display: grid;
  grid-gap: 6px;
  grid-template-columns: repeat(auto-fill, 78px);
}
.aro-photos-group > .photo {
  width: 78px;
  height: 78px;
  border-radius: 8px;
  border: 2px solid #000;
  background-color: #fff;
  overflow: hidden;
  margin: 2px;
}
.clickable.aro-photos-group > .photo {
  cursor: pointer;
  transition: border-color 0.3s ease;
}
.clickable.aro-photos-group > .photo:hover {
  border-color: #00A3E0;
}
.aro-photos-group > .photo > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}
.aro-companies-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 3fr));
  column-gap: 20px;
  row-gap: 20px;
  align-items: start;
}
.aro-companies-grid .aro-company-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.aro-companies-grid .aro-company-picker:hover .image,
.aro-companies-grid .aro-company-picker.active .image {
  border: 2px solid #00A3E0;
}
.aro-companies-grid .aro-company-picker:hover .name,
.aro-companies-grid .aro-company-picker.active .name {
  color: #00A3E0;
}
.aro-companies-grid .aro-company-picker .name {
  margin-top: 17px;
  text-align: center;
  color: #39393F;
  transition: color 0.2s ease;
}
.aro-companies-grid .aro-company-picker .image {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #232229;
  background-color: #fff;
  height: 100px;
  width: 100px;
  padding: 10px;
  transition: border-color 0.2s ease;
}
.aro-companies-grid .aro-company-picker .image > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.aro-assignee-group {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
}
.aro-assignee-group .assignee {
  display: flex;
  column-gap: 14px;
  align-items: center;
}
.aro-assignee-group .assignee > .icon {
  background-color: #DEDFE7;
  border-radius: 200px;
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  flex: 0 0 26px;
}
.aro-assignee-group .assignee > .details-group {
  flex: 1 1 auto;
  line-height: 1.1em;
}
.aro-assignee-group .assignee > .details-group .name {
  font-size: 16px;
  font-weight: 500;
  color: #232229;
}
.aro-assignee-group .assignee > .details-group .email {
  font-size: 0.875rem;
  font-weight: 400;
  color: #65666B;
}
.aro-event-logs .event-log {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
}
.aro-event-logs .event-log:hover {
  background-color: #f3f3f6;
}
.aro-event-logs .event-log .details {
  flex: 1 1 auto;
}
.aro-event-logs .event-log .details .name {
  color: #232229;
  font-weight: 500;
  font-size: 16px;
}
.aro-event-logs .event-log .details .date,
.aro-event-logs .event-log .details .time {
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  line-height: 1.1em;
}
.aro-event-logs .event-log .duration-wrapper {
  flex: 0 0 auto;
}
.aro-event-logs .no-logs {
  padding: 20px;
  text-align: center;
}
.aro-event-logs .no-logs i {
  font-size: 24px;
  color: #727380;
  margin-bottom: 8px;
}
.aro-event-logs .no-logs p {
  font-size: 14px;
  font-weight: 500;
  color: #727380;
}
.aro-buttons-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 2px;
}
.aro-buttons-group .aro.button {
  margin-right: 0px;
}
.aro-table-mobile-container .description {
  word-break: break-word;
}
.aro-small-widget-wrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: 1fr;
  gap: 20px;
}
@media screen and (max-width: 1200px) {
  .aro-small-widget-wrapper {
    grid-template-columns: minmax(0, 1fr);
  }
}
.aro-large-widget-wrapper {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: 1fr;
  column-gap: 20px;
  row-gap: 20px;
}
@media screen and (max-width: 1400px) {
  .aro-large-widget-wrapper {
    grid-template-columns: minmax(0, 1fr);
  }
}
.aro-stars-wrapper {
  margin-top: 20px;
  overflow: hidden;
}
.aro-stars-wrapper img {
  width: 109px;
}
.aro-recommend-bar-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
}
.aro-recommend-bar-wrapper .recommend-bar {
  flex: 1 1 100%;
  height: 7px;
  border-radius: 100px;
  background: linear-gradient(90deg, #FF0000 -4.5%, #FFE920 49.38%, #00B612 100%);
}
.aro-recommend-bar-wrapper .recommend-bar .caret {
  transition: transform 0.4s ease-out;
}
.aro-recommend-bar-wrapper .recommend-percentage {
  font-size: 0.875em;
  color: rgba(35, 34, 41, 0.8);
  font-weight: 500;
}
.aro-daterange-picker-wrapper {
  position: relative;
}
.aro-daterange-picker-wrapper .daterange-picker {
  display: flex;
  padding: 10px 20px;
  color: #232229;
  gap: 20px;
  border: 1px solid #65666B;
  border-radius: 6px;
  user-select: none;
  cursor: pointer;
}
.aro-daterange-picker-wrapper .daterange-picker:hover,
.aro-daterange-picker-wrapper .daterange-picker.active {
  background-color: #fff;
  border: 1px solid #001871;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
}
.aro-daterange-picker-wrapper .daterange-picker .arrow {
  transition: transform 0.2s ease;
}
.aro-daterange-picker-wrapper .daterange-picker.active .arrow {
  transform: rotate(90deg);
}
.aro-datepicker-module {
  position: absolute;
  z-index: 100;
  right: 0px;
  top: 105%;
  border-radius: 16px;
  border: 1px solid #EAEBEF;
  box-shadow: 0px 4px 20px rgba(60, 61, 73, 0.08);
  background-color: #fff;
  cursor: default;
  opacity: 1;
  transition: opacity 0.2s ease, transform 0.2s ease-out;
}
.aro-datepicker-module.visuallyhidden {
  opacity: 0;
  transform: translateY(30px);
}
.aro-datepicker-module.hidden {
  display: none;
}
.aro-datepicker-module .options-list {
  list-style: none;
  padding: 0px;
  margin: 0px;
  padding: 17px 0px;
}
.aro-datepicker-module .options-list li {
  padding: 8px 30px;
  cursor: pointer;
}
.aro-datepicker-module .options-list li:hover {
  background-color: #f3f3f6;
}
.aro-datepicker-module .options-list li:last-child {
  border-top: 1px solid #EAEBEF;
}
.aro-datepicker-module .datepickers {
  padding: 17px;
  text-align: center;
}
.aro-datepicker-module .datepickers .calendar-wrap .header-title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
}
.aro-datepicker-module .datepickers .calendar-wrap .calendar .link:not(.disabled):hover {
  background-color: #f3f3f6;
}
.aro-datepicker-module .datepickers .lowkey-button {
  border: none;
  background-color: transparent;
  font-size: 0.875em;
  color: #65666B;
  margin-top: 12px;
  cursor: pointer;
}
.aro-datepicker-module .datepickers .lowkey-button:hover {
  color: #39393F;
}
.aro-datepicker-module .fade-left-enter-active,
.aro-datepicker-module .fade-left-leave-active {
  transition: opacity 0.1s ease, transform 0.1s ease;
}
.aro-datepicker-module .fade-left-enter-from {
  opacity: 0;
  transform: translateX(50px);
}
.aro-datepicker-module .fade-left-leave-to {
  opacity: 0;
  transform: translateX(-50px);
}
.aro-activitylog-form-listing {
  display: flex;
  padding: 24px 20px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  transition: background-color 0.2s ease;
}
.aro-activitylog-form-listing.border-top {
  border-top: 1px solid #EAEBEF;
}
.aro-activitylog-form-listing:hover {
  background-color: #f3f3f6;
}
.aro-activitylog-form-listing .arrow {
  transition: transform 0.2s ease;
}
.aro-activitylog-form-listing.active .arrow {
  transform: rotate(90deg);
}
.aro-activitylog-form-listing.active::after {
  content: '';
  height: 25px;
  width: 4px;
  border-radius: 0px 4px 4px 0px;
  position: absolute;
  left: 0px;
  background-color: #39449C;
}
.aro-bar-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
}
.aro-bar-wrapper .bar-outer {
  flex: 1 1 100%;
  background-color: #D9D9D9;
  border-radius: 100px;
  height: 7px;
  overflow: hidden;
}
.aro-bar-wrapper .bar-outer .bar-inner {
  width: 100%;
  height: 100%;
  background-color: #FFBF1C;
  border-radius: 0px 100px 100px 0px;
  transition: transform 0.4s ease-out;
}
.aro-bar-wrapper .question,
.aro-bar-wrapper .score-value {
  flex: 0 1 auto;
  font-size: 0.875em;
  font-weight: 500;
  color: rgba(35, 34, 41, 0.8);
}
.aro-layout-defects {
  display: grid;
  grid-template-columns: 2fr minmax(0, 1fr);
  gap: 20px;
}
@media screen and (max-width: 1200px) {
  .aro-layout-defects {
    grid-template-columns: minmax(0, 1fr);
  }
}
.aro-layout-defects > .widgets-area {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
  align-items: start;
}
@media screen and (max-width: 1200px) {
  .aro-layout-defects > .widgets-area {
    grid-row: 2;
  }
}
.aro-layout-defects .summary-area .summary-card {
  position: sticky;
  top: 0px;
}
@media screen and (max-width: 1200px) {
  .aro-layout-defects .summary-area {
    grid-row: 1;
  }
}
.aro-chart-container {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}
.aro-chart-container .legend-wrapper {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
}
.aro-chart-container .chart-wrapper {
  min-height: 160px;
  flex: 0 0 170px;
  overflow: hidden;
}
@media screen and (max-width: 1030px) {
  .aro-chart-container {
    flex-wrap: wrap-reverse;
    justify-content: center;
    row-gap: 20px;
    margin-bottom: 30px;
  }
}
.aro-dashboard-title-bar {
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.aro-dashboard-title-bar .heading-wrapper {
  display: flex;
  align-items: center;
  column-gap: 24px;
}
.aro-dashboard-title-bar .heading-wrapper > .title {
  margin-top: 0px;
  font-size: 24px;
}
.aro-dashboard-title-bar .heading-wrapper .image-wrapper {
  height: 74px;
  width: 74px;
  padding: 5px;
  border-radius: 100%;
  overflow: hidden;
}
.aro-dashboard-title-bar .heading-wrapper .image-wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.aro-shortcuts-bar {
  display: flex;
  column-gap: 15px;
  row-gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0px 20px;
}
.aro-shortcuts-bar p {
  margin-bottom: 0px;
}
.aro-shortcuts-bar .shortcut {
  background-color: #00A3E0;
  color: #fff;
  display: flex;
  column-gap: 6px;
  align-items: center;
  border-radius: 20px;
  padding: 10px 20px;
  flex: 0 0 auto;
  transition: 0.2s background-color ease;
}
.aro-shortcuts-bar .shortcut:hover {
  background-color: #007ead;
}
.aro-app-listing {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.aro-app-listing .app-wrapper {
  flex: 0 0 217px;
  min-height: 189px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #EAEBEF;
  transition: all 0.2s ease;
}
.aro-app-listing .app-wrapper:hover {
  border-color: #00A3E0;
}
.aro-app-listing .app-wrapper .icon-container {
  font-size: 24px;
  background-color: #00A3E0;
  color: #fff;
  border-radius: 100%;
  padding: 15px;
}
.aro-app-listing .app-wrapper .title {
  margin-top: 30px;
  color: #39393F;
}
.aro-eod-picker-widget {
  border-radius: 10px;
  background-color: #fff;
  padding: 10px 30px 10px 20px;
  border: 1px solid #EAEBEF;
  display: flex;
  gap: 20px;
  flex-wrap: nowrap;
  align-items: center;
}
.aro-eod-picker-widget > .calendar {
  color: #39449C;
  font-size: 24px;
}
.aro-eod-picker-widget > .date-block .date {
  font-size: 1em;
  font-weight: 600;
  color: #39449C;
}
.aro-eod-picker-widget > .date-block .time {
  font-size: 0.875em;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
}
.aro-trip-column-wrap {
  margin-top: 20px;
}
.aro-trip-column-wrap .trip-listing {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 20px;
}
.aro-trip-events-listing > .trip-event {
  padding: 20px 0px;
  border-bottom: 1px solid #EAEBEF;
}
.aro-trip-events-listing > .trip-event:last-child {
  border-bottom: none;
}
.aro-trip-events-listing > .trip-event .heading-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
}
.aro-trip-events-listing > .trip-event .heading-wrap > .title {
  color: #232229;
  font-size: 1em;
  margin-bottom: 0;
}
.aro-trip-events-listing > .trip-event .heading-wrap > .label {
  color: #B35C05;
  font-size: 0.875em;
}
.aro-trip-events-listing > .trip-event .meta {
  display: flex;
  column-gap: 6px;
  font-size: 0.875em;
  color: rgba(0, 0, 0, 0.7);
  align-items: center;
}
.aro-trip-events-listing > .trip-event .daterange {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}
.aro-trip-events-listing > .trip-event .daterange .date-wrap > .date {
  color: #65666B;
  font-weight: 500;
  font-size: 0.875em;
}
.aro-trip-events-listing > .trip-event .daterange .date-wrap > .day {
  color: #232229;
  font-size: 1.5em;
}
.aro-trip-transactions-listing .trip-transaction {
  padding: 20px;
  border-bottom: 1px solid #EAEBEF;
}
.aro-trip-transactions-listing .trip-transaction:last-child {
  border-bottom: none;
}
.aro-trip-transactions-listing .trip-transaction > .heading {
  color: #232229;
  display: flex;
  align-items: center;
  column-gap: 5px;
  margin-bottom: 10px;
}
.aro-trip-transactions-listing .trip-transaction > .heading .title {
  font-size: 1em;
  font-weight: 600;
  margin-top: 0px;
}
.aro-trip-transactions-listing .trip-transaction > .heading i {
  font-size: 1.25em;
}
.aro-trip-transactions-listing .trip-transaction .divider {
  margin: 10px 0px;
  border-bottom: 2px dashed #EAEBEF;
}
.aro-trip-transactions-listing .grand-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
  border-top: 2px solid #EAEBEF;
  font-weight: 600;
  color: #232229;
}
.aro-trip-expenses-listing .trip-expense {
  padding: 20px;
  border-bottom: 1px solid #EAEBEF;
}
.aro-trip-expenses-listing .trip-expense:last-child {
  border-bottom: none;
}
.aro-trip-expenses-listing .trip-expense > .title {
  color: #232229;
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 5px;
}
.aro-trip-corporate-expense-widget > .header.trip {
  color: #006B57 !important;
}
.aro-trip-corporate-expense-widget > .header.trip i {
  font-size: 1.125em;
  margin-right: 5px;
}
.aro-trip-corporate-expense-widget .airline-img {
  max-height: 30px;
}
.aro-trip-corporate-expense-widget .to-and-from {
  display: grid;
  grid-template-columns: 1fr 16px 1fr;
  column-gap: 10px;
  align-items: center;
}
.aro-trip-corporate-expense-widget .to-and-from .divider {
  color: #65666B;
}
.aro-trip-corporate-expense-widget .cost-wrapper {
  padding-top: 20px;
  border-top: 2px dashed #EAEBEF;
}
.aro-trip-corporate-expense-widget .cost-wrapper .cost-detail-wrapper {
  display: flex;
  align-items: top;
  justify-content: space-between;
  margin-top: 10px;
}
.aro-trip-corporate-expense-widget .cost-wrapper .cost-detail-wrapper > .title {
  font-size: 14px;
  font-weight: 500;
  color: #65666B;
}
.aro-trip-corporate-expense-widget .cost-wrapper .cost-detail-wrapper > .details {
  text-align: right;
}
.aro-trip-corporate-expense-widget .cost-wrapper .cost-detail-wrapper > .details .diff {
  font-weight: 400;
  font-size: 0.875em;
}
.aro-trip-corporate-expense-widget .cost-wrapper .cost-detail-wrapper > .details .diff.positive {
  color: #FF331F;
}
.aro-trip-corporate-expense-widget .cost-wrapper .cost-detail-wrapper > .details .diff.negative {
  color: #006B57;
}
.aro-trip-corporate-expense-widget .cost-wrapper .cost-detail-wrapper > .details .cost {
  font-weight: 600;
  color: #232229;
  font-size: 1em;
}
.aro-trip-corporate-expense-widget .daterange {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}
.aro-trip-corporate-expense-widget .daterange .date-wrap > .title {
  color: #65666B;
  font-weight: 500;
  font-size: 0.875em;
}
.aro-trip-corporate-expense-widget .daterange .date-wrap > .date {
  color: #232229;
  font-weight: 500;
  font-size: 1em;
}
.aro-trip-corporate-expense-widget .daterange .date-wrap > .time {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
  font-size: 1em;
}
.aro-trip-corporate-expense-widget .content.address-block {
  padding: 0px;
}
.aro-trip-daterange {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}
.aro-trip-daterange .date-wrap > .date {
  color: #65666B;
  font-weight: 500;
  font-size: 0.875em;
}
.aro-trip-daterange .date-wrap > .day {
  color: #006B57;
  font-size: 1.5em;
}
.aro-trip-transactions-table-listing {
  padding: 20px;
  border: 1px solid #EAEBEF;
}
.aro-trip-transactions-table-listing:last-child {
  border-bottom: none;
}
.aro-trip-transactions-table-listing > .content {
  display: flex;
  column-gap: 10px;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}
.aro-trip-transactions-table-listing > .content > .header {
  display: flex;
  flex-direction: column;
}
.aro-trip-transactions-table-listing > .content > .header .title {
  color: #232229;
  font-size: 1em;
  font-weight: 600;
}
.aro-trip-transactions-table-listing > .content > .header .trip,
.aro-trip-transactions-table-listing > .content > .header .card {
  font-size: 0.875em;
  color: #65666B;
  font-weight: 500;
}
.aro-trip-transactions-table-listing > .content > .meta {
  flex: 0 0 auto;
  text-align: right;
}
.aro-trip-transactions-table-listing > .content > .meta .date {
  color: #65666B;
  font-weight: 500;
  font-size: 0.875em;
}
.aro-trip-transactions-table-listing > .content > .meta .cost {
  font-size: 1.2em;
  color: #006B57;
  font-weight: 600;
}
.aro-trip-transactions-table-listing .aro.detail {
  padding: 5px 0px;
}
.aro-trip-transactions-table-listing .aro.detail:last-child {
  border-top: 1px solid #EAEBEF;
}
.aro-trip-review-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 375px));
  grid-auto-rows: 20px;
  column-gap: 20px;
  row-gap: 20px;
  align-items: start;
  /*
    Container query support not yet available for less
    So we have to specify the full class target again to make it work with the mixin properly
  */
}
.aro-costsaves-monthly-totals .totals-wrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  gap: 20px;
}
@media screen and (max-width: 1400px) {
  .aro-costsaves-monthly-totals .totals-wrapper {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }
}
@media screen and (max-width: 1030px) {
  .aro-costsaves-monthly-totals .totals-wrapper {
    grid-template-columns: 1fr;
  }
}
.aro-costsaves-monthly-totals .totals-wrapper .table-content {
  max-width: 100%;
  overflow-x: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
}
.aro-costsaves-monthly-totals .totals-wrapper .table-content::-webkit-scrollbar {
  display: none;
}
.aro-costsaves-monthly-totals .totals-wrapper table {
  width: 100%;
  border-collapse: collapse;
}
.aro-costsaves-monthly-totals .totals-wrapper table th.heading,
.aro-costsaves-monthly-totals .totals-wrapper table td.heading {
  text-align: left;
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1;
  color: rgba(35, 34, 41, 0.7);
}
.aro-costsaves-monthly-totals .totals-wrapper table th,
.aro-costsaves-monthly-totals .totals-wrapper table td:not(.heading) {
  padding: 15px 5px;
  border-bottom: 1px solid #EAEBEF;
}
.aro-costsaves-monthly-totals .totals-wrapper table tbody tr:last-child td {
  border-bottom: none;
}
.aro-calendar-event-tooltip {
  display: none;
  z-index: 100;
  max-width: 350px;
  width: 100%;
  max-height: 70vh;
  overflow: auto !important;
}
.aro-calendar-event-tooltip[data-show] {
  display: block;
}
.aro-calendar-event-tooltip .options-bar {
  padding: 10px 20px 0px;
  text-align: right;
}
.aro-calendar-event-tooltip .arrow,
.aro-calendar-event-tooltip .arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}
.aro-calendar-event-tooltip .arrow {
  visibility: hidden;
}
.aro-calendar-event-tooltip .arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}
.aro-calendar-event-tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
}
.aro-calendar-event-tooltip[data-popper-placement^='bottom'] > #arrow {
  top: -4px;
}
.aro-calendar-event-tooltip[data-popper-placement^='left'] > #arrow {
  right: -4px;
}
.aro-calendar-event-tooltip[data-popper-placement^='right'] > #arrow {
  left: -4px;
}
.aro-calendar-event-tooltip.tentative::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 25px;
  opacity: 0.5;
  top: 0px;
  left: 0px;
  background: repeating-linear-gradient(-45deg, #000, #000 10px, transparent 10px, transparent 20px);
  z-index: 0;
}
.aro-full-calendar .aro-calendar-event {
  cursor: pointer;
  gap: 4px;
}
.aro-full-calendar .aro-calendar-event .fc-event-time {
  flex: 0 0 auto;
}
.aro-full-calendar a.aro-calendar-event.tentative {
  padding-left: 20px;
}
.aro-full-calendar a.aro-calendar-event.tentative::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 100%;
  top: 0px;
  left: 0px;
  background: repeating-linear-gradient(-45deg, #000, #000 4px, transparent 5px, transparent 8px);
}
.aro-full-calendar a.aro-calendar-event.bordered {
  padding-left: 20px;
}
.aro-full-calendar a.aro-calendar-event.bordered::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #39393F;
}
.aro-full-calendar a.aro-calendar-event.category-1::before {
  background-color: #00A3E0;
}
.aro-full-calendar a.aro-calendar-event.category-2::before {
  background-color: #099455;
}
.aro-full-calendar a.aro-calendar-event.category-3::before {
  background-color: #B35C05;
}
.aro-full-calendar a.aro-calendar-event.category-4::before {
  background-color: #232229;
}
.aro-full-calendar a.aro-calendar-event.category-5::before {
  background-color: #B01B0C;
}
.aro-trip-planner .sites-wrapper {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.aro-trip-planner .sites-wrapper .site {
  border-top: 1px solid #EAEBEF;
  padding: 15px 0px;
}
.aro-trip-planner .sites-wrapper .site > .content {
  padding: 0px 20px 10px 0px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.aro-trip-planner .sites-wrapper .site > .content .dragger {
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 50%;
  font-weight: 600;
}
.aro-trip-planner .sites-wrapper .site > .content .dragger:hover {
  color: #232229;
}
.aro-trip-planner .sites-wrapper .site > .content > button {
  margin-left: auto;
}
.aro-trip-planner .sites-wrapper .site > .content .search-block {
  width: 100%;
}
.aro-trip-planner .sites-wrapper .site > .content .search-block .input {
  width: 100%;
}
.aro-trip-planner .sites-wrapper .site > .content .search-block input {
  font-family: 'Inter', sans-serif;
  border: 1px solid #DEDFE7;
  border-radius: 6px !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  padding: 10px 14px;
  background: #fff;
  font-size: 14px;
  width: 100%;
}
.aro-trip-planner .sites-wrapper .site > .content .search-block input:focus {
  border: 1px solid #39449C;
  background-color: #f3f4fb;
  box-shadow: 0px 1px 4px rgba(2, 9, 34, 0.12);
}
.aro-trip-planner .sites-wrapper .site > .content .search-block input:focus-visible {
  outline: none;
}
.aro-trip-planner .sites-wrapper .site > .meta {
  margin-left: 60px;
  font-size: 0.875em;
  display: flex;
  gap: 10px;
}
.aro-trip-planner .sites-wrapper .site > .meta .title {
  font-weight: 600;
  color: #232229;
}
.aro-trip-planner .sites-wrapper .site > .meta .description {
  color: #39393F;
}
.aro-trip-planner .sites-wrapper .site.dragging .dragger {
  cursor: grabbing;
}
.aro-trip-planner .trip-overview {
  padding: 10px 20px;
  border-top: 1px solid #EAEBEF;
  border-bottom: 1px solid #EAEBEF;
  border-left: 4px solid #00A3E0;
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.aro-trip-planner .trip-overview .icon {
  font-size: 1.5em;
  color: #232229;
}
.aro-trip-planner .trip-overview .content {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.aro-trip-planner .trip-overview .content .title {
  font-weight: 600;
  color: #232229;
}
.aro-trip-planner .trip-overview .content .description {
  color: #39393F;
  font-size: 0.875em;
}
.aro-trip-planner .button-block {
  border-top: 1px solid #EAEBEF;
  padding: 20px;
  padding-bottom: 30px;
}
.aro-site-info-window {
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  padding: 20px 10px;
}
.aro-site-info-window .header .title {
  font-weight: 600;
  color: #232229;
  font-size: 1.25em;
  margin-bottom: 10px;
}
.aro-site-info-window .header .description {
  color: #39393F;
  font-size: 0.875em;
}
.aro-site-info-window .meta-block {
  display: flex;
  gap: 30px;
  color: #001871;
  font-size: 0.875em;
  font-weight: 500;
  margin-top: 20px;
}
.aro-site-info-window .meta-block .meta {
  display: flex;
  gap: 5px;
}
.aro-site-info-window .meta-block .meta .icon {
  font-size: 1.25em;
}
.aro-site-info-window .meta-block .meta p {
  font-weight: 500;
}
.aro-site-info-window .upcoming-event-block {
  margin-top: 30px;
  border: 1px solid #B35C05;
  border-radius: 10px;
  padding: 15px 20px;
  color: #B35C05;
}
.aro-site-info-window .upcoming-event-block .title {
  font-size: 0.875em;
  margin-bottom: 5px;
}
.aro-site-info-window .upcoming-event-block .description {
  margin-top: 0px;
  font-weight: 600;
  font-size: 1.25em;
}
.aro-site-info-window .last-event-block {
  display: flex;
  padding-left: 10px;
  font-size: 0.875em;
  margin-top: 10px;
  gap: 5px;
}
.aro-site-info-window .last-event-block .title {
  color: #39393F;
}
.aro-site-info-window .last-event-block .description {
  font-weight: 500;
  color: #232229;
}
.aro-site-info-window .actions {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.aro-site-secondary-info-window {
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  padding: 20px 0px;
}
.aro-site-secondary-info-window .header {
  padding: 0px 20px;
}
.aro-site-secondary-info-window .header > .title {
  font-weight: 600;
  color: #232229;
  font-size: 1.25em;
  margin-bottom: 10px;
}
.aro-site-secondary-info-window .header .description {
  color: #39393F;
  font-size: 0.875em;
}
.aro-site-secondary-info-window .meta-block {
  display: flex;
  gap: 30px;
  color: #001871;
  font-size: 0.875em;
  font-weight: 500;
  margin-top: 20px;
  padding: 0px 20px;
}
.aro-site-secondary-info-window .meta-block .meta {
  display: flex;
  gap: 5px;
}
.aro-site-secondary-info-window .meta-block .meta .icon {
  font-size: 1.25em;
}
.aro-site-secondary-info-window .meta-block .meta p {
  font-weight: 500;
}
.aro-site-secondary-info-window .upcoming-event-block {
  margin-top: 30px;
  border: 1px solid #B35C05;
  border-radius: 10px;
  padding: 15px 20px;
  color: #B35C05;
}
.aro-site-secondary-info-window .upcoming-event-block .title {
  font-size: 0.875em;
  margin-bottom: 5px;
}
.aro-site-secondary-info-window .upcoming-event-block .description {
  margin-top: 0px;
  font-weight: 600;
  font-size: 1.25em;
}
.aro-site-secondary-info-window .last-event-block {
  display: flex;
  padding-left: 10px;
  font-size: 0.875em;
  margin-top: 10px;
  gap: 5px;
}
.aro-site-secondary-info-window .last-event-block .title {
  color: #39393F;
}
.aro-site-secondary-info-window .last-event-block .description {
  font-weight: 500;
  color: #232229;
}
.aro-site-secondary-info-window .actions {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.aro-map-overlay {
  text-shadow: 2px 2px 0px #ffffff, -2px 2px 0px #ffffff, 2px -2px 0px #ffffff, -2px -2px 0px #ffffff;
}
.aro-map-control-button {
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  color: #191919;
  cursor: pointer;
  font-family: Roboto, Arial, sans-serif;
  font-size: 16px;
  line-height: 38px;
  margin: 8px 0 22px;
  padding: 0 10px;
  text-align: center;
}
.aro-map-control-button:hover {
  background-color: #ebebeb;
  border-color: #ebebeb;
}
.aro-header-block > .meta-wrapper {
  display: flex;
  gap: 10px 40px;
  flex-wrap: wrap;
  padding: 15px 30px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px 0px rgba(60, 61, 73, 0.12);
  background-color: #fff;
}
.aro-header-block > .meta-wrapper > .meta {
  display: flex;
  gap: 10px;
}
.aro-header-block > .meta-wrapper > .meta .icon {
  font-size: 1em;
  color: #00C19F;
}
.aro-header-block > .meta-wrapper > .meta .content {
  display: flex;
  font-size: 0.875em;
  gap: 5px;
}
.aro-header-block > .meta-wrapper > .meta .content .title {
  color: #727380;
}
.aro-header-block > .meta-wrapper > .meta .content .description {
  color: #39393F;
  font-weight: 500;
}
.aro-login-basic-screen {
  background: linear-gradient(0deg, rgba(0, 24, 113, 0.71), rgba(0, 24, 113, 0.71)), url('../img/aro-holding-phone.jpg') center right / cover no-repeat;
}
.aro-basic-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 24px;
}
.aro-basic-screen .aro-access-denied-block {
  max-width: 415px;
  color: #232229;
}
.aro-basic-screen .aro-access-denied-block h1.header.basic {
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 0px;
}
.aro-basic-screen .aro-access-denied-block p {
  font-size: 0.875em;
  color: #65666B;
}
.aro-basic-screen .aro-access-denied-block .aro.card {
  padding: 50px 40px;
}
@media screen and (max-width: 480px) {
  .aro-basic-screen .aro-access-denied-block .aro.card {
    padding: 30px 5px;
  }
}
.aro-basic-screen .aro-access-denied-block .header-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #fff;
  margin: 0 auto;
  margin-bottom: 50px;
}
.aro-basic-screen .aro-access-denied-block .header-group img {
  max-width: 165px;
  height: auto;
}
.aro-basic-screen .aro-access-denied-block .header-group .heading {
  font-size: 1em;
  font-weight: 600;
  font-family: 'Montserrat', 'Inter', sans-serif;
}
.aro-basic-screen .footer-group {
  position: absolute;
  bottom: 30px;
  color: #fff;
  font-size: 0.875em;
}
.aro-ticket-create-card {
  max-width: 826px;
  margin: 0 auto;
  width: 100%;
}
.aro-ticket-show-card > .stepper-wrapper {
  display: flex;
  border-bottom: 1px solid #EAEBEF;
}
.aro-ticket-show-card > .stepper-wrapper > .step {
  position: relative;
  display: flex;
  flex: 1 1 0;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  color: #727380;
  font-size: 0.875em;
}
@media screen and (max-width: 1030px) {
  .aro-ticket-show-card > .stepper-wrapper > .step {
    font-size: 0.8em;
  }
}
.aro-ticket-show-card > .stepper-wrapper > .step > .icon {
  display: none;
}
.aro-ticket-show-card > .stepper-wrapper > .step.error {
  font-weight: 500;
  background-color: #FFC6C1;
  color: #B01B0C;
}
.aro-ticket-show-card > .stepper-wrapper > .step.active {
  font-weight: 500;
  background-color: #39449C;
  color: #fff;
}
.aro-ticket-show-card > .stepper-wrapper > .step.active > .icon {
  display: block;
}
@media screen and (max-width: 1030px) {
  .aro-ticket-show-card > .stepper-wrapper > .step.active > .icon {
    display: none;
  }
}
.aro-ticket-show-card > .stepper-wrapper > .step.current {
  border-top-right-radius: 20px 100%;
  border-bottom-right-radius: 20px 100%;
}
.aro-ticket-show-card > .header-wrapper {
  padding: 30px 20px;
  border-bottom: 1px solid #EAEBEF;
}
.aro-ticket-show-card > .header-wrapper > .header {
  display: flex;
  align-items: center;
  gap: 24px;
}
.aro-ticket-show-card > .header-wrapper > .header h1 {
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 0px;
}
.aro-ticket-show-card > .header-wrapper > .meta-wrapper {
  margin-top: 30px;
}
.aro-ticket-show-card > .header-wrapper > .meta-wrapper .primary {
  padding-bottom: 15px;
  border-bottom: 1px solid #EAEBEF;
}
.aro-ticket-show-card > .header-wrapper > .meta-wrapper .meta-list {
  display: flex;
  gap: 10px 40px;
  flex-wrap: wrap;
}
.aro-ticket-show-card > .header-wrapper > .meta-wrapper .meta-list > .meta-item {
  display: flex;
  gap: 10px;
}
.aro-ticket-show-card > .header-wrapper > .meta-wrapper .meta-list > .meta-item i {
  font-size: 1.2em;
  color: #00C19F;
}
.aro-ticket-show-card > .header-wrapper > .meta-wrapper .meta-list > .meta-item a:hover {
  text-decoration: underline;
}
.aro-ticket-show-card > .header-wrapper > .meta-wrapper .meta-list > .meta-item .content {
  font-size: 0.875em;
  color: #39393F;
  font-weight: 500;
}
.aro-ticket-show-card > .header-wrapper > .meta-wrapper .meta-list-discrete {
  display: flex;
  gap: 10px 40px;
  flex-wrap: wrap;
  margin-top: 15px;
}
.aro-ticket-show-card > .header-wrapper > .meta-wrapper .meta-list-discrete > .meta-item {
  display: flex;
  gap: 10px;
}
.aro-ticket-show-card > .header-wrapper > .meta-wrapper .meta-list-discrete > .meta-item i {
  font-size: 1em;
  color: #727380;
}
.aro-ticket-show-card > .header-wrapper > .meta-wrapper .meta-list-discrete > .meta-item .title {
  font-size: 0.875em;
  color: #727380;
}
.aro-ticket-show-card > .header-wrapper > .meta-wrapper .meta-list-discrete > .meta-item .content {
  font-size: 0.875em;
  color: #39393F;
  font-weight: 500;
}
.aro-ticket-show-card > .assignee-wrapper {
  background-color: #F8F8F8;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.aro-ticket-show-card > .assignee-wrapper > .title {
  flex-shrink: 0;
  font-size: 0.875em;
}
.aro-ticket-show-card > .assignee-wrapper.top-bordered {
  border-top: 1px solid #EAEBEF;
}
.aro-ticket-show-card > .content-wrapper {
  padding: 30px 20px;
}
.aro-ticket-show-card > .content-wrapper > .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #EAEBEF;
}
.aro-ticket-show-card > .content-wrapper > .header > .title {
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0px;
}
.aro-ticket-show-card > .content-wrapper > .header > .description {
  font-size: 0.875em;
  color: #65666B;
}
.aro-ticket-comment-create-card {
  position: relative;
  margin-top: 20px;
  overflow: visible !important;
}
.aro-ticket-comment-create-card::before {
  content: '';
  position: absolute;
  top: -20px;
  left: 0px;
  right: 0px;
  width: 2px;
  height: 20px;
  background-color: #A7A9B2;
  margin: 0 auto;
}
.aro-ticket-comment-create-card.aro.card > .header {
  background-color: #F8F8F8;
  padding: 10px 20px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}
.aro-ticket-comment-create-card.aro.card > .header > .title {
  font-size: 0.875em;
  font-weight: 500;
}
.aro-ticket-comment-card {
  position: relative;
  margin-top: 20px;
  overflow: visible !important;
}
.aro-ticket-comment-card::before {
  content: '';
  position: absolute;
  top: -20px;
  left: 0px;
  right: 0px;
  width: 2px;
  height: 20px;
  background-color: #A7A9B2;
  margin: 0 auto;
}
.aro-ticket-comment-card.aro.card > .header {
  background-color: #F8F8F8;
  padding: 10px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}
.aro-ticket-comment-card.aro.card > .header .column {
  display: flex;
  align-items: center;
  gap: 8px;
}
.aro-ticket-comment-card.aro.card > .header .column.right-aligned {
  justify-content: flex-end;
}
.aro-ticket-comment-card.aro.card > .header .column .title {
  font-size: 0.875em;
  font-weight: 500;
}
.aro-ticket-comment-card.aro.card > .header .column .description {
  font-size: 0.875em;
  color: #65666B;
}
.aro-attachments-wrapper > .title {
  color: #65666B;
  font-weight: 500;
  font-size: 0.875em;
}
.aro-attachments-wrapper > .attachment-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  margin-top: 12px;
}
.aro-attachments-wrapper > .attachment-list .attachment-list-item {
  background-color: #F8F8F8;
  padding: 10px 12px;
  border-radius: 6px;
  border: 1px solid #EAEBEF;
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 0.875em;
  cursor: pointer;
}
.aro-attachments-wrapper > .attachment-list .attachment-list-item:hover {
  border: 1px solid #DEDFE7;
}
.aro-ticket-meta {
  margin-top: 30px;
  margin-bottom: 50px;
}
.aro-ticket-meta .meta-group {
  font-size: 0.875em;
  display: flex;
  align-items: center;
  gap: 10px;
}
.aro-ticket-meta .meta-group > .title {
  color: #65666B;
}
.aro-ticket-meta .meta-group > .description {
  color: #232229;
  font-weight: 500;
}
.aro-ticket-form-listing {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 18px;
  padding-bottom: 18px;
  transition: background-color 0.2s ease;
  cursor: pointer;
}
.aro-ticket-form-listing:hover {
  background-color: #EAEBEF;
}
.aro-ticket-form-listing > .content {
  flex: 1 1 auto;
  padding: 0px 16px;
  color: #232229;
}
.aro-ticket-form-listing > .content .title {
  color: #232229;
  font-weight: 500;
}
.aro-ticket-form-listing > .content .description {
  font-size: 0.875rem;
  color: #65666B;
}
.aro-ticket-form-listing > .icon {
  flex-basis: 24px;
  font-size: 20px;
  color: #001871;
}
.aro-ticket-form-listing::before {
  content: '';
  height: 1px;
  background-color: #EAEBEF;
  position: absolute;
  bottom: 0px;
  display: block;
  left: 20px;
  right: 20px;
}
.aro-ticket-form-listing:last-child:before,
.aro-ticket-form-listing.last:before {
  display: none;
}
.aro-ticket-form-listing::after {
  content: '\eb9f';
  font-family: unicons-line;
  font-style: normal;
  font-weight: 400;
  display: inline-block;
  text-decoration: inherit;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #232229;
}
.aro-shortcuts-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #EAEBEF #f3f3f6;
  padding-bottom: 5px;
}
.aro-shortcuts-wrapper div::-webkit-scrollbar {
  height: 2px;
}
.aro-shortcuts-wrapper > .title {
  color: #65666B;
  font-weight: 500;
  font-size: 0.875em;
}
.aro-shortcuts-wrapper > .shortcut-group {
  display: flex;
  gap: 10px;
}
.aro-shortcuts-wrapper > .shortcut-group .shortcut {
  flex-shrink: 0;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
  border: 1px solid #EAEBEF;
  padding: 10px 20px;
  transition: 0.2s background-color ease;
}
.aro-shortcuts-wrapper > .shortcut-group .shortcut:hover {
  background-color: #f3f3f6;
}
.slide-fade-enter,
.slide-fade-fast-enter {
  opacity: 0;
  transform: translateX(60px);
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s;
}
.slide-fade-leave-to,
.slide-fade-fast-leave-to {
  opacity: 0;
  transform: translateX(-60px);
}
.slide-fade-fast-enter-active,
.slide-fade-fast-leave-active,
.slide-fade-fast-reverse-enter-active,
.slide-fade-fast-reverse-leave-active {
  transition: all 0.15s;
}
.slide-fade-fast-reverse-enter {
  opacity: 0;
  transform: translateX(-60px);
}
.slide-fade-fast-reverse-leave-to {
  opacity: 0;
  transform: translateX(60px);
}
.add-event-button {
  border: 2px dashed #00A3E0;
  background-color: transparent;
  text-align: center;
  padding: 50px 10px;
  width: 100%;
  color: #00A3E0;
  cursor: pointer;
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
}
.add-event-button .icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-event-button .icon-text svg {
  fill: #00A3E0;
}
.add-event-button .icon-text > span {
  margin-left: 8px;
}
.add-event-button:hover {
  border-color: #00597a;
  color: #00597a;
}
.add-event-button:hover svg {
  fill: #00597a;
}
.event-type-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.event-type-container > button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.event-type-container > button:focus {
  border: none;
  outline: none;
}
.event-type-container > button:hover {
  color: #0b0b0d;
}
.travel-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.true.card {
  width: 100%;
  display: flex;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.true.card > .content {
  flex: 1 1 auto;
  background-color: #fff;
  padding: 50px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-radius: 6px;
}
.true.card > .sidebar {
  flex: 0 1000 500px;
  min-width: 300px;
  background-color: #39449C;
  padding: 50px 30px;
  z-index: 100;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.true.card > .sidebar .true.stepper > .step {
  position: relative;
  padding: 60px 10px 60px 25px;
}
.true.card > .sidebar .true.stepper > .step > .step__heading,
.true.card > .sidebar .true.stepper > .step > .step__description {
  color: rgba(255, 255, 255, 0.5);
}
.true.card > .sidebar .true.stepper > .step > .step__heading {
  font-size: 18px;
  margin-bottom: 8px;
}
.true.card > .sidebar .true.stepper > .step > .step__icon-container {
  position: absolute;
  left: -50px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 2px solid #fff;
  color: #fff;
  text-align: center;
  line-height: 36px;
  background-color: #c8c8c8;
}
.true.card > .sidebar .true.stepper > .step.complete > .step__icon-container {
  background-image: url('../../img/checkmark.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-color: #39449C;
}
.true.card > .sidebar .true.stepper > .step.complete > .step__icon-container > span {
  display: none;
}
.true.card > .sidebar .true.stepper > .step.active > .step__icon-container {
  background-color: #00A3E0;
}
.true.card > .sidebar .true.stepper > .step.active > .step__heading {
  color: #fff;
}
.true.card > .sidebar .true.stepper > .step.active > .step__description {
  color: rgba(255, 255, 255, 0.7);
}
.true.card .review-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 1400px) {
  .true.card {
    flex-direction: column;
  }
  .true.card > .content {
    order: 2;
    padding: 40px 20px;
  }
  .true.card > .sidebar {
    order: 1;
    flex: 0 0 auto;
    border-radius: 0px;
    padding: 30px 20px;
  }
  .true.card > .sidebar .true.stepper {
    display: flex;
    position: static;
    justify-content: space-around;
  }
  .true.card > .sidebar .true.stepper > .step {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    padding: 0px;
  }
  .true.card > .sidebar .true.stepper > .step .step__heading {
    margin-bottom: 0px;
  }
  .true.card > .sidebar .true.stepper > .step .step__description {
    display: none;
  }
  .true.card > .sidebar .true.stepper > .step .step__icon-container {
    position: static;
  }
}
.true.panel {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
}
.true.panel .panel__header {
  padding: 12px 50px;
}
.true.panel.primary {
  border: 1px solid #A6BCC9;
}
.true.panel.primary .panel__header {
  background-color: rgba(35, 34, 41, 0.06);
  color: #232229;
  border-bottom: 1px solid #A6BCC9;
}
.true.panel.warning {
  border: 1px solid #FEEBD7;
}
.true.panel.warning .panel__header {
  background-color: rgba(254, 235, 215, 0.06);
  color: #faa042;
  border-bottom: 1px solid #FEEBD7;
}
.true.panel .panel__content,
.true.panel .panel__footer {
  padding: 25px 50px;
}
.true.panel .panel__footer {
  background-color: rgba(0, 0, 0, 0.05);
}
@media screen and (max-width: 768px) {
  .true.panel .panel__header,
  .true.panel .panel__content {
    padding: 30px 20px;
  }
}
.sliders-container {
  padding: 20px;
}
.sliders-container .slider {
  width: 400px;
  margin-right: 20px;
  display: inline-block;
}
.property-label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.61);
}
.property-value {
  font-size: 14px;
  color: #000;
}
/* HELPERS -------------------------------------------- */
.dark-link {
  font-size: 14px;
  color: #232229;
  cursor: pointer;
}
.dark-link:hover {
  color: #0b0b0d;
  text-decoration: underline;
}
.toast-title {
  font-weight: bold;
}
.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #FFFFFF;
}
.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
.toast-top-center {
  margin-top: 40px;
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}
#toast-container {
  position: fixed;
  z-index: 999999;
  padding: 0px 20px;
  pointer-events: none;
  /*overrides*/
}
#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#toast-container > div {
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 30px 20px;
  max-width: 500px;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
#toast-container > div.rtl {
  direction: rtl;
  padding: 15px 50px 15px 15px;
  background-position: right 15px center;
}
#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
  margin-left: auto;
  margin-right: auto;
}
#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.toast {
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 16px;
  background-color: #fff;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
  border-radius: 9px;
}
.toast > .icon {
  flex-shrink: 0;
  width: 46px;
  height: 46px;
  border-radius: 100px;
  position: relative;
}
.toast > .icon > i {
  position: absolute;
  font-size: 20px;
  color: #fff;
  top: 12px;
  left: 13px;
}
.toast .toast-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 13px;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.toast .content {
  flex-shrink: 1;
  position: relative;
}
.toast .content .toast-message {
  line-height: 1.2em;
  color: rgba(0, 0, 0, 0.7);
}
.toast.actions .content::after {
  content: '';
  width: 1px;
  height: 100%;
  background-color: #EAEBEF;
  position: absolute;
  right: -20px;
  top: 0px;
  bottom: 0px;
}
.toast .actions {
  flex-shrink: 0;
  position: relative;
  margin-left: 20px;
}
.toast.toast-warning > .icon {
  background-color: #FFD15B;
}
.toast.toast-success > .icon {
  background-color: #00C19F;
}
.toast.toast-error > .icon {
  background-color: #FF331F;
}
.toast.toast-error > .icon > i {
  left: 14px;
}
@media screen and (max-width: 480px) {
  .toast {
    flex-wrap: wrap;
  }
  .toast .actions {
    margin-left: 0px;
  }
  .toast.actions .content::after {
    display: none;
  }
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}
/*Responsive Design*/
.aro.formbuilder {
  display: grid;
  grid-template-columns: minmax(0, 1fr) 262px;
  grid-gap: 20px;
  align-items: start;
}
@media screen and (max-width: 1200px) {
  .aro.formbuilder {
    grid-template-columns: minmax(0, 1fr) 175px;
  }
}
@media screen and (max-width: 1030px) {
  .aro.formbuilder {
    grid-template-columns: minmax(0, 1fr) auto;
  }
}
.aro.formbuilder .form-wrapper {
  border: 2px dashed #EAEBEF;
  border-radius: 16px;
  padding: 15px;
  margin-top: 0px;
  list-style: none;
  position: relative;
  z-index: 10;
}
.aro.formbuilder .form-wrapper .form-placeholder {
  margin: 100px auto;
  text-align: center;
  color: #65666B;
}
.aro.formbuilder .form-wrapper .form-placeholder p {
  font-weight: 600;
  margin-bottom: 5px;
}
.aro.formbuilder .form-wrapper .sortable-placeholder {
  height: 135px;
  border-radius: 8px;
  border: 2px #00A3E0 dashed;
  width: 100%;
  margin-bottom: 20px;
}
.aro.formbuilder .form-wrapper .field-wrapper {
  background-color: #f3f3f6;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  position: relative;
}
.aro.formbuilder .form-wrapper .field-wrapper.ui-sortable-helper {
  box-shadow: 0px 2px 23px -3px rgba(0, 0, 0, 0.14);
}
.aro.formbuilder .form-wrapper .field-wrapper .child-divider {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 15px;
  display: none;
}
.aro.formbuilder .form-wrapper .field-wrapper .child-divider:before {
  content: '';
  height: 1px;
  width: 100%;
  position: absolute;
  top: 16px;
  background-color: rgba(0, 0, 0, 0.22);
}
.aro.formbuilder .form-wrapper .field-wrapper .child-divider .content {
  background-color: #f3f3f6;
  padding: 5px 15px;
  color: rgba(0, 0, 0, 0.7);
  margin: 0 auto;
  display: inline-block;
  font-size: 25px;
  position: relative;
  z-index: 1;
}
.aro.formbuilder .form-wrapper .field-wrapper ul {
  list-style-type: none;
  padding-left: 0px;
}
.aro.formbuilder .form-wrapper .field-wrapper ul .child-divider {
  display: flex;
}
.aro.formbuilder .form-wrapper .field-wrapper ul li {
  padding-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 0px;
}
.aro.formbuilder .form-wrapper .field-wrapper ul li .toolbar {
  justify-content: flex-end;
}
.aro.formbuilder .form-wrapper .field-wrapper ul .dragger {
  display: none;
}
.aro.formbuilder .form-wrapper .field-wrapper.expanded > .field-form-wrapper {
  height: auto;
  opacity: 1;
  transform: scale(1);
  overflow: visible;
}
.aro.formbuilder .form-wrapper .field-wrapper .toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.aro.formbuilder .form-wrapper .field-wrapper .toolbar .dragger {
  padding: 8px;
  cursor: pointer;
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.aro.formbuilder .form-wrapper .field-wrapper .toolbar .dragger:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.aro.formbuilder .form-wrapper .field-wrapper > .data-form {
  padding: 20px;
  background-color: #EAEBEF;
}
.aro.formbuilder .form-wrapper .field-wrapper .field-form-wrapper {
  height: 0px;
  overflow: hidden;
  opacity: 0;
  transform: scale(0.9);
  /* fade out, then shrink */
  transition: opacity 0.4s ease, transform 0.2s ease;
}
.aro.formbuilder .form-wrapper .field-wrapper .field-form-wrapper .ui.loading.selection.dropdown > i.icon {
  padding: 18px !important;
}
.aro.formbuilder .form-wrapper .field-wrapper .field-form-wrapper .ui.loading.selection.dropdown > i.icon:before {
  display: none;
}
.aro.formbuilder .form-wrapper .field-wrapper .field-form-wrapper .ui.selection.dropdown > .remove.icon {
  right: 3.7em;
  top: 11px;
}
.aro.formbuilder .form-wrapper .field-wrapper .field-form-wrapper .title {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #232229;
  margin-bottom: 10px;
}
.aro.formbuilder .form-wrapper .field-wrapper .field-form-wrapper .title:before {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background-color: #001871;
  margin-right: 12px;
}
.aro.formbuilder .form-wrapper .field-wrapper .field-form-wrapper .validation-errors {
  border-radius: 6px;
  padding: 15px;
  background-color: #FFC6C1;
  color: #B01B0C;
}
.aro.formbuilder .form-wrapper .field-wrapper .field-form-wrapper .options .option-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 32px;
  grid-gap: 5px;
  align-items: center;
  margin-bottom: 5px;
}
@media screen and (max-width: 1030px) {
  .aro.formbuilder .components {
    padding: 10px 0px;
  }
  .aro.formbuilder .components .header {
    display: none;
  }
}
.aro.formbuilder .components .control-wrapper {
  display: flex;
  flex: 200px 0 0;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
}
@media screen and (max-width: 1030px) {
  .aro.formbuilder .components .control-wrapper {
    padding: 10px;
  }
}
.aro.formbuilder .components .control-wrapper:hover {
  background-color: #f3f3f6;
}
.aro.formbuilder .components .control-wrapper .icon {
  margin-right: 10px;
  height: 30px;
  width: 30px;
  background-color: #EAEBEF;
  border-radius: 6px;
  text-align: center;
  line-height: 30px;
  color: #39393F;
  flex: 0 0 auto;
}
@media screen and (max-width: 1030px) {
  .aro.formbuilder .components .control-wrapper .icon {
    margin-right: 0px;
  }
}
.aro.formbuilder .components .control-wrapper .details {
  line-height: 18px;
}
@media screen and (max-width: 1030px) {
  .aro.formbuilder .components .control-wrapper .details {
    display: none;
  }
}
.aro.formbuilder .components .control-wrapper .details .title {
  font-size: 0.875rem;
  font-weight: 700;
  color: #39393F;
}
@media screen and (max-width: 1200px) {
  .aro.formbuilder .components .control-wrapper .details .title {
    font-weight: 600;
  }
}
.aro.formbuilder .components .control-wrapper .details .description {
  font-size: 0.875rem;
  color: #727380;
}
@media screen and (max-width: 1200px) {
  .aro.formbuilder .components .control-wrapper .details .description {
    display: none;
    grid-template-columns: minmax(0, 1fr) 175px;
  }
}
@media print {
  .no-print {
    display: none !important;
  }
  .app-ui-sidebar,
  .app-ui-header,
  .app-ui-title-block,
  .app-ui-footer {
    display: none !important;
  }
  .aro.card {
    border: 1px solid #EAEBEF;
    box-shadow: none !important;
  }
  .ui.menu.asd.main-menu {
    display: none;
  }
  body,
  #body {
    font-size: 8pt !important;
  }
  .title-container {
    display: none;
  }
}
