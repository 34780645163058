@field-wrapper-bg: lighten(@border, 3%);

.aro.formbuilder {
  display:grid;
  grid-template-columns: minmax(0, 1fr) 262px;
  grid-gap: 20px;
  align-items: start;

  .breakpoint-lg-tablet({
    grid-template-columns: minmax(0, 1fr) 175px;
  });

  .breakpoint-md-tablet({
    grid-template-columns: minmax(0, 1fr) auto;
  });
  
  .form-wrapper {
    border:2px dashed @border;
    border-radius:16px;
    padding:15px;
    margin-top:0px;
    list-style:none;
    position:relative;
    z-index:10;

    .form-placeholder {
      margin:100px auto;
      text-align:center;
      color:@color__grey-medium-light;

      p {
        font-weight:@semi-bold;
        margin-bottom:5px;
      }
    }

    .sortable-placeholder {
      height:135px;
      border-radius:8px;
      border:2px @secondary dashed;
      width:100%;
      margin-bottom:20px;
    }

    .field-wrapper {
      background-color: @field-wrapper-bg;
      border-radius:8px;
      padding:15px;
      margin-bottom:20px;
      position:relative;
  
      &.ui-sortable-helper {
        box-shadow: 0px 2px 23px -3px rgba(0,0,0,0.14);
      }

      .child-divider {
        width:100%;
        display:flex;
        justify-content: center;
        position:relative;
        margin-top:15px;
        display:none;

        &:before {
          content:'';
          height:1px;
          width:100%;
          position:absolute;
          top:16px;
          background-color:rgba(0,0,0, .22);
        }

        .content {
          background-color: @field-wrapper-bg;
          padding:5px 15px;
          color:@color__grey-medium;
          margin:0 auto;
          display:inline-block;
          font-size:25px;
          position:relative;
          z-index:1;
        }
      }

      ul {
        list-style-type: none;
        padding-left:0px;

        .child-divider {
          display:flex;
        }

        li {
          padding-top:10px;
          padding-left: 0px;
          padding-right: 0px;
          margin-bottom: 0px;

          .toolbar {
            justify-content:flex-end;
          }
        }
        .dragger {
          display:none;
        }
      }
  
      &.expanded {
        & > .field-form-wrapper {
          height:auto;
          opacity:1;
          transform:scale(1);
          overflow:visible;
        }
      }
  
      .toolbar {
        display:flex;
        align-items: center;
        justify-content: space-between;
  
        .dragger {
          padding:8px;
          cursor:pointer;
          cursor: move; /* fallback if grab cursor is unsupported */
          cursor: grab;
          cursor: -moz-grab;
          cursor: -webkit-grab;
        }

        .dragger:active {
          cursor: grabbing;
          cursor: -moz-grabbing;
          cursor: -webkit-grabbing;
        }
      }
  
      & > .data-form {
        padding:20px;
        background-color:@border;
      }
  
      .field-form-wrapper {
        height:0px;
        overflow:hidden;
        opacity: 0;
        transform: scale(0.9);
        /* fade out, then shrink */
        transition: opacity .4s ease, transform .2s ease;
        // transition: opacity .4s ease, transform .2s ease;

        //style the loading of api call dropdowns
        .ui.loading.selection.dropdown>i.icon {
          padding: 18px !important;

          &:before {
            display:none;
          }
        }

        //styles for the clearable option on dropdown selection
        .ui.selection.dropdown>.remove.icon {
          right: 3.7em;
          top: 11px;
        }
  
        .title {
          display:flex;
          align-items: center;
          font-weight:@semi-bold;
          color:@primary-dark;
          margin-bottom:10px;
  
          &:before {
            content:'';
            width:6px;
            height:6px;
            border-radius:10px;
            background-color:@primary;
            margin-right:12px;
          }
        }
  
        .validation-errors {
          border-radius:6px;
          padding:15px;
          background-color:@color__error-light;
          color:@color__error-dark;
        }

        .options {

          .option-wrapper {
            display:grid;
            grid-template-columns: 1fr 1fr 32px;
            grid-gap:5px;
            align-items:center;
            margin-bottom:5px;
          }
        }
      }
    }
  }

  .components {
    .breakpoint-md-tablet({
      .header {
        display:none;
      }

      padding:10px 0px;
    });

    .control-wrapper {
      display:flex;
      flex:200px 0 0;
      align-items: center;
      padding:10px 20px;
      cursor:pointer;

      .breakpoint-md-tablet({
        padding:10px;
      });

      &:hover {
        background-color:lighten(@border, 3%);
      }

      .icon {
        margin-right:10px;
        height:30px;
        width:30px;
        background-color:@border;
        border-radius:6px;
        text-align:center;
        line-height:30px;
        color:@color__grey-medium-dark;
        flex: 0 0 auto;

        .breakpoint-md-tablet({
          margin-right:0px;
        });
      }

      .details {
        line-height:18px;

        .breakpoint-md-tablet({
          display:none;
        });

        .title {
          font-size:@font__small;
          font-weight:@bold;
          color:@color__grey-medium-dark;

          .breakpoint-lg-tablet({
            font-weight:@semi-bold;
          });
        }

        .description {
          font-size:@font__small;
          color:@color__grey-light;

          .breakpoint-lg-tablet({
            display:none;
            grid-template-columns: minmax(0, 1fr) 175px;
          });
        }
      }
    }
  }
}