.aro-ui-wrapper {
  display:grid;
  position:fixed;
  height:100vh;
  width:100%;
  background-color:#F4F6F8;
  grid-template-columns: auto 1fr;
  grid-template-rows: 70px 1fr 30px;
  grid-template-areas:
    "sidenav header"
    "sidenav main"
    "sidenav footer";

  .breakpoint-mobile({
    display:flex;
    flex-direction:column;
    position:static;
    min-height:100%;
    padding-top:70px;
  });

  &.with-titlebar{
    grid-template-rows: 70px auto 1fr 30px;
    grid-template-areas:
      "sidenav header"
      "sidenav title"
      "sidenav main"
      "sidenav footer";
  }

  .app-ui-sidebar {
    grid-area: sidenav;
    height:100%;
    background-color:@primary-dark;
    display:flex;
    flex-direction: column;
    width:78px;
    overflow-x:auto;
    transition:width 0.3s ease;
    position:relative;

    //style the scrollbar to be more discrete
    &::-webkit-scrollbar {
      width:4px;
      background-color:@border;
    }
    //style the scrollbar slider
    &::-webkit-scrollbar-thumb {
      background-color:@color__grey-medium-dark;
      border-radius:4px;
    }

    &.discrete {
      width:0px;
    }

    .app-ui-sidebar-header {
      display:flex;
      align-items:center;
      font-family:'Montserrat','Inter',sans-serif;
      background-color:#fff;
      padding:16px 20px;
      justify-content: space-between;
      border-right:1px solid #e6e6e6;
      position:sticky;
      top:0px;
      left:0px;
      z-index:100;
      width:100%;

      & > img {
        height:37px;
        width:37px;
      }

      & > .heading, & > .heading-img {
        color: @primary;
        font-size:18px;
        font-weight:@bold;
        opacity:0;
        position:absolute;
        left:-500px;
        transition:opacity 0.2s ease;
      }

      .breakpoint-mobile({
        display:none;
      });
    }

    .items-wrapper {
      height:auto;
      max-height:100%;
      padding-top:20px;
      display:flex;
      flex-direction: column;
      justify-content: space-between;
      flex:1 0 auto;
      font-size:14px;

      .breakpoint-mobile({
        overflow:auto;
      });

      & > .main {
        padding-bottom:40px;
      }
    
      .item {
        position:relative;
        display:flex;
        justify-content: space-between;
        align-items: center;
        color:#fff;
        padding:16px 20px;
        padding-left:27px;
        cursor:pointer;
        text-decoration:none;
        
        .item-content {
          display:flex;
          flex:1 0 auto;
          align-items: center;
          user-select: none;
          transition: transform 0.2s ease;

          & > p {
            margin:0px 0px 0px 16px;
            opacity:0;
            transition:opacity 0.2s ease;
          }

          & > svg {
            width:22px;
            height:22px;
          }
        }

        &:not(.child):hover .item-content {
          // padding-left:3px;
          transform:translateX(3px);
        }

        &.active::after {
          content:'';
          position:absolute;
          width:4px;
          height:23px;
          border-radius:0px 3px 3px 0px;
          background-color:@ios-purple;
          margin:auto 0px;
          left:0px;
        }

        &.child {
          padding:4px 20px;
          opacity:0;
          position:absolute;
          left:-600px;
          transition:opacity 0.2s ease;

          .item-content {
            display:block;
            padding:9px 40px;
            width:100%;
            border-radius:8px;
            white-space: nowrap;
            overflow:hidden;
            transition: background-color 0.2s ease;

            & > p {
              margin:0px;
            }

          }

          .item-content:hover {
            background-color: @ios-purple;
          }
        }
      }

      .sidebar-dropdown-item {

        & > .item.dropdown > svg {
          width:22px;
          height:22px;
          transform: rotate(90deg);
          opacity:0;
          transition: transform 0.2s ease, opacity 0.2s ease;;
        }


        .dropdown-container {
          height:auto;
          // max-height:56px;
          opacity:1;
          transition: opacity 0.2s ease, max-height 0.2s ease;
        }
      }

      
      .sidebar-dropdown-item.collapsed {
        & > .item.dropdown > svg {
          transform: rotate(0deg);
        }

        .dropdown-container {
          max-height:0px !important;
          overflow:hidden;
          opacity:0;
        }
      }

      .divider {
        height:1px;
        width:100%;
        background-color:rgba(255, 255, 255, 0.1);
        margin:10px 0px;
      }
    }

    .breakpoint-mobile({
      width:0px;
      overflow:hidden;
      position:fixed;
      z-index:100;
      top:0px;
      bottom:0px;
      height:auto;
      padding-top:70px;
    });
  }

  .app-ui-header {
    grid-area: header;
    background-color:#fff;
    position:relative;
    display:flex;
    width:auto;
    align-items: center;
    padding:0px 0px 0px 20px;
    height:70px;
    border-bottom:1px solid @border;
    justify-content: space-between;

    .user-details {
      cursor:pointer;
      height:100%;
      display:flex;
      align-items: center;
      padding:0px 20px;
      // margin-left:auto;
      position: relative;
      z-index: 20;
      user-select:none;

      & > p {
        font-weight:@semi-bold;
        margin-right:14px;
      }

      & > .user-menu {
        display:none;
        position:absolute;
        top:75px;
        background-color:#fff;
        border-radius:8px;
        border:1px solid @border;
        min-width: 200px;
        right: 20px;
        overflow:hidden;
        padding:4px 0px;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);

        &.active {
          display:block;
        }

        a {
          padding: 4px 20px;
          color:@primary-dark;
          display:block;

          &:hover {
            background-color:@border;
          }
        }
      }
    }

    .links-block {
      display:flex;
      height:100%;

      .breakpoint-lg-tablet({
        display:none;
      });


      & > .item {
        position: relative;
        height:100%;
        font-size:.875em;
        font-weight:@medium;
        color:@color__grey-medium;
        padding:0px 20px;

        &.active {
          & > a, & > .link {
            color:@ios-purple;
          }
          border-bottom:2px solid @ios-purple;
        }

        a, .link {
          height:100%;
          display:flex;
          align-items:center;
          column-gap:5px;
          color:@color__grey-medium;
          transition:color 0.2 ease;
          cursor:pointer;

          &:hover {
            color:@ios-purple;
          }
        }
      }
    }

    & > .sidebar-toggle {
      font-size:24px;
      cursor:pointer;

      &:hover {
        color:@ios-purple;
      }
    }

    .user-nav-wrapper {
      display:flex;
      align-items:center;
      padding:0px 20px;
      cursor:pointer;
      user-select: none;
      height:100%;

      .site-logo {
        margin-right:14px;
        width: 45px;
        height:45px;
        border-radius:100%;
        background-color:#EEF3FE;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width:70%;
        }
      }

      .details {
        margin-right:20px;

        .name {
          font-weight: @bold;
        }

        .site {
          font-size:@font__small;
          color:@color__grey-medium;
        }
      }

      svg {
        color: @color__grey-medium;
        fill: @color__grey-medium;
      }
    }

    &.minimalistic {
      justify-content: space-between;

      .sidebar-toggle {
        display:none;

        .breakpoint-lg-tablet({
          display:block;
        });
      }

      & > .icon-wrapper {
        display:flex;
        align-items:center;
        gap:20px;

        img {
          width:37px;
          height:37px;
        }

        .heading {
          font-weight:@bold;
          font-size:1.125em;
          margin:0px;
          color:@primary;
          font-family:'Montserrat', 'Inter', sans-serif;
        }

        .breakpoint-lg-tablet({
          display:none;
        });
      }
    }

    .breakpoint-mobile({
      z-index:110;
      position:fixed;
      top:0px;
      width:100%;
    });
  }

  .app-ui-title-block {
    grid-area: title;
    display:flex;
    flex-wrap:wrap;
    align-items: center;
    padding:10px 20px;
    justify-content: space-between;
    row-gap:20px;
    background-color:#fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
    position:relative;
    z-index:10;

    .breakpoint-mobile({
      padding:15px 20px 10px;
    });

    .title {
      color:@primary-dark;
      font-size:20px;
      margin:0px 66px 0px 0px;
    }

    .heading {
      display:flex;
      align-items: center;

      .breakpoint-extra-lg-tablet({
        display:block;
      });
    }

    .actions-wrapper {
      display:flex;
      align-items:center;
      column-gap:10px;
    }

    .aro-title-block {
      display:flex;
      align-items:center;
      column-gap:10px;
      justify-content: space-between;
    }

    &.mobile-ready {

      .mobile-actions {
        display:none;

        .breakpoint-extra-lg-tablet({
            display:block;
        });
      }

      .actions-wrapper {

        .breakpoint-extra-lg-tablet({
          display:none;
        });
      }
    }
  }

  .app-ui-content {
    grid-area:main;
    padding:20px;
    display:block;
    overflow-y:scroll;

    &.white {
      background-color:#fff;
    }

    .breakpoint-mobile({
      padding:10px 10px 50px 10px;
      overflow-y:visible;
    });
  }

  .app-ui-footer {
    grid-area: footer;
    background-color:#fff;
    display:flex;
    border-top:1px solid @border;
    align-items: center;
    justify-content: space-between;
    padding:0px 20px;
    font-size:12px;

    .breakpoint-mobile({
      // background-color:transparent;
      // border:none;
      margin-top:auto;
      font-size:10px;
    });

    & > img {
      height:17px;
    }
  }

  .app-ui-sidebar.expanded, .app-ui-sidebar.soft-expanded  {
  // .app-ui-sidebar:hover, .app-ui-sidebar.expanded, .app-ui-sidebar.soft-expanded  { // use this to make the whole nave slide out on hover

    width:267px;

    // .items-wrapper .item {
    //   // padding-left:20px;
    // }

    .items-wrapper .item .item-content > p {
      opacity:100;
    }
    
    .sidebar-dropdown-item > .item.dropdown > svg {
      opacity:100;
    }

    .app-ui-sidebar-header > .heading, .app-ui-sidebar-header > .heading-img {
      opacity:100;
      position:static;
    }

    .app-ui-sidebar-header.roboworx-varient > img {
      display:none;
    }

    .item.child {
      position:relative;
      left:0px;
      opacity:100;
    }
  }
  
  //Don't automatically show the sidebar even if cookie if set on mobile
  .breakpoint-mobile({
    .app-ui-sidebar.soft-expanded:not(.expanded) {width:0px};
  });
}

.aro-user-nav-wrapper {
  display:flex;
  align-items:center;
  padding:0px 20px;
  user-select: none;
  height:100%;
  
  &.selectable {
    cursor:pointer;
    transition:background-color 0.2s ease;

    &:hover {
      background-color:lighten(@border, 2);
    }
  }

  &.no-padding {
    padding:0px;
  }

  .site-logo {
    margin-right:14px;
    width: 45px;
    height:45px;
    border-radius:100%;
    background-color:#EEF3FE;
    display: flex;
    align-items: center;
    justify-content: center;
    border:1px solid @border;
    flex: 0 0 auto;

    img {
      max-width:70%;
    }
  }

  .details {
    margin-right:20px;
    flex:0 1 200px;
    width:200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    .name {
      font-weight: @bold;
      text-overflow: ellipsis;
      overflow:hidden;
    }

    .site {
      font-size:@font__small;
      color:@color__grey-medium;
      text-overflow: ellipsis;
      overflow:hidden;
    }
  }

  svg {
    color: @color__grey-medium;
    fill: @color__grey-medium;
  }

  & > a.aro.button {
    margin-left:auto;
  }
}

.aro-title-bar-wrapper {
  display:flex;
  flex-wrap:wrap;
  align-items: center;
  padding:15px 0px 20px;
  justify-content: space-between;
  row-gap:20px;

  .breakpoint-mobile({
    padding:15px 0px 10px;
  });

  .title {
    color:#232229;
    margin:0px 66px 0px 0px;
  }

  .heading {
    display:flex;
    align-items: center;

    .breakpoint-extra-lg-tablet({
      display:block;
    });
  }

  .actions-wrapper {
    display:flex;
    align-items:center;
    column-gap:10px;
  }

  &.mobile-ready {

    .mobile-actions {
      display:none;

      .breakpoint-extra-lg-tablet({
          display:block;
      });
    }

    .actions-wrapper {

      .breakpoint-extra-lg-tablet({
        display:none;
      });
    }
  }
}

.aro-breadcrumbs-container {
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  color: @color__grey-medium;
  font-size:@font__small;

  .breakpoint-tablet({
    display:none;
  });

  a { 
    color: @color__grey-medium; 
    padding:5px;

    &:hover {
      color:@ios-purple;
    }
  }

  span {
    padding:5px;
  }
  
  svg {
    width:16px;
    height:16px;
    fill: @color__grey-medium;
    margin:0px 0px;
  }

  .home-icon {
    margin:0px;
  }
}

.aro-client-breadcrumbs-block {
  display:flex;
  align-items: center;
  column-gap:10px;

  .breadcrumb-item {
    font-size:1.25em;
    margin:0px;
    color:@primary-dark;
    font-weight:@regular;

    .breakpoint-tablet({
      font-size:1em;
    });

    &.active {
      font-weight: @semi-bold;
    }
  }

  a {
    transition: color 0.2s ease;

    &:hover {
      color:@secondary;
    }
  }

  .breakpoint-mobile({
    .breadcrumb-item,.breadcrumb-icon {
      display:none;
    }
    .breadcrumb-item.active {
      display:block;
    }
  })
}


.aro-table-wrapper {
  /*
    Mixin for force mobile styling
    This will create breakpoint styles and force-mobile styles at the same time
    This way our mobile styles work the same for both .force-mobile and our css media query breakpoint
  */
  .mobile-table-styles(@rules) {
    &.force-mobile {
      @rules();
    }

    .breakpoint-mobile({ @rules() });
  }

  .aro-data-table-toolbar {
    display:flex;
    justify-content: space-between;
    padding:15px 20px;
    border-bottom:1px solid @border;
  
    & > .categories {
      display:flex;
      align-items:center;
      font-size:16px;
      color:@color__grey-light;
      font-weight: @semi-bold;
  
      .category {
        position:relative;
        cursor:pointer;
        line-height:39px;
        transition: color 0.2s ease;
  
        &:hover {
          color:@ios-purple;
        }
  
        &.active {
          color:@ios-purple;
          
          &::after {
            content:'';
            width:100%;
            background-color:@ios-purple;
            height:2px;
            position:absolute;
            bottom:-16px;
            left:0px;
          }
        }
      }
  
      // Add a 8px gap between elements
      & > * {
        margin:0px 8px;
        
        &:first-child { margin-left:0px;}
        &:last-child { margin-right:0px;}
      }
    }
  
    & > .results-group {
      display:flex;
      align-items:center;
  
      .total-info {
        font-size:16px;
        color:@color__grey-medium;
      }
  
      // Add a 10px gap between elements
      & > * {
        margin:0px 8px;
        
        &:first-child { margin-left:0px;}
        &:last-child { margin-right:0px;}
      }
    }
  }
  
  .aro-data-table-peripherals {
    padding:10px 20px;
    border-bottom:1px solid @border;
  
    & > .top-row {
      display:flex;
      justify-content: space-between;
      flex-wrap: wrap;
  
      & > .button-group {
        display:flex;
  
        // Add a 10px gap between elements
        & > * {
          margin:0px 5px;
          
          &:first-child { margin-left:0px;}
          &:last-child { margin-right:0px;}
        }
      }
  
      & > .search-group {
        line-height:0px; //fixes weired whitespace issue
      }
    }
  
    & > .bottom-row {
      display:flex;
      flex-wrap:wrap;
  
      & > * {
        margin-top:10px;
        margin-right:10px;
        
        &:last-child { margin-right:0px;}
      }
  
  
      .search-info, .filter-info {
        font-weight:@regular;
        color:@primary-dark;
        font-size:@font__small;
        padding-right:12px;
        background-color:@primary-dark;
        color:#fff;
        border-radius:6px;
        user-select: none;
        opacity:0;
        position:absolute;
        display:none;
        visibility:hidden;
        transform:scale(0.9);
        transition: visibility 0.2s ease, opacity 0.2s ease, transform 0.2s ease;
  
        &.active {
          opacity:100;
          transform:scale(1);
          visibility: visible;
          position:static;
          display:block;
        }
  
        .clear-search, .clear-filters {
          padding:6px 12px;
          display:inline-block;
          cursor:pointer;
          transition: color 0.2s ease;
  
          &:hover {
            color:rgba(255,255,255,.7);
          }
        }
      }
  
      .filter-info {
        background-color:@secondary;
        color:#fff;
      }
    }
  }

  .aro-table-container {
    width:100%;
    max-height:500px;
    overflow-x:scroll;
    position:relative;
  
    //When table container is in loading state
    &.loading {
      min-height:400px;
      overflow-y:hidden;
      overflow-x:hidden;
  
      & > .ui.table.true::before { 
        content: '';
        position:absolute;
        top:0px;
        background-color:rgba(255,255,255, .6);
        height:100%;
        min-height:400px;
        width:100%;
        z-index: 99;
      }
    }
  
    .table-loader {
      position: absolute;
      top: 0px;
      z-index: 1;
      margin: 0 auto;
      text-align: center;
      width: 100%;
  
      & > .loader {
        margin:0 auto;
        width:200px;
        position:relative;
  
        &::after {
          content: 'Fetching data...';
          position: absolute;
          width: 100%;
          left: 0px;
          bottom: 20px;
        }
      }
    }
  }

  .aro-table-footer {
    padding:10px 20px;
    display:flex;
    align-items: center;
    justify-content: space-between;
  
    & > .pagination-info {
      display:flex;
      align-items: center;
      color:@color__grey-medium;
    }
  
    & > .mobile-pagination-info {
      display:none;
    }
  
    .ui.loader {
      margin-left:auto;
    }

    .aro-pagination {
      & > ul {
        list-style-type: none;
        margin:0px;
        color:@color__grey-light;
        padding-left:0px;
    
        li {
          float: left;
          display: block;
          text-align: center;
          padding: 4px 12px;
          margin:0px 2px;
          border-radius:4px;
          text-decoration: none;
          color:@color__grey-light;
          font-weight:@semi-bold;
          cursor:pointer;
          transition: background-color 0.2s ease, color 0.2s ease;
    
          &:hover {
            background-color:@border;
            color:@ios-purple;
          }
    
          &.active {
            background-color:@ios-purple;
            color:#fff;
          }
    
          &.arrow {
            padding:4px 8px;
          }
    
          &.disabled:hover {
            background-color:initial;
            cursor:default;
          }
        }
      }
    }
  }

  .mobile-table-styles({
    &.mobile-ready table, &.mobile-ready .aro-table-container {
      display:none;
    }

    .aro-data-table-peripherals  > .top-row  > .button-group {
      .ui.compact.menu, .ui.compact.dropdown {
        display:none;
      }
    }

    .aro-table-footer > .pagination-info {
      display:none;
    }

    .aro-table-footer > .mobile-pagination-info {
      display:block;
    }

    .aro-table-footer .aro-pagination > ul li:not(.arrow) {
      display:none;
    }
  });
}

.aro-filter-row {
  .delete-button {
    text-align: center;
    padding: 5px;
    cursor: pointer;
    font-size:18px;
    transition: color 0.2s ease;

    &:hover {
      color:@color__grey-light;
    }
  }
}

.aro-contacts-wrapper {
  border-radius:8px;
  border:1px solid @border;
  background-color:#fff;
  padding:10px 18px;
  display:flex;
  align-items:center;
  margin-bottom:10px;

  &.variation2 {
    border-top:none;
    border-bottom:1px solid @border;
    border-left:none;
    border-right:none;
    border-radius: 0px;
    padding:16px 0px;
    margin-bottom:0px;

    &:last-child {
      border-bottom:none;
    }
  }

  .contact {
    flex: 1;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    margin-right:12px;
    width:0;
    min-width:0;

    .name {
      font-size:16px;
      font-weight:@medium;
      color:#000;
    }

    .detail {
      display:inline;
      font-size:@font__small;
      color:@color__grey-medium-light;

      //Put each on a new line
      &:after { content: "\A"; white-space: pre; }

      i {
        margin-right: 10px;
      }
    }
  }

  .actions {
    display:flex;
    flex:0 0 30px;
    flex-direction:column;
  }
}

.aro-popup-card {
  opacity:0;
  visibility: hidden;
  transform:scale(.8);
  transition: transform 0.2s ease, opacity 0.2s ease, visibility 0.2s ease;
  position:sticky !important;
  top:0px;

  .close {
    position:absolute;
    padding:12px;
    right: 10px;
    top: 10px;
    cursor:pointer;

    &:hover {
      color:@color__grey-medium-light;
    }
  }

  &.active {
    opacity:1;
    transform:scale(1);
    visibility:visible;
  }
}

.aro-pdf-viewer {
  display:flex;
  flex-direction:column;

  &.ui.modal > .close {
    top: 18px !important;
    font-size: 26px !important;
  }

  .breakpoint-tablet({
    &.ui.modal > .close {
      top: 18px !important;
      font-size: 26px !important;
    }
  });

  .aro-pdf-content {
    flex:1 1 auto;
    align-self: stretch;
    position:relative;
  }
}

.aro-photos-group {
  display:grid;
  grid-gap:6px;
  grid-template-columns: repeat(auto-fill, 78px);

  & > .photo {
    width:78px;
    height:78px;
    border-radius:8px;
    border:2px solid #000;
    background-color:#fff;
    overflow:hidden;
    margin:2px;

    .clickable& {
      cursor:pointer;
      transition: border-color 0.3s ease;
      &:hover {
        border-color: @secondary;
      }
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain; //https://stackoverflow.com/questions/9071830/contain-an-image-within-a-div
      overflow: hidden;
    }
  }
}

.aro-companies-grid {
  display:grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 3fr));
  column-gap:20px;
  row-gap:20px;
  align-items:start;

  .aro-company-picker {
    display:flex;
    flex-direction: column;
    align-items: center;
    cursor:pointer;

    &:hover, &.active {
      .image {
        border:2px solid @secondary;
      }

      .name {
        color: @secondary;
      }
    }

    .name {
      margin-top:17px;
      text-align: center;
      color: @color__grey-medium-dark;
      transition: color 0.2s ease;
    }

    .image {
      display:flex;
      justify-content: center;
      align-items:center;
      border-radius:10px;
      border:2px solid @color__grey-dark;
      background-color:#fff;
      height:100px;
      width:100px;
      padding:10px;
      transition: border-color 0.2s ease;

      & > img {
        width:100%;
        height:100%;
        object-fit:contain;
      }
    }
  }
}

.aro-assignee-group {
  display:flex;
  flex-direction: column;
  row-gap:18px;

  .assignee {
    display:flex;
    column-gap:14px;
    align-items: center;

    & > .icon {
      background-color:@border-medium;
      border-radius:200px;
      height:26px;
      width:26px;
      display:flex;
      align-items:center;
      justify-content: center;
      font-weight:@semi-bold;
      flex:0 0 26px;
    }

    & > .details-group {
      flex:1 1 auto;
      line-height:1.1em;

      .name {
        font-size:16px;
        font-weight:@medium;
        color:@primary-dark;
      }

      .email {
        font-size:@font__small;
        font-weight:@regular;
        color:@color__grey-medium-light;
      }
    }
  }
}

.aro-event-logs {

  .event-log {
    display:flex;
    align-items:center;
    padding:10px 20px;
    cursor:pointer;

    &:hover {
      background-color:@border-light;
    }
    
    .details {
      flex:1 1 auto;

      .name {
        color:@primary-dark;
        font-weight:@medium;
        font-size:16px;
      }

      .date, .time {
        color:@color__grey-medium;
        font-size:14px;
        line-height:1.1em;
      }
    }

    .duration-wrapper {
      flex:0 0 auto;
    }
  }

  .no-logs {
    padding:20px;
    text-align:center;
    i {
      font-size:24px;
      color:@color__grey-light;
      margin-bottom:8px;
    }

    p {
      font-size:14px;
      font-weight:@medium;
      color:@color__grey-light;
    }
  }
}

.aro-buttons-group {
  display:flex;
  flex-direction: column;
  gap:10px;
  padding-bottom:2px;

  .aro.button {
    margin-right:0px;
  }
}

.aro-table-mobile-container {
  .description {
    word-break: break-word;
  }
}

.aro-small-widget-wrapper {
  display:grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: 1fr;
  gap:20px;

  .breakpoint-lg-tablet({
    grid-template-columns:minmax(0, 1fr);
  });
}

.aro-large-widget-wrapper {
  margin-top:20px;
  display:grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  // grid-template-columns: minmax(0, 1fr);
  grid-template-rows: 1fr;
  column-gap:20px;
  row-gap:20px;

  .breakpoint-extra-lg-tablet({
    grid-template-columns:minmax(0, 1fr);
  });
}

.aro-stars-wrapper {
  margin-top:20px;
  overflow:hidden;

  img {
    width:109px;
  }
}

.aro-recommend-bar-wrapper {
  display:flex;
  flex-wrap:nowrap;
  align-items:center;
  gap:8px;

  .recommend-bar {
    flex:1 1 100%;
    height:7px;
    border-radius:100px;
    background: linear-gradient(90deg, #FF0000 -4.5%, #FFE920 49.38%, #00B612 100%);

    .caret {
      transition:transform 0.4s ease-out;
    }
  }

  .recommend-percentage {
    font-size:.875em;
    color:fade(@primary-dark, 80%);
    font-weight:@medium;
  }
}

.aro-daterange-picker-wrapper {
  position:relative;

  .daterange-picker {
    display:flex;
    padding:10px 20px;
    color:#232229;
    gap:20px;
    border:1px solid @color__grey-medium-light;
    border-radius:6px;
    user-select: none;
    cursor:pointer;

    &:hover, &.active {
      background-color:#fff;
      border:1px solid @primary;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
    }

    .arrow {
      transition: transform 0.2s ease;
    }

    &.active {
      .arrow {
        transform: rotate(90deg);
      } 
    }
  }
}

.aro-datepicker-module {
  position:absolute;
  z-index:100;
  right:0px;
  top: 105%;
  // padding:17px 30px;
  border-radius:16px;
  border:1px solid @border;
  box-shadow: 0px 4px 20px rgba(60, 61, 73, 0.08);
  background-color:#fff;
  cursor:default;
  opacity:1;
  transition: opacity 0.2s ease, transform 0.2s ease-out;

  &.visuallyhidden {
    opacity:0;
    transform:translateY(30px);
  }

  &.hidden {
    display:none;
  }

  .options-list {
    list-style:none;
    padding:0px;
    margin:0px;
    padding:17px 0px;

    li {
      padding:8px 30px;
      cursor:pointer;

      &:hover {
        background-color:@border-light;
      }

      &:last-child {
        border-top:1px solid @border;
      }
    }
  }

  .datepickers {
    padding:17px;
    text-align:center;

    .calendar-wrap {

      .header-title {
        font-weight:@semi-bold;
        font-size:16px;
        margin-bottom:8px;
      }

      .calendar {
        .link:not(.disabled):hover {
          background-color:@border-light;
        }
      }
    }

    .lowkey-button {
      border:none;
      background-color:transparent;
      font-size:.875em;
      color:@color__grey-medium-light;
      margin-top:12px;
      cursor:pointer;

      &:hover {
        color:@color__grey-medium-dark;
      }
    }
  }

  //Transitions
  .fade-left-enter-active,
  .fade-left-leave-active {
    transition: opacity 0.1s ease, transform 0.1s ease;
  }

  .fade-left-enter-from {
    opacity:0;
    transform: translateX(50px);
  }
  .fade-left-leave-to {
    opacity: 0;
    transform: translateX(-50px);
  }
}

.aro-activitylog-form-listing {
  display:flex;
  padding:24px 20px;
  align-items:center;
  justify-content:space-between;
  cursor:pointer;
  position:relative;
  transition:background-color 0.2s ease;

  &.border-top {
    border-top:1px solid @border;
  }

  &:hover {
    background-color:@border-light;
  }

  .arrow {
    transition:transform 0.2s ease;
  }

  &.active {
    .arrow {
      transform:rotate(90deg);
    }

    &::after {
      content:'';
      height:25px;
      width:4px;
      border-radius:0px 4px 4px 0px;
      position:absolute;
      left:0px;
      background-color:@ios-purple;
    }
  }
}

.aro-bar-wrapper {
  display:flex;
  flex-wrap:nowrap;
  align-items:center;
  gap:8px;

  .bar-outer {
    flex:1 1 100%;
    background-color: #D9D9D9;
    border-radius: 100px;
    height:7px;
    overflow:hidden;


    .bar-inner {
      width:100%;
      height:100%;
      background-color:#FFBF1C;
      border-radius:0px 100px 100px 0px;
      transition: transform 0.4s ease-out;
    }
  }

  .question, .score-value {
    flex:0 1 auto;
    font-size:.875em;
    font-weight:@medium;
    color:fade(@primary-dark, 80%);
  }
}

.aro-layout-defects {
  display:grid;
  grid-template-columns: 2fr minmax(0, 1fr);
  gap: 20px;

  .breakpoint-lg-tablet({
    grid-template-columns:minmax(0, 1fr);
  });

  & > .widgets-area {
    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap:20px;
    align-items:start;

    .breakpoint-lg-tablet({
      grid-row:2;
    });
  }
  .summary-area {
  
    .summary-card {
      position:sticky;
      top:0px;
    }

    .breakpoint-lg-tablet({
      grid-row:1;
    });
  }
}

.aro-chart-container {
  display:flex;
  margin-top:20px;
  justify-content: space-between;
  // flex-wrap:wrap-reverse;

  .legend-wrapper {
    display:flex;
    flex:0 1 auto;
    align-items:center;
  }

  .chart-wrapper {
    min-height:160px;
    flex:0 0 170px;
    overflow:hidden;
  }

  .breakpoint-md-tablet({
    flex-wrap: wrap-reverse;
    justify-content: center;
    row-gap: 20px;
    margin-bottom:30px;
  });
}

.aro-dashboard-title-bar {
  margin-top:30px;
  margin-bottom:10px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  width:100%;

  .heading-wrapper {
    display:flex;
    align-items:center;
    column-gap:24px;

    & > .title {
      margin-top:0px;
      font-size:24px;
    }

    .image-wrapper {
      height:74px;
      width:74px;
      padding:5px;
      border-radius:100%;
      overflow:hidden;

      & > img {
        width:100%;
        height:100%;
        object-fit: contain;
      }
    }
  }
}

.aro-shortcuts-bar {
  display:flex;
  column-gap:15px;
  row-gap:10px;
  align-items:center;
  flex-wrap:wrap;
  margin:10px 0px 20px;

  p {
    margin-bottom:0px;
  }

  .shortcut {
    background-color: @secondary;
    color:#fff;
    display:flex;
    column-gap:6px;
    align-items:center;
    border-radius:20px;
    padding:10px 20px;
    flex:0 0 auto;
    transition:0.2s background-color ease;

    &:hover {
      background-color: darken(@secondary, 10%);
    }
  }
}

.aro-app-listing {
  display:flex;
  flex-wrap:wrap;
  gap:20px;

  .app-wrapper {
    flex:0 0 217px;
    min-height:189px;
    display:flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    background-color:#fff;
    border-radius:10px;
    border:1px solid @border;
    transition:all 0.2s ease;

    &:hover {
      border-color:@secondary;
    }

    .icon-container {
      font-size:24px;
      background-color:@secondary;
      color:#fff;
      border-radius:100%;
      padding:15px;
    }

    .title {
      margin-top:30px;
      color:@color__grey-medium-dark;
    }
  }
}

.aro-eod-picker-widget {
  border-radius:10px;
  background-color:#fff;
  padding:10px 30px 10px 20px;
  border:1px solid @border;
  // box-shadow: 0px 2px 10px rgba(60, 61, 73, 0.08);
  display:flex;
  gap:20px;
  flex-wrap:nowrap;
  align-items:center;

  & > .calendar {
    color:@ios-purple;
    font-size:24px;
  }

  & > .date-block {
    .date {
      font-size:1em;
      font-weight:@semi-bold;
      color:@ios-purple;
    }

    .time {
      font-size:.875em;
      font-weight:@regular;
      color:@color__grey-medium;
    }
  }
}

.aro-trip-column-wrap {
  margin-top:20px;

  .trip-listing {
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap:20px;
  }
}

.aro-trip-events-listing {

  & > .trip-event {
    padding:20px 0px;
    //add border to all but last
    border-bottom:1px solid @border;
    &:last-child {
      border-bottom:none;
    }

    .heading-wrap {
      display:flex;
      align-items:center;
      justify-content:space-between;
      font-weight:@medium;

      & > .title {
        color:@primary-dark;
        font-size:1em;
        margin-bottom:0;
      }

      & > .label {
        color:@color__warning-dark;
        font-size:.875em;
      }
    }

    .meta {
      display:flex;
      column-gap:6px;
      font-size:.875em;
      color:@color__grey-medium;
      align-items:center;
    }

    .daterange {
      margin-top:20px;
      display:grid;
      grid-template-columns:1fr 1fr;
      column-gap:10px;

      .date-wrap {
        & > .date {
          color:@color__grey-medium-light;
          font-weight:@medium;
          font-size:.875em;
        }

        & > .day {
          color:@primary-dark;
          font-size:1.5em;
        }
      }
    }
  }
}

.aro-trip-transactions-listing {
  
  .trip-transaction {
    padding:20px;
    border-bottom:1px solid @border;

    //add border to all but last
    &:last-child {
      border-bottom:none;
    }

    & > .heading {
      color:@primary-dark;
      display:flex;
      align-items: center;
      column-gap:5px;
      margin-bottom:10px;

      .title {
        font-size:1em;
        font-weight:@semi-bold;
        margin-top:0px;
      }

      i {
        font-size:1.25em;
      }
    }

    .divider {
      margin:10px 0px;
      border-bottom:2px dashed @border;
    }
  }

  .grand-total {
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:30px 20px;
    border-top:2px solid @border;
    font-weight:@semi-bold;
    color:@primary-dark;
  }
}

.aro-trip-expenses-listing {

  .trip-expense {
    padding:20px;
    border-bottom:1px solid @border;

    //no border on last
    &:last-child {
      border-bottom:none;
    }

    & > .title {
      color:@primary-dark;
      font-size:1em;
      font-weight:@semi-bold;
      margin-bottom:5px;
    }
  }
}

.aro-trip-corporate-expense-widget {

  & > .header.trip {
    color:@tertiary-dark !important;

    i {
      font-size:1.125em;
      margin-right:5px;
    }
  }

  .airline-img {
    max-height:30px;
  }

  .to-and-from {
    display:grid;
    grid-template-columns:1fr 16px 1fr;
    column-gap:10px;
    align-items:center;

    .divider {
      color:@color__grey-medium-light;
    }
  }

  .cost-wrapper {
    padding-top:20px;
    border-top:2px dashed @border;

    .cost-detail-wrapper {
      display:flex;
      align-items:top;
      justify-content:space-between;
      margin-top:10px;

      & > .title {
        font-size:14px;
        font-weight:@medium;
        color:@color__grey-medium-light;
      }

      & > .details {
        text-align:right;

        .diff {
          font-weight:@regular;
          font-size:.875em;

          &.positive {
            color:@color__error;
          }

          &.negative {
            color:@tertiary-dark;
          }
        }
        .cost {
          font-weight:@semi-bold;
          color:@primary-dark;
          font-size:1em;
        }
      }
    }
  }

  .daterange {
    margin-top:20px;
    display:grid;
    grid-template-columns:1fr 1fr;
    column-gap:10px;

    .date-wrap {
      & > .title {
        color:@color__grey-medium-light;
        font-weight:@medium;
        font-size:.875em;
      }

      & > .date {
        color:@primary-dark;
        font-weight:@medium;
        font-size:1em;
      }

      & > .time {
        font-weight:@regular;
        color:@color__grey-medium;
        font-size:1em;
      }
    }
  }

  .content.address-block {
    padding:0px;
  }
}

.aro-trip-daterange {
  display:grid;
  grid-template-columns:1fr 1fr;
  column-gap:10px;

  .date-wrap {
    & > .date {
      color:@color__grey-medium-light;
      font-weight:@medium;
      font-size:.875em;
    }

    & > .day {
      color:@tertiary-dark;
      font-size:1.5em;
    }
  }
}

.aro-trip-transactions-table-listing {
  padding:20px;
  border:1px solid @border;
  //remove border from last
  &:last-child {
    border-bottom:none;
  }
  
  & > .content {
    display:flex;
    column-gap:10px;
    justify-content: space-between;
    align-items:flex-end;
    margin-bottom:10px;

    & > .header {
      display:flex;
      flex-direction:column;

      .title {
        color:@primary-dark;
        font-size:1em;
        font-weight:@semi-bold;
      }

      .trip, .card {
        font-size:.875em;
        color:@color__grey-medium-light;
        font-weight:@medium;
      }
    }

    & > .meta {
      flex:0 0 auto;
      text-align:right;
      .date {
        color:@color__grey-medium-light;
        font-weight:@medium;
        font-size:.875em;
      }
  
      .cost {
        font-size:1.2em;
        color:@tertiary-dark;
        font-weight:@semi-bold;
      }
    }
  }

  .aro.detail {
    padding:5px 0px;
    //only add border to last child
    &:last-child {
      border-top:1px solid @border;
    }
  }
  
}

.aro-trip-review-grid {
  display:grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 375px));
  grid-auto-rows:20px;
  column-gap:20px;
  row-gap:20px;
  align-items:start;

  // //give first child a different grid area
  // & > *:first-child {
  //   grid-area: a;
  // }
  // //give second child a different grid area
  // & > *:nth-child(2) {
  //   grid-area: b;
  // }
  // //give third child a different grid area
  // & > *:nth-child(3) {
  //   grid-area: c;
  // }

  /*
    Container query support not yet available for less
    So we have to specify the full class target again to make it work with the mixin properly
  */
  // .breakpoint-columns-4({
  //   .aro.grid.one-one-one {
  //     grid-template-columns: 1fr 1fr;
  //     grid-template-rows: 20% auto;
  //     //create a grid with 2 columns and 2 rows in column 1
  //     grid-template-areas: 
  //       "a b"
  //       "c b";
  //   }
  // });
}

.aro-costsaves-monthly-totals {
  .totals-wrapper {
    display:grid;
    grid-template-columns: repeat(3, minmax(300px, 1fr));
    gap:20px;

    .breakpoint-extra-lg-tablet({
      grid-template-columns: repeat(2, minmax(300px, 1fr));
    });

    .breakpoint-md-tablet({
      grid-template-columns: 1fr;
    });

    .table-content {
      max-width:100%;
      overflow-x:scroll;
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
    }

    table {
      width:100%;
      border-collapse: collapse;

      th.heading, td.heading {
        text-align: left;
        font-weight:@semi-bold;
        font-size:.875em;
        line-height:1;
        color:fade(#232229, 70%);
      }

      th, td:not(.heading) {
        padding:15px 5px;
        border-bottom:1px solid @border;
      }

      tbody {
        tr:last-child td {
          border-bottom:none;
        }
      }
    }
  }
}

.aro-calendar-event-tooltip {
  display:none;
  z-index:100;
  max-width:350px;
  width:100%;
  max-height:70vh;
  overflow:auto !important;

  &[data-show] {
    display:block;
  }

  .options-bar {
    padding:10px 20px 0px;
    text-align:right;
  }

  .arrow,
  .arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  .arrow {
    visibility: hidden;
  }

  .arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  &[data-popper-placement^='top'] > #arrow {
    bottom: -4px;
  }
  
  &[data-popper-placement^='bottom'] > #arrow {
    top: -4px;
  }
  
  &[data-popper-placement^='left'] > #arrow {
    right: -4px;
  }
  
  &[data-popper-placement^='right'] > #arrow {
    left: -4px;
  }

  &.tentative {
      &::before {
        content:'';
        position:absolute;
        width:100%;
        height:25px;
        opacity: 0.5;
        top:0px;
        left:0px;
        background: repeating-linear-gradient( -45deg, #000, #000 10px, transparent 10px, transparent 20px );
        z-index:0;
      }
    }
}

.aro-full-calendar {
  .aro-calendar-event {
    cursor:pointer;
    gap:4px;

    //fix fc event time bug
    .fc-event-time {
      flex:0 0 auto;
    }
  }

  //this is odd but we have to do it this way
  a.aro-calendar-event {
    &.tentative {
      padding-left:20px;

      &::after {
        content:'';
        position:absolute;
        width:15px;
        height:100%;
        top:0px;
        left:0px;
        background: repeating-linear-gradient( -45deg, #000, #000 4px, transparent 5px, transparent 8px );
      }
    }

    &.bordered {
      padding-left:20px;

      &::before {
        content:'';
        position:absolute;
        width:10px;
        height:100%;
        top:0px;
        left:0px;
        background-color:#39393F;
      }
    }

    &.category- {
      &1::before {
        background-color: #00A3E0;
      }
      &2::before {
        background-color: #099455;
      }
      &3::before {
        background-color: #B35C05;
      }
      &4::before {
        background-color: #232229;
      }
      &5::before {
        background-color: #B01B0C;
      }
    }
  }
}

.aro-trip-planner {
  .sites-wrapper {
    //remove the default list styles
    list-style:none;
    padding:0px;
    margin:0px;

    .site {
      border-top:1px solid @border;
      padding:15px 0px;

      & > .content {
        //remove the default list styles=
        padding:0px 20px 10px 0px;
        display:flex;
        align-items: center;
        gap:10px;

        .dragger {
          cursor:grab;
          display:flex;
          align-items: center;
          justify-content: center;
          padding:10px 15px;
          border-radius:50%;
          font-weight:@semi-bold;

          &:hover {
            color:@primary-dark;
          }
        }

        //move the delete button to the right
        & > button {
          margin-left:auto;
        }

        //style the search input
        .search-block {
          width:100%;

          .input {
            width:100%;
          }

          input {
            font-family: @font-family;
            border:1px solid @border-medium;
            border-radius:6px !important;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
            margin: 0;
            outline: 0;
            -webkit-appearance: none;
            -webkit-tap-highlight-color: rgba(255,255,255,0);
            padding: 10px 14px;
            background: #fff;
            font-size:14px;
            width:100%;
        
            &:focus {
              border:1px solid @ios-purple;
              background-color:lighten(@ios-purple, 55);
              box-shadow: 0px 1px 4px rgba(2, 9, 34, 0.12);
            }
        
            &:focus-visible {
              outline:none;
            }
          }
        }
      }

      & > .meta {
        margin-left: 60px;
        font-size:.875em;
        display:flex;
        gap:10px;

        .title {
          font-weight:@semi-bold;
          color:@primary-dark;
        }

        .description {
          color:@color__grey-medium-dark;
        }
      }

      &.dragging {
        .dragger {
          cursor:grabbing;
        }
      }
    }
  }

  .trip-overview {
    padding:10px 20px;
    border-top:1px solid @border;
    border-bottom:1px solid @border;
    border-left: 4px solid @secondary;
    display:flex;
    align-items: flex-start;
    gap:20px;

    .icon {
      font-size:1.5em;
      color:@primary-dark;
    }

    .content {
      display:flex;
      flex-direction:column;
      gap:3px;

      .title {
        font-weight:@semi-bold;
        color:@primary-dark;
      }
      
      .description {
        color:@color__grey-medium-dark;
        font-size:.875em;
      }
    }
  }

  .button-block {
    border-top:1px solid @border;
    padding:20px;
    padding-bottom:30px;
  }
}

.aro-site-info-window {
  font-size:16px;
  font-family:@font-family;
  padding:20px 10px;

  .header {
    .title {
      font-weight:@semi-bold;
      color:@primary-dark;
      font-size:1.25em;
      margin-bottom:10px;
    }

    .description {
      color:@color__grey-medium-dark;
      font-size:.875em;
    }
  }

  .meta-block {
    display:flex;
    gap:30px;
    color:@primary;
    font-size:.875em;
    font-weight:@medium;
    margin-top:20px;

    .meta {
      display:flex;
      gap:5px;

      .icon {
        font-size:1.25em;
      }

      p {
        font-weight:@medium;
      }
    }
  }

  .upcoming-event-block {
    margin-top:30px;
    border:1px solid @color__warning-dark;
    border-radius:10px;
    padding:15px 20px;
    color:@color__warning-dark;

    .title {
      font-size:.875em;
      margin-bottom:5px;
    }

    .description {
      margin-top:0px;
      font-weight:@semi-bold;
      font-size:1.25em;
    }
  }

  .last-event-block {
    display:flex;
    padding-left:10px;
    font-size:.875em;
    margin-top:10px;
    gap:5px;

    .title {
      color:@color__grey-medium-dark;
    }

    .description {
      font-weight:@medium;
      color:@primary-dark;
    }
  }

  .actions {
    margin-top:30px;
    display:flex;
    flex-direction: column;
    gap:10px;
  }
}

.aro-site-secondary-info-window {
  font-size:16px;
  font-family:@font-family;
  padding:20px 0px;

  .header {
    padding:0px 20px;
    
    & > .title {
      font-weight:@semi-bold;
      color:@primary-dark;
      font-size:1.25em;
      margin-bottom:10px;
    }

    .description {
      color:@color__grey-medium-dark;
      font-size:.875em;
    }
  }

  .meta-block {
    display:flex;
    gap:30px;
    color:@primary;
    font-size:.875em;
    font-weight:@medium;
    margin-top:20px;
    padding:0px 20px;

    .meta {
      display:flex;
      gap:5px;

      .icon {
        font-size:1.25em;
      }

      p {
        font-weight:@medium;
      }
    }
  }

  .upcoming-event-block {
    margin-top:30px;
    border:1px solid @color__warning-dark;
    border-radius:10px;
    padding:15px 20px;
    color:@color__warning-dark;

    .title {
      font-size:.875em;
      margin-bottom:5px;
    }

    .description {
      margin-top:0px;
      font-weight:@semi-bold;
      font-size:1.25em;
    }
  }

  .last-event-block {
    display:flex;
    padding-left:10px;
    font-size:.875em;
    margin-top:10px;
    gap:5px;

    .title {
      color:@color__grey-medium-dark;
    }

    .description {
      font-weight:@medium;
      color:@primary-dark;
    }
  }

  .actions {
    margin-top:30px;
    display:flex;
    flex-direction: column;
    gap:10px;
  }
}

.aro-map-overlay {
  //add text stroke
  text-shadow: 
    2px 2px 0px rgba(255, 255, 255, 1),
    -2px 2px 0px rgba(255,255,255,1),
    2px -2px 0px rgba(255,255,255,1),
    -2px -2px 0px rgba(255,255,255,1);

}

.aro-map-control-button {
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0,0,0,.3);
  color: rgb(25,25,25);
  cursor: pointer;
  font-family: Roboto,Arial,sans-serif;
  font-size: 16px;
  line-height: 38px;
  margin: 8px 0 22px;
  padding: 0 10px;
  text-align: center;

  &:hover {
    background-color:rgb(235,235,235);
    border-color:rgb(235,235,235);
  }
}

.aro-header-block {
  & > .meta-wrapper {
    display: flex;
    gap:10px 40px;
    flex-wrap:wrap;
    padding:15px 30px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px 0px rgba(60, 61, 73, 0.12);
    background-color: #fff;

    & > .meta {
      display:flex;
      gap:10px;

      .icon {
        font-size:1em;
        color:@tertiary;
      }

      .content {
        display:flex;
        font-size:.875em;
        gap:5px;

        .title {
          color:@color__grey-light;
        }
        
        .description {
          color:@color__grey-medium-dark;
          font-weight:@medium;
        }
      }
    }
  }
}

.aro-login-basic-screen {
  background: linear-gradient(0deg, rgba(0, 24, 113, 0.71), rgba(0, 24, 113, 0.71)), url('../img/aro-holding-phone.jpg') center right / cover no-repeat;
}

.aro-basic-screen {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  height:100%;
  padding: 24px;

  .aro-access-denied-block {
    max-width:415px;
    color:@primary-dark;

    h1.header.basic {
      font-size:1.5em;
      font-weight:@semi-bold;
      margin-bottom:0px;
    }

    p {
      font-size:.875em;
      color:@color__grey-medium-light;
    }
    
    .aro.card {
      padding:50px 40px;

      .breakpoint-mobile({
        padding:30px 5px;
      });
    }

    .header-group {
      display:flex;
      align-items:center;
      justify-content: center;
      gap:10px;
      color:#fff;
      margin: 0 auto;
      margin-bottom:50px;

      img {
        max-width:165px;
        height:auto;
      }

      .heading {
        font-size:1em;
        font-weight:@semi-bold;
        font-family:'Montserrat','Inter',sans-serif;
      }
    }
  }

  .footer-group {
    position:absolute;
    bottom:30px;
    color:#fff;
    font-size:.875em;
  }
}

.aro-ticket-create-card {
  max-width:826px;
  margin:0 auto;
  width:100%;
}

.aro-ticket-show-card {
  & > .stepper-wrapper {
    display:flex;
    border-bottom:1px solid @border;
    
    & > .step {
      position:relative;
      display:flex;
      flex:1 1 0;
      align-items:center;
      justify-content:center;
      gap:10px;
      padding:10px;
      color:@color__grey-light;
      font-size:.875em;

      .breakpoint-md-tablet({
        font-size:.8em;
      });

      & > .icon {
        display:none;
      }

      &.error {
        font-weight:@medium;
        background-color:@color__error-light;
        color:@color__error-dark;
      }

      &.active {
        font-weight:@medium;
        background-color:@ios-purple;
        color:#fff;

        & > .icon {
          display:block;

          .breakpoint-md-tablet({
            display:none;
          });
        }
      }

      &.current {
        border-top-right-radius: 20px 100%;
        border-bottom-right-radius: 20px 100%;
      }
    }
  }

  & > .header-wrapper {
    padding:30px 20px;
    border-bottom:1px solid @border;

    & > .header {
      display:flex;
      align-items:center;
      gap: 24px;

      h1 {
        font-size:1.5em;
        font-weight:@semi-bold;
        margin-bottom:0px;
      }
    }

    & > .meta-wrapper {
      margin-top:30px;

      .primary {
        padding-bottom:15px;
        border-bottom:1px solid @border;
      }

      .meta-list {
        display: flex;
        gap:10px 40px;
        flex-wrap:wrap;

        & > .meta-item {
          display:flex;
          gap:10px;

          i {
            font-size:1.2em;
            color:@tertiary;
          }

          a:hover {
            text-decoration:underline;
          }

          .content {
            font-size:.875em;
            color:@color__grey-medium-dark;
            font-weight:@medium;
          }
        }
      }

      .meta-list-discrete {
        display: flex;
        gap:10px 40px;
        flex-wrap:wrap;
        margin-top:15px;

        & > .meta-item {
          display:flex;
          gap:10px;

          i {
            font-size:1em;
            color:@color__grey-light;
          }

          .title {
            font-size:.875em;
            color:@color__grey-light;
          }

          .content {
            font-size:.875em;
            color:@color__grey-medium-dark;
            font-weight:@medium;
          }
        }
      }
    }
  }

  & > .assignee-wrapper {
    background-color: #F8F8F8;
    padding:10px 20px;
    display:flex;
    align-items:center;
    gap:10px;

    & > .title {
      flex-shrink:0;
      font-size:.875em;
    }

    &.top-bordered {
      border-top:1px solid @border;
    }
  }

  & > .content-wrapper {
    padding:30px 20px;

    & > .header {
      display:flex;
      align-items:center;
      justify-content: space-between;
      padding-bottom:15px;
      margin-bottom:20px;
      border-bottom:1px solid @border;

      & > .title {
        font-size:1em;
        font-weight:@semi-bold;
        margin-bottom:0px;
      }

      & > .description {
        font-size:.875em;
        color:@color__grey-medium-light;
      }
    }
  }
}

.aro-ticket-comment-create-card {
  position:relative;
  margin-top:20px;
  overflow:visible !important;
  
  &::before {
    content:'';
    position:absolute;
    top:-20px;
    left:0px;
    right:0px;
    width:2px;
    height:20px;
    background-color:@color__grey-lighter;
    margin:0 auto;
  }

  &.aro.card {
    & > .header {
      background-color: #F8F8F8;
      padding:10px 20px;
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
      
      & > .title {
        font-size:.875em;
        font-weight:@medium;
      }
    }
  }
}

.aro-ticket-comment-card {
  position:relative;
  margin-top:20px;
  overflow:visible !important;

  &::before {
    content:'';
    position:absolute;
    top:-20px;
    left:0px;
    right:0px;
    width:2px;
    height:20px;
    background-color:@color__grey-lighter;
    margin:0 auto;
  }

  &.aro.card {
    & > .header {
      background-color: #F8F8F8;
      padding:10px 20px;
      display:grid;
      grid-template-columns: 1fr 1fr;
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
      
      .column {
        display:flex;
        align-items:center;
        gap:8px;

        &.right-aligned {
          justify-content:flex-end;
        }

        .title {
          font-size:.875em;
          font-weight:@medium;
        }

        .description {
          font-size:.875em;
          color:@color__grey-medium-light;
        }
      }
    }
  }
}

.aro-attachments-wrapper {

  & > .title {
    color:@color__grey-medium-light;
    font-weight:@medium;
    font-size:.875em;
  }

  & > .attachment-list {
    display:flex;
    flex-wrap:wrap;
    gap:10px 20px;
    margin-top:12px;

    .attachment-list-item {
      background-color:#F8F8F8;
      padding:10px 12px;
      border-radius: 6px;
      border:1px solid @border;
      display:flex;
      align-items:center;
      gap:20px;
      font-size:.875em;
      cursor:pointer;

      &:hover {
        border:1px solid @border-medium;
      }
    }
  }
}

.aro-ticket-meta {
  margin-top:30px;
  margin-bottom:50px;

  .meta-group {
    font-size:.875em;
    display:flex;
    align-items:center;
    gap:10px;

    & > .title {
      color:@color__grey-medium-light;
    }

    & > .description {
      color:@primary-dark;
      font-weight:@medium;
    }
  }
}

.aro-ticket-form-listing {
  display:flex;
  align-items: center;
  position:relative;
  padding-left:20px;
  padding-right:20px;
  padding-top:18px;
  padding-bottom:18px;
  transition: background-color 0.2s ease;
  cursor:pointer;

  &:hover {
    background-color: @border;
  }

  & > .content {
    flex:1 1 auto;
    padding:0px 16px;
    color:@primary-dark;

    .title {
      color:@primary-dark;
      font-weight:500;
    }

    .description {
      font-size:@font__small;
      color:@color__grey-medium-light;
    }

  }

  & > .icon {
    flex-basis: 24px;
    font-size:20px;
    color:@primary;
  }

  &::before {
    content:'';
    height:1px;
    background-color: @border;
    position:absolute;
    bottom:0px;
    display:block;
    left:20px;
    right:20px;
  }

  &:last-child:before, &.last:before {
    display:none;
  }

  &::after {
    content:'\eb9f';
    font-family: unicons-line;
    font-style: normal;
    font-weight: 400;
    display: inline-block;
    text-decoration: inherit;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color:@primary-dark;
  }
}

.aro-shortcuts-wrapper {
  display:flex;
  align-items: center;
  gap: 10px;
  overflow:auto;
  //make the scrollbar height 2px
  scrollbar-width: thin;
  scrollbar-color: @border @border-light;
  div::-webkit-scrollbar {
    height: 2px;
  }
  padding-bottom: 5px;



  & > .title {
    color:@color__grey-medium-light;
    font-weight:@medium;
    font-size:.875em;
  }

  & > .shortcut-group {
    display:flex;
    gap:10px;

    .shortcut {
      flex-shrink: 0;
      background-color:#fff;
      color:@color__grey-medium;
      border-radius:20px;
      border:1px solid @border;
      padding:10px 20px;
      transition:0.2s background-color ease;

      &:hover {
        background-color: lighten(@border, 3%);
      }
    }
  }
}