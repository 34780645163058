html {
  font-size:16px;
}

body {
  font-size:16px;
  font-family:@font-family;
  margin:0px;
  background-color:#F4F6F8;
  color:@primary-dark;
}

[x-cloak] { display: none !important; }
[x-hide] { display: none !important; } //used when not using alpine

body.pushable > .pusher {
  background-color:#f4f6f8;
}

h1,h2,h3,h4,h5 {
  font-family:@font-family;
}

p {
  font-size:1em;
  font-weight:400;
  margin:0px;
}

h3 {
  font-size:24px;
  font-weight: 600;
}

h4 {
  font-size:18px;
  font-weight:@semi-bold;
}

/*******************************
        Semantic Overrides
*******************************/
.ui.calendar .ui.table tr .completed {
  // pointer-events: none;
  color: @color__error-dark !important;
  background-color:@color__error-light !important;
}

.ui.form, .ui.form .field .dropdown, .ui.form .field .dropdown .menu>.item  {
  font-size:.875rem;
}

a {
  color:@primary-dark;
  &:hover {
    color:@primary-dark;
  }
}

.ui.accordion .title:not(.ui) {
  padding: unset;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

.aro.form .ui.labeled.input {
  & > input {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  & > input:focus {
    border-left:1px solid @ios-purple !important;
  }
}