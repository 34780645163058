.aro.grid {
  &.columns-1-auto {
    display: grid;
    grid-template-columns: minmax(300px, 375px) 1fr;
    grid-gap: 20px;
    align-items: start;

    .breakpoint-mobile({
      grid-template-columns: 1fr;
    });

    &.tablet {
      .breakpoint-tablet({
        grid-template-columns: 1fr;
      });
    }

    &.tablet-lg {
      .breakpoint-lg-tablet({
        grid-template-columns: 1fr;
      });
    }
  }

  &.columns-1-1-auto {
    display:grid;
    grid-template-columns: minmax(300px, 375px) minmax(300px, 375px) 1fr;
    column-gap:20px;
    row-gap:20px;
    align-items:start;

    .breakpoint-tablet({
      grid-template-columns: 1fr;
    });
  }

  &.columns-1-1-1 {
    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 375px));
    column-gap:20px;
    row-gap:20px;
    align-items:start;

    .breakpoint-tablet({
      grid-template-columns: 1fr;
    });
  }

  &.columns-2-auto {
    //first column takes up to spaces the rest is auto
    display:grid;
    grid-template-columns: minmax(600px, 770px) 1fr;
    column-gap:20px;
    row-gap:20px;
    align-items:start;

    .breakpoint-mobile({
      grid-template-columns: 1fr;
    });
  }

  &.columns-3 {
    //first column takes up to spaces the rest is auto
    display:grid;
    grid-template-columns: minmax(300px, 1161px);
    align-items:start;

    .breakpoint-mobile({
      grid-template-columns: 1fr;
    });
  }

  &.columns-1-3 {
    //first column takes up to spaces the rest is auto
    display:grid;
    grid-template-columns: minmax(300px, 375px) minmax(300px, 1161px);
    align-items:start;
    column-gap:20px;
    row-gap:20px;
    
    &.with-sticky{
      & > .column:first-child {
        position: sticky;
        top: 30px;
        z-index: 1;
      }
    }
    
    .breakpoint-lg-tablet({
      grid-template-columns: 1fr !important;
      
      &.reverse {
        
        & > .column:first-child {
          order:2;
        }
      }
      
      &.with-sticky{
        & > .column:last-child {
          position: static;
        }
      }
    });

    &.fluid {
      grid-template-columns: 1fr minmax(300px, 375px);
    }
  }

  &.columns-3-1 {
    //first column takes up to spaces the rest is auto
    display:grid;
    grid-template-columns: minmax(300px, 1161px) minmax(300px, 375px);
    align-items:start;
    column-gap:20px;
    row-gap:20px;
    
    &.with-sticky{
      & > .column:last-child {
        position: sticky;
        top: 30px;
        z-index: 1;
      }
    }
    
    .breakpoint-lg-tablet({
      grid-template-columns: 1fr !important;
      
      &.reverse {
        
        & > .column:first-child {
          order:2;
        }
      }
      
      &.with-sticky{
        & > .column:last-child {
          position: static;
        }
      }
    });

    &.fluid {
      grid-template-columns: 1fr minmax(300px, 375px);
    }
  }

  &.columns-1-2-1 {
    //first column takes up to spaces the rest is auto
    display:grid;
    grid-template-columns: minmax(300px, 375px) minmax(600px, 770px) minmax(300px, 375px);
    column-gap:20px;
    row-gap:20px;
    align-items:start;

    .breakpoint-mobile({
      grid-template-columns: 1fr;
    });
  }

  &.columns-1-2 {
    display:grid;
    grid-template-columns: minmax(300px, 375px) minmax(300px, 770px);
    column-gap:20px;
    row-gap:20px;
    align-items:start;

    .breakpoint-tablet({
      grid-template-columns: 1fr;
    });
  }
}

.aro.flex {
  display:flex;

  &.row {
    flex-direction: row;
  }
  
  &.column {
    flex-direction: column;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.justify-center {
    justify-content: center;
  }

  &.align-center {
    align-items: center;
  }

  &.gap-20 {
    gap: 20px;
  }

}

.aro.dynamic-grid {
  &.layout-1-1-1-1 {
    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 375px));
    column-gap:20px;
    row-gap:20px;
    align-items:start;

    .column {
      display: flex;
      flex-direction: column;
      gap:20px;
    }

    .breakpoint-tablet({
      grid-template-columns: 1fr;
    });
  }
}

.aro.layout-container {
  max-width: 1280px;
  width:100%;
  margin: 0 auto;
  padding: 0px 48px;

  &.width-600 {
    max-width:600px;
  }

  .breakpoint-lg-tablet({
    padding: 0px 0px;
  });
}